import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios"; // You'll need to install axios
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL
import EmailMarketing from "./emailMarketing";

function EmailVerification() {
    const { userId, hash } = useParams();
    console.log("user id: " + userId, "hash: " + hash);
    const navigate = useNavigate(); // Initialize useNavigate
    const [verificationStatus, setVerificationStatus] = useState("");

    const handleSubscriptionChange = () => {
        navigate("/dashboard");
    }

    useEffect(() => {
        async function verifyEmail() {
            try {
                const response = await axios.get(
                    `https://${apiBaseUrl}/confirm-email/${userId}/${hash}`
                );
                if (response.status === 200) {
                    setVerificationStatus("Email verified successfully.");
                } else {
                    setVerificationStatus(
                        "Email verification failed.  Please contact support at admin@HiddenCruiseFares.com"
                    );
                }
            } catch (error) {
                setVerificationStatus(
                    "An error occurred during verification.  Please contact support at admin@HiddenCruiseFares.com"
                );
            }
        }

        verifyEmail();
    }, [userId, hash, navigate]);

    return (
        <div>
            <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full bg-white p-6 rounded-md shadow-md text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900">
                        Verifying Your Email
                    </h2>
                    <p className="mt-2 text-gray-700">{verificationStatus}</p>
                    {verificationStatus === "Email verified successfully." && (
                        <EmailMarketing
                            userId={userId}
                            onSubscriptionChange={handleSubscriptionChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmailVerification;
