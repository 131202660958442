import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const editActiveUser = createAsyncThunk("user/edit", async ({ userId, userData }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-api-token": userData.token,
      "x-user-id": userId,
      // Add other headers as needed
    },
  };

  try {
    const response = await axios.put(
      `${apiBaseUrl}/users/${userId}`,
      userData,
      config // Pass the config to the axios call
    );
    
    if (!response.data) {
      throw new Error("Failed to edit user data");
    }

    return response.data;
  } catch (error) {
    // Handle error as needed
    throw error;
  }
});

export { editActiveUser };

