import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptionStatus } from "../../store/thunks/fetchSubscriptionStatus";
import { cancelSubscription } from "../../store/thunks/stripe/cancelSubscription";
import InvoiceList from "./InvoiceList";
import EmailMarketing from "../email/emailMarketing";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const Profile = () => {
    const dispatch = useDispatch();
    const activeUser = useSelector((state) => state.activeUser.data.user);
    const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
        useState(true);
    const subscriptionActive = useSelector((state) => {
        const subscriptionStatus =
            state.subscriptionStatus.data.status === "active";
        const userIsAdmin = state.activeUser.data.user.user_type === "admin";

        return subscriptionStatus || userIsAdmin;
    });

    return (
        <>
            <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Profile
                        </h2>
                        <p className="mt-1 text-sm leading-6 text-gray-500">
                            This information will be displayed publicly so be
                            careful what you share.
                        </p>

                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    First Name
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {activeUser.first_name}
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    >
                                        Update
                                    </button>
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    Last Name
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {activeUser.last_name}
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    >
                                        Update
                                    </button>
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    Email address
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {activeUser.email}
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    >
                                        Update
                                    </button>
                                </dd>
                            </div>
                        </dl>
                    </div>
                    {activeUser.agent && activeUser.agent.trim() !== "" && (
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Agent
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                You were either referred by or have been
                                assigned the following agent.
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                        Agent Name
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">
                                            {activeUser.agent}
                                        </div>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                        Agent Email address
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">
                                            {activeUser.agentEmail}
                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    )}
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Email Subscriptions
                        </h2>
                        <p className="mt-1 text-sm leading-6 text-gray-500">
                            Here you can manage your email subscriptions.  If you want to unsubscribe from all emails uncheck all options.
                        </p>
                        <EmailMarketing userId={activeUser._id} />
                    </div>
                </div>
            </main>
        </>
    );
};

export default Profile;
