let apiBaseUrl;
let STRIPE_SK;
let STRIPE_ENDPOINT_SK;
let isDev;

if (process.env.NODE_ENV === 'production') {
  console.log("PRODUCTION BUILD");
  apiBaseUrl = 'www.hiddencruisefares.com:6020';
  STRIPE_SK = process.env.STRIPE_SK_LIVE;
  STRIPE_ENDPOINT_SK = process.env.STRIPE_ENDPOINT_SK;
  isDev = false;
} else {
  console.log("DEVELOPMENT BUILD");
  apiBaseUrl = 'dev.HiddenCruiseFares.com:7020';
  console.log("apiBaseUrl" + apiBaseUrl);
  STRIPE_SK = process.env.STRIPE_SK_TEST;
  STRIPE_ENDPOINT_SK = process.env.STRIPE_ENDPOINT_SK_TEST;
  isDev = true;
}

export { apiBaseUrl, STRIPE_SK, STRIPE_ENDPOINT_SK, isDev };
