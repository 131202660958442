import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import roomList from "./roomList";
import { US, CA } from "country-flag-icons/react/3x2";
import CruiseFilter from "../filter/CruiseFilter";
import SubscriptionModal from "../modals/SubscriptionModal";
import {
    BarsArrowUpIcon,
    BarsArrowDownIcon,
    Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import celebrityLogo from "../../assets/celebrityLogo.png";
import royalLogo from "../../assets/royalLogo.png";
import virginLogo from "../../assets/virginLogo.png";
import CruiseDetailModal from "../cruiseDetail/CruiseDetailModal";
import SoldOutModal from "./SoldOutModal";
import ShipDetailModal from "../cruiseDetail/ShipDetailModal";
import { InformationCircleOutline } from "heroicons-react";
import { addToCart } from "../../store/thunks/facebook/addToCart";
import { isDev } from "../../config";
import { Link } from "react-router-dom";

function CruiseList({ onInquireClick, onDetailsClick }) {
    const [sort, setSort] = useState("CurrentPriceAdv");
    const [sortOrder, setSortOrder] = useState("neutral");
    const [currentPage, setCurrentPage] = useState(1);
    const [overlayTop, setOverlayTop] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: "GroupRate",
        direction: "default", // can be 'asc', 'desc', or 'default'
    });
    const [soldOutModalOpen, setSoldOutModalOpen] = useState(false);
    const [selectedSoldOutCruiseLine, setselectedSoldOutCruiseLine] =
        useState(null);

    const [cruiseDetailModalOpen, setCruiseDetailModalOpen] = useState(false);
    const [cruiseUnavailableModalOpen, setCruiseUnavailableModalOpen] =
        useState(false);
    useState(false);
    const [shipDetailModalOpen, setShipDetailModalOpen] = useState(false);
    const [currentCruiseShip, setCurrentCruiseShip] = useState(null);
    const [currentOperator, setCurrentOperator] = useState(null);

    const dispatch = useDispatch();
    const cruises = useSelector((state) => state.cruises.cruisesList.cruises);
    const activeUser = useSelector((state) => state.activeUser.data.user);
    const subscriptionActive = useSelector((state) => {
        const subscriptionStatus = state.subscriptionStatus.data.status;
        const userIsAdmin = state.activeUser.data.user.user_type === "admin";

        return subscriptionStatus || userIsAdmin;
    });
    // const globalCruiseLine = cruises.CruiseList.cruises.cruiseLine;
    const [sailDateForCruiseDetail, setSailDateForCruiseDetail] = useState();
    const [shipNameForCruiseDetail, setShipNameForCruiseDetail] = useState();

    const rowRefs = useMemo(() => {
        return cruises ? cruises.map(() => React.createRef()) : [];
    }, [cruises]);

    console.log(cruises);

    useEffect(() => {}, [cruises]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (shipDetailModalOpen && !event.target.closest(".popup")) {
                toggleShipDetailModal(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [sort, sortOrder, setShipDetailModalOpen]);

    // For determining the cruise category for Celebrity/Royal Carribean. Also sets breakpoints for long room categories
    const getCategoryDetails = (cruise) => {
        let room;

        if (cruise.CruiseLine === "Celebrity") {
            room = roomList.find(
                (room) => room.categoryCode === cruise.Category
            );
        } else if (cruise.CruiseLine === "Royal Caribbean") {
            room = roomList.find(
                (room) => room.categoryCode === cruise.CategoryClass
            );
        } else {
            return { code: "", name: "" }; // fallback
        }

        if (room && room.categoryName) {
            // Check for "w/" and "(" as potential break points
            const breakpoints = [" w/ ", " ("];
            let splitName;

            for (const breakpoint of breakpoints) {
                splitName = room.categoryName.split(breakpoint);
                if (splitName.length > 1) {
                    const separator = breakpoint === " w/ " ? " w/ " : " (";
                    return {
                        code:
                            cruise.CruiseLine === "Celebrity"
                                ? cruise.Category
                                : cruise.CategoryClass,
                        name: (
                            <>
                                {splitName[0]} <br /> {separator}
                                {splitName[1]}
                            </>
                        ),
                    };
                }
            }

            return {
                code:
                    cruise.CruiseLine === "Celebrity"
                        ? cruise.Category
                        : cruise.CategoryClass,
                name: room.categoryName,
            };
        }

        return {
            code:
                cruise.CruiseLine === "Celebrity"
                    ? cruise.Category
                    : cruise.CategoryClass,
            name: "",
        };
    };

    const toggleSortOrder = (currentOrder) => {
        switch (currentOrder) {
            case "neutral":
                return "asc";
            case "asc":
                return "desc";
            case "desc":
                return "neutral";
            default:
                return "neutral";
        }
    };

    // Render logo for each cruise line (just Celeb and Royal atm)
    function getLogoByCruiseLine(cruiseLine) {
        switch (cruiseLine) {
            case "Celebrity":
                return celebrityLogo;
            case "Royal Caribbean":
                return royalLogo;
            case "Virgin Voyages":
                return virginLogo;
            default:
                return null; // or some default logo if needed
        }
    }

    // This function is triggered for information icon on sold out cruises
    function showSoldOutModal(cruiseLine) {
        setSoldOutModalOpen(true);
        setselectedSoldOutCruiseLine(cruiseLine);
    }

    function toggleShipDetailModal(show) {
        if (!isDev) {
            ReactGA.event({
                event: "cruise_search",
                category: "Search",
                action: "Clicked Ship Detail",
            });
        }
        setShipDetailModalOpen(show);
    }

    function toggleCruiseDetailModal(show) {
        console.log("toggleCruiseDetailModal called with:", show);
        if (!isDev) {
            ReactGA.event({
                event: "cruise_search",
                category: "Search",
                action: "Clicked Cruise Detail",
            });
        }
        setCruiseDetailModalOpen(show);
    }

    function toggleCruiseUnavailableModal(show) {
        setCruiseUnavailableModalOpen(show);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            {/* Header bit */}
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Cruise Deals
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        View our exclusive list of cruise deals. Prices are per
                        person, taxes not included.
                    </p>
                    <p className="mt-2 text-sm text-gray-700">
                        Available while quantities last. Prices subject to
                        change without notice.
                    </p>
                </div>
            </div>
            <br />

            {/* CruiseFilter */}
            <CruiseFilter
                onPageChange={handlePageChange}
                subscriptionActive={subscriptionActive}
                sortOrder={sortOrder}
                sort={sort}
            />

            {/* Table */}
            <div className="relative mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="relative">
                            <div className="flex justify-center items-center pr-2 sm:hidden mb-4">
                                <div className="pl-2 pr-4">
                                    <label
                                        className="block text-sm font-semibold text-gray-900"
                                        htmlFor="sort"
                                    >
                                        Sort:
                                    </label>
                                </div>
                                {/* Mobile sorting options */}
                                <select
                                    id="sort"
                                    name="sort"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value !== sort) {
                                            setSort(value);
                                            setSortOrder("asc");
                                        } else {
                                            setSortOrder(
                                                toggleSortOrder(sortOrder)
                                            );
                                        }
                                    }}
                                >
                                    <option value="SailDate">
                                        Duration & Sail Date
                                    </option>
                                    <option value="CurrentGroupRate">
                                        Current Price PP
                                    </option>
                                    <option value="GroupRate">
                                        Our Price PP
                                    </option>
                                    <option value="CurrentPriceAdv">
                                        Savings PP
                                    </option>
                                </select>
                            </div>
                            <table className="min-w-full divide-y divide-gray-300">
                                {/* Table Head */}
                                <thead className="sm:table-header-group hidden">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Cruise Line & Ship
                                        </th>

                                        <th
                                            scope="col"
                                            onClick={() => {
                                                if (sort !== "SailDate") {
                                                    setSort("SailDate");
                                                    setSortOrder("asc");
                                                } else {
                                                    setSortOrder(
                                                        toggleSortOrder(
                                                            sortOrder
                                                        )
                                                    );
                                                }
                                            }}
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                        >
                                            Duration & Sail Date{" "}
                                            {sort === "SailDate" &&
                                                (sortOrder === "neutral" ? (
                                                    <Bars3BottomLeftIcon className="inline h-3.5 w-3.5" />
                                                ) : sortOrder === "asc" ? (
                                                    <BarsArrowUpIcon className="inline h-4 w-4" />
                                                ) : (
                                                    <BarsArrowDownIcon className="inline h-4 w-4" />
                                                ))}
                                        </th>

                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Category
                                        </th>

                                        <th
                                            scope="col"
                                            onClick={() => {
                                                if (
                                                    sort !== "CurrentGroupRate"
                                                ) {
                                                    setSort("CurrentGroupRate");
                                                    setSortOrder("asc");
                                                } else {
                                                    setSortOrder(
                                                        toggleSortOrder(
                                                            sortOrder
                                                        )
                                                    );
                                                }
                                            }}
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                        >
                                            Current Price PP{" "}
                                            {sort === "CurrentGroupRate" &&
                                                (sortOrder === "neutral" ? (
                                                    <Bars3BottomLeftIcon className="inline h-3.5 w-3.5" />
                                                ) : sortOrder === "asc" ? (
                                                    <BarsArrowUpIcon className="inline h-4 w-4" />
                                                ) : (
                                                    <BarsArrowDownIcon className="inline h-4 w-4" />
                                                ))}
                                        </th>

                                        <th
                                            scope="col"
                                            onClick={() => {
                                                if (sort !== "GroupRate") {
                                                    setSort("GroupRate");
                                                    setSortOrder("asc");
                                                } else {
                                                    setSortOrder(
                                                        toggleSortOrder(
                                                            sortOrder
                                                        )
                                                    );
                                                }
                                            }}
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                        >
                                            Our Price PP{" "}
                                            {sort === "GroupRate" &&
                                                (sortOrder === "neutral" ? (
                                                    <Bars3BottomLeftIcon className="inline h-3.5 w-3.5" />
                                                ) : sortOrder === "asc" ? (
                                                    <BarsArrowUpIcon className="inline h-4 w-4" />
                                                ) : (
                                                    <BarsArrowDownIcon className="inline h-4 w-4" />
                                                ))}
                                        </th>

                                        <th
                                            scope="col"
                                            onClick={() => {
                                                if (
                                                    sort !== "CurrentPriceAdv"
                                                ) {
                                                    setSort("CurrentPriceAdv");
                                                    setSortOrder("asc");
                                                } else {
                                                    setSortOrder(
                                                        toggleSortOrder(
                                                            sortOrder
                                                        )
                                                    );
                                                }
                                            }}
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                        >
                                            Savings PP{" "}
                                            {sort === "CurrentPriceAdv" &&
                                                (sortOrder === "neutral" ? (
                                                    <Bars3BottomLeftIcon className="inline h-3.5 w-3.5" />
                                                ) : sortOrder === "asc" ? (
                                                    <BarsArrowUpIcon className="inline h-4 w-4" />
                                                ) : (
                                                    <BarsArrowDownIcon className="inline h-4 w-4" />
                                                ))}
                                        </th>

                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                        >
                                            <span className="sr-only">
                                                Book
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                {/* Table Body */}
                                <tbody className="divide-y divide-gray-200 bg-white ">
                                    {cruises && cruises.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan="6"
                                                className="text-center py-10"
                                            >
                                                <h2 className="text-xl font-semibold mb-4">
                                                    No matching cruises
                                                </h2>
                                                <p>
                                                    Try adjusting your filters
                                                    or check back later.
                                                </p>
                                            </td>
                                        </tr>
                                    ) : (
                                        cruises &&
                                        cruises.map((cruise, index) => (
                                            <tr
                                                key={cruise.CombinedKey}
                                                ref={rowRefs[index]}
                                                className={`${
                                                    !subscriptionActive &&
                                                    ((currentPage === 1 &&
                                                        index >= 10) ||
                                                        currentPage > 1)
                                                        ? "blurred pointer-events-none"
                                                        : ""
                                                } even-row`}
                                            >
                                                {/* Cruise Line & Ship */}
                                                <td
                                                    className="whitespace-nowrap py-2 sm:py-5 pl-4 text-sm sm:pl-0"
                                                    style={{
                                                        minWidth: "245px",
                                                    }}
                                                >
                                                    {/* <td className="py-5 pl-4 text-xs sm:text-sm md:text-base md:pl-0" style={{ minWidth: '150px' }}> */}

                                                    <div className="flex items-center">
                                                        <img
                                                            src={getLogoByCruiseLine(
                                                                cruise.CruiseLine
                                                            )}
                                                            alt="Cruise Logo"
                                                            className={`flex-shrink-0 mr-2 ${
                                                                cruise.CruiseLine ===
                                                                "Royal Caribbean"
                                                                    ? "h-[20px] w-[80px]"
                                                                    : cruise.CruiseLine ===
                                                                      "Celebrity"
                                                                    ? "h-[60px] w-[60px] ml-2 mr-4"
                                                                    : cruise.CruiseLine ===
                                                                      "Virgin Voyages"
                                                                    ? "h-[50px] w-[40px] ml-4 mr-7"
                                                                    : ""
                                                            }`}
                                                        />

                                                        <div className="ml-4">
                                                            <div className="flex justify-items items-center font-medium text-gray-900">
                                                                {
                                                                    cruise.CruiseLine
                                                                }
                                                                {/* This renders the ShipDetail modal */}
                                                                <button
                                                                    className="ml-1 bg-transparent border-0 cursor-pointer text-indigo-500 focus:outline-none"
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        event.stopPropagation(); // stop the click event from propagating up to the document
                                                                        setCurrentCruiseShip(
                                                                            cruise.Ship
                                                                        ); // Set current cruise ship here
                                                                        setCurrentOperator(
                                                                            cruise.CruiseLine
                                                                        ); // Set the proper operator name to pass to thunk
                                                                        toggleShipDetailModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <InformationCircleOutline
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </button>
                                                            </div>
                                                            <div className="mt-1 text-gray-500">
                                                                {cruise.Ship}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                {/* Duration & Sail Date */}
                                                <td
                                                    className="relative whitespace-nowrap items-center px-3 py-2 sm:py-5 text-sm text-gray-500"
                                                    style={{
                                                        minWidth: "220px",
                                                    }}
                                                >
                                                    <div className="flex mt-1 items-center text-gray-900">
                                                        {cruise.Product.substring(
                                                            0,
                                                            20
                                                        ).toUpperCase()}
                                                        <button
                                                            className="ml-1 bg-transparent border-0 cursor-pointer text-indigo-500 focus:outline-none"
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.stopPropagation();
                                                                setShipNameForCruiseDetail(
                                                                    cruise.Ship
                                                                );
                                                                setSailDateForCruiseDetail(
                                                                    cruise.SailDate
                                                                );
                                                                toggleCruiseDetailModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <InformationCircleOutline
                                                                width={20}
                                                                height={20}
                                                            />
                                                        </button>
                                                        <button className="ml-1 mt-0.5">
                                                            <Link
                                                                to={{
                                                                    pathname:
                                                                        "/cruise_detail",
                                                                    search: `?shipName=${encodeURIComponent(
                                                                        cruise.Ship
                                                                    )}&sailDate=${encodeURIComponent(
                                                                        cruise.SailDate
                                                                    )}&cruiseId=${encodeURIComponent(
                                                                        cruise._id
                                                                    )}`,
                                                                }}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                title="Open details in new tab"
                                                                onClick={() =>
                                                                    console.log(
                                                                        "Link Clicked. CruiseDetailsPage opening"
                                                                    )
                                                                }
                                                            >
                                                                <button className="your-button-styles">
                                                                    <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                                                                </button>
                                                            </Link>
                                                        </button>
                                                    </div>
                                                    <div className="flex mt-1 items-center text-gray-500">
                                                        {cruise.Nights} nights -{" "}
                                                        {new Date(
                                                            cruise.SailDate
                                                        ).toLocaleDateString(
                                                            "en-US",
                                                            {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "numeric",
                                                            }
                                                        )}
                                                    </div>
                                                </td>

                                                {/* Category */}
                                                <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
                                                    <div className="mt-1 text-gray-900">
                                                        {
                                                            getCategoryDetails(
                                                                cruise
                                                            ).code
                                                        }
                                                    </div>
                                                    <div className="mt-1 text-gray-500">
                                                        {
                                                            getCategoryDetails(
                                                                cruise
                                                            ).name
                                                        }
                                                    </div>
                                                    {cruise.CruiseLine ===
                                                        "Virgin Voyages" && (
                                                        <>
                                                            <div className="mt-1 text-gray-900">
                                                                {
                                                                    cruise.CategoryClass
                                                                }
                                                            </div>
                                                            <div className="mt-1 text-gray-500">
                                                                {
                                                                    cruise.Category
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </td>

                                                {/* Current Price/Group Rate */}
                                                <td className="whitespace-nowrap px-3 py-2 sm:py-5 text-sm text-gray-500">
                                                    <div className="sm:hidden">
                                                        Current Price:{" "}
                                                    </div>{" "}
                                                    <span
                                                        className={
                                                            isNaN(
                                                                parseFloat(
                                                                    cruise.CurrentGroupRate
                                                                )
                                                            ) ||
                                                            parseFloat(
                                                                cruise.CurrentGroupRate
                                                            ) === 0
                                                                ? "inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-600/20"
                                                                : "inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-600/20"
                                                        }
                                                    >
                                                        {isNaN(
                                                            parseFloat(
                                                                cruise.CurrentGroupRate
                                                            )
                                                        ) ||
                                                        parseFloat(
                                                            cruise.CurrentGroupRate
                                                        ) === 0 ? (
                                                            <span
                                                                className="text-red-500"
                                                                title="Cabin no longer available through the cruise line"
                                                            >
                                                                SOLD OUT
                                                            </span>
                                                        ) : (
                                                            <>
                                                                {cruise.Currency ===
                                                                "CAD" ? (
                                                                    <CA
                                                                        title="Canada"
                                                                        className="w-4 mr-1"
                                                                    />
                                                                ) : (
                                                                    <US
                                                                        title="United States"
                                                                        className="w-4 mr-1"
                                                                    />
                                                                )}
                                                                $
                                                                {parseFloat(
                                                                    cruise.CurrentGroupRate
                                                                ).toFixed(
                                                                    2
                                                                )}{" "}
                                                                + taxes
                                                            </>
                                                        )}
                                                    </span>
                                                </td>

                                                {/* Our Price/Group Rate */}
                                                <td className="whitespace-nowrap px-3 py-2 sm:py-5 text-sm text-gray-500">
                                                    <div className="sm:hidden">
                                                        Our Price:{" "}
                                                    </div>{" "}
                                                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                        {/* This will only be visible on small screens and will cause a line break */}
                                                        {cruise.Currency ===
                                                        "CAD" ? (
                                                            <CA
                                                                title="Canada"
                                                                className="w-4 mr-1"
                                                            />
                                                        ) : (
                                                            <US
                                                                title="United States"
                                                                className="w-4 mr-1"
                                                            />
                                                        )}
                                                        $
                                                        {parseFloat(
                                                            cruise.GroupRate
                                                        ).toFixed(2)}{" "}
                                                        + taxes
                                                    </span>
                                                </td>

                                                {/* Savings */}
                                                <td className="whitespace-nowrap px-3 py-2 sm:py-5 text-sm text-gray-500">
                                                    <div className="sm:hidden">
                                                        Savings:{" "}
                                                    </div>{" "}
                                                    <div
                                                        className={
                                                            isNaN(
                                                                parseFloat(
                                                                    cruise.CurrentPriceAdv
                                                                )
                                                            )
                                                                ? "bg-red-50 ring-1 ring-inset ring-red-600/20 p-2 rounded-md w-auto"
                                                                : "bg-green-50 ring-1 ring-inset ring-green-600/20 p-2 rounded-md w-auto"
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                isNaN(
                                                                    parseFloat(
                                                                        cruise.CurrentPriceAdv
                                                                    )
                                                                )
                                                                    ? "font-medium text-red-500"
                                                                    : "font-medium text-green-700"
                                                            }
                                                        >
                                                            {isNaN(
                                                                parseFloat(
                                                                    cruise.CurrentPriceAdv
                                                                )
                                                            ) ? (
                                                                <span title="Cabin no longer available through the cruise line">
                                                                    SOLD OUT
                                                                </span>
                                                            ) : (
                                                                `Save $${parseFloat(
                                                                    cruise.CurrentPriceAdv
                                                                ).toFixed(2)}`
                                                            )}
                                                        </div>
                                                        <div className="flex items-center text-gray-500">
                                                            {isNaN(
                                                                cruise.CurrentPercentAdv
                                                            )
                                                                ? "SOLD OUT"
                                                                : `${cruise.CurrentPercentAdv}% OFF`}

                                                            {(isNaN(
                                                                cruise.CurrentPriceAdv
                                                            ) ||
                                                                cruise.CurrentPriceAdv ===
                                                                    0) && (
                                                                <button
                                                                    className="ml-2 bg-transparent border-0 cursor-pointer text-indigo-500 focus:outline-none"
                                                                    onClick={() =>
                                                                        showSoldOutModal(
                                                                            cruise.CruiseLine
                                                                        )
                                                                    }
                                                                >
                                                                    <InformationCircleOutline />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>

                                                {/* Inquire */}
                                                <td className="relative whitespace-nowrap py-2 sm:py-5 pl-3 pr-4 text-sm font-medium sm:pr-0 sm:text-right text-left">
                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                cruise.CruiseLine ===
                                                                "Virgin Voyages"
                                                            ) {
                                                                // Open a URL in a new window for Virgin Voyages
                                                                window.open(
                                                                    cruise.Url,
                                                                    "_blank"
                                                                );
                                                                if (!isDev) {
                                                                    ReactGA.event(
                                                                        {
                                                                            category:
                                                                                "User",
                                                                            action: "Book Virgin Clicked",
                                                                        }
                                                                    );
                                                                }
                                                            } else {
                                                                // Perform the original action (onInquireClick) for other CruiseLines
                                                                onInquireClick(
                                                                    cruise._id,
                                                                    cruise.CruiseLine,
                                                                    cruise.Ship,
                                                                    cruise.SailDate,
                                                                    cruise.Nights,
                                                                    cruise.Product,
                                                                    cruise.CurrentPriceAdv,
                                                                    cruise.CurrentPercentAdv,
                                                                    cruise.GroupRate
                                                                );
                                                                console.log(
                                                                    "Inquire button clicked. Here's the data being passed through",
                                                                    "id:",
                                                                    cruise._id,
                                                                    "curiseline",
                                                                    cruise.CruiseLine,
                                                                    "ship:",
                                                                    cruise.Ship,
                                                                    "saildate",
                                                                    cruise.SailDate,
                                                                    "nights",
                                                                    cruise.Nights,
                                                                    "product",
                                                                    cruise.Product,
                                                                    "currpriceadv",
                                                                    cruise.CurrentPriceAdv,
                                                                    "currgroupadv",
                                                                    cruise.CurrentPercentAdv,
                                                                    "grouprate",
                                                                    cruise.GroupRate
                                                                );
                                                            }
                                                        }}
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                    >
                                                        {cruise.CruiseLine ===
                                                        "Virgin Voyages"
                                                            ? "Book"
                                                            : "Inquire"}
                                                        <span className="sr-only">
                                                            , {cruise._id}
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                            {!subscriptionActive && (
                                <div
                                    style={{
                                        top: overlayTop + "1000px",
                                        height: "135px",
                                    }}
                                    className="absolute left-0 w-full bg-white bg-opacity-30 flex items-center justify-center z-10 pointer-events-auto"
                                >
                                    <div className="text-center">
                                        <p className="mb-4">
                                            Subscribe to access more cruises!
                                        </p>
                                        <button
                                            className="bg-blue-600 text-white py-2 px-4 rounded"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Subscribe Now
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        {soldOutModalOpen && (
                            <SoldOutModal
                                cruiseLine={selectedSoldOutCruiseLine}
                                closeModal={() => {
                                    setSoldOutModalOpen(false);
                                    setselectedSoldOutCruiseLine(null);
                                }}
                            />
                        )}

                        {shipDetailModalOpen && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflowY: "auto", // added overflowY here
                                }}
                            >
                                <ShipDetailModal
                                    isOpen={shipDetailModalOpen}
                                    onClose={() => toggleShipDetailModal(false)}
                                    shipName={currentCruiseShip} // Passed ship name here
                                    operator={currentOperator} // Pass operator name
                                />
                            </div>
                        )}

                        {cruiseDetailModalOpen && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflowY: "auto", // added overflowY here
                                }}
                            >
                                <CruiseDetailModal
                                    isOpen={cruiseDetailModalOpen}
                                    onClose={() => {
                                        toggleCruiseDetailModal(false);
                                        toggleCruiseUnavailableModal(false);
                                    }}
                                    toggleCruiseDetailModal={
                                        toggleCruiseDetailModal
                                    } // Pass it here
                                    sailDate={sailDateForCruiseDetail}
                                    shipName={shipNameForCruiseDetail}
                                />
                            </div>
                        )}

                        <SubscriptionModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            onConfirm={() => {
                                dispatch(addToCart(activeUser));
                                if (!isDev) {
                                    ReactGA.event({
                                        event_category: "E-commerce",
                                        event_action: "Add to Cart",
                                        event_label: "Annual Subscription",
                                    });
                                }
                                // Handle confirmation logic here
                                // For example, navigate back or take any other action
                                window.location.href = `https://buy.stripe.com/test_5kAbMg8kGbiB6bK288?client_reference_id=${activeUser._id}`;
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

//  This should've prevented CruiseList from re-rendering everytime we go in/out of ContactUs
export default React.memo(CruiseList);
// export default CruiseList;
