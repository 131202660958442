const shipList = [
  // Royal Caribbean Ships
  {
    ship: "Adventure of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/12/44/afba2a8f-6459-4cb6-8e81-87adb79db1e7/RCI Adventure of the Seas Exterior.JPG",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/00/42a6c0c2-37f7-4dd6-84c7-2e34ab6729f0/RCI Adventure of the Seas Exterior.JPG",
  },
  {
    ship: "Allure of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/13/17/b83d74ca-aa43-4a5d-adc2-c1d9c51566ae/RCI Allure of the Seas Exterior.JPG",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/12/dd447c9f-ec0c-43ad-a6a0-9a303cb780e8/RCI Allure of the Seas Exterior.JPG",
  },
  {
    ship: "Anthem of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/17/53/38/fb835495-a0e4-44be-a4d2-3322cead5f63/RCI Anthem of the Seas Exterior 3.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/16/19/4aea20b2-d868-44b8-8ed9-638ca7b5a257/RCI Anthem of the Seas Exterior 3.jpg",
  },
  {
    ship: "Brilliance of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/14/46/d770a749-9992-4fd4-830b-b549331bc818/ Royal Caribbean International Brilliance of the Seas Exterior Mountain 2.jpeg'",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/49/9147ce8d-725c-4089-853f-07b8aef40128/ Royal Caribbean International Brilliance of the Seas Exterior Mountain 2.jpeg",
  },
  {
    ship: "Enchantment of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/12/35/24bb0cf2-e061-45b2-b3be-ddfb4b1b8756/adventure of the seas enchantment of the seas exterior at sea 2.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/20/53/c7a17b95-d7a2-47a1-b83a-814bfc66be38/adventure of the seas enchantment of the seas exterior at sea 2.jpg",
  },
  {
    ship: "Explorer of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/12/27/0b29b7e6-dca6-4277-b679-e63cca7eac82/Royal Caribbean International Explorer of the Seas Exterior Land 5.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/20/42/d51fb4d5-6050-4a6b-8ac2-28dcc5732c75/Royal Caribbean International Explorer of the Seas Exterior Land 5.jpg",
  },
  {
    ship: "Freedom of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/23/34/aa31d591-419e-44f5-a17e-414be9f23d38/royal caribbean international freedom of the seas exterior ariel at sea.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/42/e3af4a9b-ee6b-4f38-adb1-17685ffd6d10/royal caribbean international freedom of the seas exterior ariel at sea.jpg",
  },
  {
    ship: "Grandeur of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/12/22/3df12526-c538-4394-a7b2-a9c2c89b584b/Grandeur of the Seas exterior image.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/17/52/368464ff-2114-48a7-aa1f-547e049f27d3/Grandeur of the Seas exterior image.jpg",
  },
  {
    ship: "Harmony of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/12/07/8bef5419-8fc7-49be-8fe5-2987a3797ba9/Royal Caribbean International Harmony of the seas exterior image.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/17/39/e016d410-01dc-450c-b82d-ab92dbcac261/Royal Caribbean International Harmony of the seas exterior image.jpg",
  },
  {
    ship: "Icon of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/10/57/7898b826-4626-444e-ad7f-ec1bd7705daa/RCI Icon of the Seas Aft Exterior Rendering.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/17/09/ce334739-0a4c-46bd-983b-57d102d918da/RCI Icon of the Seas Aft Exterior Rendering.jpg",
  },
  {
    ship: "Independence of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/11/34/2084b43d-f3ad-42e7-8faf-bb63e9809616/Royal Caribbean International Independence of the Seas exterior side.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/17/32/aec36c3b-48c5-4469-a0c5-c8820bfb4b48/Royal Caribbean International Independence of the Seas exterior side.jpg",
  },
  {
    ship: "Jewel of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/11/24/07120b40-6153-4666-b7aa-3afdd4a3f2a7/royal caribbean international jewel of the seas  exterior 1.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/17/26/51774023-e98b-4aec-a9d2-b38cc0460e4d/royal caribbean international jewel of the seas  exterior 1.jpg",
  },
  {
    ship: "Liberty of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/15/22/195e8d91-282f-408e-b75e-733d290eac55/Royal Caribbean International Liberty of the Seas Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/22/15/277e06b3-fbd8-46cd-a4c2-abcc9b1aa970/Royal Caribbean International Liberty of the Seas Exterior.jpg",
  },
  {
    ship: "Mariner of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/16/42/96d76aec-5483-4c4c-ad47-7fd2e4612c7d/RCI Mariner of the Seas Exterior.JPG",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/36/02/7426e6c5-af24-427c-951c-f13c5de58fb7/RCI Mariner of the Seas Exterior.JPG",
  },
  {
    ship: "Navigator of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/23/31/82193257-dcd5-425b-99a1-9da8355872a0/Navigator of the Seas.JPG",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/35/49/6ea615ff-bc27-4085-91e4-0b7104771686/Navigator of the Seas.JPG",
  },
  {
    ship: "Oasis of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/14/55/1514d583-57a0-4398-82bb-095c11519941/Royal Caribbean International Oasis of the Seas Exterior DEV5341.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/35/45/0490da6f-9c7a-4b44-93bb-cefc2bdbcd52/Royal Caribbean International Oasis of the Seas Exterior.jpg",
  },
  {
    ship: "Ovation of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/15/03/7b4ef704-8e23-43cf-914f-07ccb524509f/Royal Caribbean International Quantum of the Seas Exterior Aerials NY at Sea010 RT.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/22/03/0cd631cf-3e5a-42c4-a3aa-73fb1b39c090/Royal Caribbean International Quantum of the Seas Exterior Aerials NY at Sea010 RT.jpg",
  },
  {
    ship: "Odyssey of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/15/15/585d2f4a-958d-4b66-86cb-10a6a514bb20/RCI Odyssey of the Seas Ext.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/22/12/c494cb0a-ea30-4d5a-81f9-903913eb55c9/RCI Odyssey of the Seas Ext.jpg",
  },
  {
    ship: "Quantum of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/11/16/e731f6d3-acd3-4330-959c-3bbafe06ce53/RCI_QN-AerialsNYatSea039-RT.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/17/21/b72047c5-d97a-4e47-a47d-7f819b007f5f/RCI_QN-AerialsNYatSea039-RT.jpg",
  },
  {
    ship: "Radiance of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/15/31/31b6e895-d1f8-4e82-a513-7d620a9d4daa/Radiance of the Seas Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/22/23/f1e3cdea-7bad-44ff-b845-cde4b3e63b08/Radiance of the Seas Exterior.jpg",
  },
  {
    ship: "Rhapsody of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/14/05/4fdff43c-ee81-44a8-a84d-d204e4d167d1/Royal Caribbean International Rhapsody of the Seas Exterior 071F.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/31/d7f0855f-9521-4d18-a564-0bc4a9c4acc0/Royal Caribbean International Rhapsody of the Seas Exterior 071F.jpg",
  },
  {
    ship: "Serenade of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/14/12/c1795729-61f6-4a56-83f2-34861a474a7f/Royal Caribbean International Serenade of the Seas Exterior 16.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/36/e629e417-4fe8-4ba9-9e88-3e2e33f121af/Royal Caribbean International Serenade of the Seas Exterior 16.jpg",
  },
  {
    ship: "Symphony of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/14/55/1514d583-57a0-4398-82bb-095c11519941/Royal Caribbean International Oasis of the Seas Exterior DEV5341.jpg",

    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/55/753eb991-790e-4ba6-b951-1d8242b00001/Royal Caribbean International Oasis of the Seas Exterior DEV5341.jpg",
  },
  {
    ship: "Spectrum of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/13/02/b4f580ab-9fd3-4d9f-9992-fc28c23f1510/RCI Spectrum of the Seas Exterior 0.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/06/70359268-1213-4501-aa49-e382b8ff6dd6/RCI Spectrum of the Seas Exterior 0.jpg",
  },
  {
    ship: "Utopia of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/08/42/d814b62e-7b1b-40c5-82e3-f6804a97f8fe/RCI Utopia of the Seas Rendering 0.jpg.JPG",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/16/29/c74f267a-1ec7-4900-99e0-bedb57763fa1/RCI Utopia of the Seas Rendering 0.jpg.JPG",
  },
  {
    ship: "Vision of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/11/08/bd0ea5dd-9554-49ec-9264-fe23894cd4e2/royal caribbean international vision of the seas exterior at sea.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/17/16/17800cc0-d357-4b67-be5f-74daa6c5c91e/royal caribbean international vision of the seas exterior at sea.jpg",
  },
  {
    ship: "Voyager of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/13/24/5858295f-57f8-4a4e-8f00-c0501e855297/Royal Caribbean International Voyager of the seas new exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/21/18/c26e6582-4e9a-4196-877c-55a582203059/Royal Caribbean International Voyager of the seas new exterior.jpg",
  },
  {
    ship: "Wonder of the Seas",
    cruiseLine: "Royal Caribbean",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/24/23/10/47/2ce6f74e-d33c-4907-956b-7d542c3e48dc/RCI Wonder of the Seas Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/18/16/38/73087d8c-e7a4-4fc2-9cd1-095f34646629/RCI Wonder of the Seas Exterior.jpg",
  },

  // Celebrity Ships
  {
    ship: "Celebrity Edge",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/32/41/6b215c5d-641b-4921-bd58-9ab3b4566971/Celebrity Crusies Celebrity Edge Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/56/41/6757ac29-50c4-494e-9250-ba1beec4be0a/Celebrity Crusies Celebrity Edge Exterior.jpg",
  },
  {
    ship: "Celebrity Apex",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/32/07/9d1b6379-8419-4399-aba1-a2818bb3de18/Celebrity Cruises - Apex Simulated Image.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/56/14/efd58c5a-5155-474b-8c62-749549196081/Celebrity Cruises - Apex Simulated Image.jpg",
  },
  {
    ship: "Celebrity Beyond",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/32/13/b28a4aad-bf6a-4ed5-98b3-9b20a9f22be1/Celebrity Cruises Celebrity Beyond.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/56/20/1666f360-b2c5-4825-a265-f65a7f0bdfa8/Celebrity Cruises Celebrity Beyond.jpg",
  },
  {
    ship: "Celebrity Ascent",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/32/21/d73f0691-2ccb-425f-a822-55372b905467/Celebrity Cruises Celebrity Ascent Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/56/28/155d8430-3f4e-45cb-888a-bac9d5bd470b/Celebrity Cruises Celebrity Ascent Exterior.jpg",
  },
  {
    ship: "Celebrity Constellation",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/31/04/495b6acc-e406-4ec6-ab11-ddcb87b5e0f2/Celebrity Cruises Celebrity Constellation Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/55/18/56f7a252-cc2f-4770-9f17-eb76446c817f/Celebrity Cruises Celebrity Constellation Exterior.jpg",
  },
  {
    ship: "Celebrity Equinox",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/31/23/3567e893-a081-4e3b-92bb-727be14f7fcb/Celebrity Cruises Celebrity Equinox Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/55/36/d9f3b180-c510-47c7-938c-c15da0f2a0ca/Celebrity Cruises Celebrity Equinox Exterior.jpg",
  },
  {
    ship: "Celebrity Silhouette",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/31/33/d80b93f5-6ea3-410d-8c82-6051a3476d24/Celebrity Cruises Celebrity Silhouette Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/55/41/96cf83bf-d305-4798-ac5a-afcb64dd7a96/Celebrity Cruises Celebrity Silhouette Exterior.jpg",
  },
  {
    ship: "Celebrity Solstice",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/31/37/1f786926-41fc-4bf4-a613-1a057733121c/Celebrity Cruises Celebrity Solstice Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/55/48/a1fdebed-0094-4d6b-ab5f-b95fdb3f1e1e/Celebrity Cruises Celebrity Solstice Exterior.jpg",
  },
  {
    ship: "Celebrity Infinity",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/32/28/f45448e8-7d09-47ed-9f83-1bfaa80576ed/Celebrity Cruises Celebrity Infinity Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/56/33/cf201a83-af35-4a8c-867a-5568edda1c9d/Celebrity Cruises Celebrity Infinity Exterior.jpg",
  },
  {
    ship: "Celebrity Eclipse",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/31/11/0f17357d-c50c-497a-b9c9-0ed3d7736890/Celebrity Cruises Celebrity Eclipse Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/55/24/015e7ded-c672-4d4c-be5b-7d98fd127c48/Celebrity Cruises Celebrity Eclipse Exterior.jpg",
  },
  {
    ship: "Celebrity Reflection",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/23/27/6e791907-06b0-482c-a5c3-936a4a4635ec/Celebrity Cruises Celebrity Reflection Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/24/44/799ebeec-922c-4924-b518-0ddea5a85b72/Celebrity Cruises Celebrity Reflection Exterior.jpg",
  },
  {
    ship: "Celebrity Summit",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/31/46/6768c886-a5c7-4e6b-b571-34f9d3d71b74/Celebrity Cruises Celebrity Summit Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/55/57/e0a1084f-5231-4ab7-a6db-caa7639650fc/Celebrity Cruises Celebrity Summit Exterior.jpg'",
  },
  {
    ship: "Celebrity Millennium",
    cruiseLine: "Celebrity",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/16/30/59/f6ee1a12-3e10-4d9f-970a-f3a0768953ca/Celebrity Cruises Celebrity Millennium Exterior.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/17/55/12/f8f6bf34-acc6-4fca-b424-ed05adbe5e44/Celebrity Cruises Celebrity Millennium Exterior.jpg",
  },

  // Virgin Ships
  {
    ship: "Valiant Lady",
    cruiseLine: "Virgin Voyages",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/23/09/15/f8e3d65e-94a6-426c-9463-678711905b2f/Virgin Voyages Scarlet Lady Exterior 1.JPG",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/22/57/24/96a40219-0753-46ed-9b65-1171787616b7/Virgin Voyages Scarlet Lady Exterior 1.JPG",
  },
  {
    ship: "Brilliant Lady",
    cruiseLine: "Virgin Voyages",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/23/09/16/077e5faa-8527-4e62-815b-e8e6374585d8/Virgin Voyages Brilliant Lady Exterior Rendering.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/22/57/32/34d34eae-4e57-4a4e-aaab-e7cc8148fc64/Virgin Voyages Brilliant Lady Exterior Rendering.jpg",
  },
  {
    ship: "Scarlet Lady",
    cruiseLine: "Virgin Voyages",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/23/09/27/de8ac7cd-8d04-4a89-acd3-e34ad56eada1/Virgin Voyages Scarlet Lady Exterior 0.JPG",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/22/57/41/5fc518c7-e4f2-4e8d-8c19-b2073327facf/Virgin Voyages Scarlet Lady Exterior 0.JPG",
  },
  {
    ship: "Resilient Lady",
    cruiseLine: "Virgin Voyages",
    selected: false,
    compressedImageURI500x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/23/09/34/3b09dd5a-bf08-429d-ac69-60b124ad6e7b/Virgin Voyages Resilient Lady Sunset Render.jpg",
    compressedImageURI1000x:
      "https://widgety-assets.s3.amazonaws.com/2023/10/25/22/57/47/c4af2c80-653c-411c-8000-d7a329b35b4b/Virgin Voyages Resilient Lady Sunset Render.jpg",
  },
];

module.exports = shipList;
