import {
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

export default function Pagination({
    currentPage,
    totalPages,
    handleNext,
    handlePrev,
    handlePage,
}) {
    const generatePages = () => {
        // console.log("On page", currentPage, "of", totalPages);
        let pages = [];

        // if totalPages is less than or equal to 7, show all
        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Always show first 2 and last 2 pages
            pages = [1, 2, totalPages - 1, totalPages];

            // If currentPage is less than 4 (but greater than 2 since it's covered above), show first four pages
            if (currentPage < 4) {
                pages = [1, 2, 3, 4, totalPages - 1, totalPages];
            }
            // If currentPage is in last four pages, show the last four
            else if (currentPage > totalPages - 3) {
                pages = [
                    1,
                    2,
                    totalPages - 3,
                    totalPages - 2,
                    totalPages - 1,
                    totalPages,
                ];
            }
            // Else show currentPage and its neighbors
            else {
                pages = [
                    1,
                    2,
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    totalPages - 1,
                    totalPages,
                ];
            }
        }

        // Add ... where there's a gap of more than one number
        let finalPages = [];
        for (let i = 0; i < pages.length; i++) {
            finalPages.push(pages[i]);
            if (i < pages.length - 1 && pages[i + 1] - pages[i] > 1) {
                finalPages.push("...");
            }
        }

        return finalPages;
    };
    
    const pages = generatePages();

    return (
        <nav className="flex items-center justify-between border-b border-gray-200 pb-4 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                <button
                    onClick={handlePrev}
                    disabled={currentPage === 1}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    <ArrowLongLeftIcon
                        className="mr-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    Previous
                </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {pages.map((page, index) => {
                    if (page === "...") {
                        return (
                            <span
                                key={`ellipsis-${index}`}
                                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                            >
                                ...
                            </span>
                        );
                    }
                    return (
                        <button
                            key={page}
                            onClick={() => {
                                if (page !== "..." && page !== currentPage) {
                                    handlePage(page);
                                }
                            }}
                            className={`inline-flex items-center px-4 pt-4 text-sm font-medium ${
                                page === currentPage
                                    ? "border-t-2 border-indigo-500 text-indigo-600"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            }`}
                            aria-current={
                                page === currentPage ? "page" : undefined
                            }
                        >
                            {page}
                        </button>
                    );
                })}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages} // assuming you've added this prop
                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    Next
                    <ArrowLongRightIcon
                        className="ml-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </button>
            </div>
        </nav>
    );
}
