import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchCruisePricingWithoutCruiseId = createAsyncThunk(
  "cruises/fetchCruisePricingWithoutCruiseId",
  async (data, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user; // Assuming the token and userId are stored in the activeUser state

    console.log("Token:", token);
    console.log("UserId:", _id);

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };

    try {
      const response = await axios.post(
        `https://${apiBaseUrl}/cruises/pricing/public/`,
        config,
        data
      );
      return response.data;
    } catch (error) {
      // Handle error as needed
      throw error;
    }
  }
);

export { fetchCruisePricingWithoutCruiseId };
