import React from "react";

export default function NotFoundPage({ cruiseUnavailable }) {
  const handleCloseTab = () => {
    // Close the tab or window
    window.close();
  };

  //  This is tryna incorporate SendGrid
  // const handleCloseTab = async () => {
  //   try {
  //     const response = await fetch("/api/sendErrorCruise", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         cruiseName: "Cruise Name", // Replace with the cruise name
  //         userEmail: "user@example.com", // Replace with the user's email
  //       }),
  //     });

  //     if (response.ok) {
  //       // Email sent successfully, you can handle the response as needed
  //       window.close();
  //     } else {
  //       console.error("Error sending error email.");
  //     }
  //   } catch (error) {
  //     console.error("Error sending error email:", error);
  //   }
  // };

  return (
    <main className="relative isolate min-h-screen">
      <img
        src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
      />
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <p className="text-xl font-semibold leading-8 text-white">404 Error</p>
        {cruiseUnavailable ? (
          <>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
              Cruise Unavailable
            </h1>
            <p className="mt-4 text-base text-white/70 sm:mt-6">
              We could not fetch the data corresponding to this cruise. This
              likely means the cruise is no longer offered by the cruise line.
            </p>
            <p className="mt-6 text-base text-white/70 sm:mt-6">
              We are aware of this error and are working on fixing it.
            </p>
          </>
        ) : (
          <>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
              Page not found
            </h1>
            <p className="mt-4 text-base text-white/70 sm:mt-6">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
          </>
        )}
        <div className="mt-10 flex justify-center">
          <button
            onClick={handleCloseTab}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            Close tab
          </button>
        </div>
      </div>
    </main>
  );
}
