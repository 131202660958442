import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginUserReducer from "./slices/loginUserSlice";
import usersReducer from "./slices/usersSlice"; // The combined slice
import cruisesReducer from "./slices/cruisesSlice";
import fetchCruiseLinesReducer from "./slices/fetchCruiseLinesSlice";
import fetchSubscriptionStatusReducer from "./slices/fetchSubscriptionStatusSlice";
import fetchCruiseDetailsReducer from "./slices/widgety/fetchCruiseDetailsSlice";
import fetchShipReducer from "./slices/widgety/fetchShipDetailsSlice";
import fetchCruiseReducer from "./slices/fetchCruiseSlice";
import fetchCruisePricingReducer from "./slices/fetchCruisePricing";
import editActiveUserReducer from "./slices/editActiveUserSlice";
import fetchAdminMetricsReducer from "./slices/admin/fetchAdminMetricsSlice";

import thunk from "redux-thunk";

const persistConfig = {
    key: "hcf",
    storage,
};

const rootReducer = persistReducer(
    persistConfig,
    combineReducers({
        activeUser: loginUserReducer,
        users: usersReducer,
        editUser: editActiveUserReducer,
        cruises: cruisesReducer,
        cruise: fetchCruiseReducer,
        ship: fetchShipReducer,
        cruiseDetails: fetchCruiseDetailsReducer,
        cruiseLines: fetchCruiseLinesReducer,
        subscriptionStatus: fetchSubscriptionStatusReducer,
        cruisePricing: fetchCruisePricingReducer,
        adminMetrics: fetchAdminMetricsReducer,
    })
);

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/loginUser";
export * from "./thunks/logoutUser";
export * from "./thunks/registerUser";
export * from "./thunks/fetchCruises";
export * from "./thunks/widgety/fetchCruiseLines";
export * from "./thunks/fetchSubscriptionStatus";
export * from "./thunks/fetchCruise";
export * from "./thunks/fetchCruisePricing";
export * from "./thunks/widgety/fetchCruiseDetails";
export * from "./thunks/widgety/fetchShipDetails";
export * from "./thunks/google/sendGa4Event";
export * from "./thunks/admin/fetchAdminMetrics";
