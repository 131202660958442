import shipList from "../components/cruiseList/shipList";
import regionList from "../components/cruiseList/regionList";

export const initialFilters = {
  nights: [
    { value: "1-3", label: "1 - 3 nights", checked: false },
    { value: "4-7", label: "4 - 7 nights", checked: false },
    { value: "8-12", label: "8 - 12 nights", checked: false },
    { value: "13", label: "13 + nights", checked: false },
  ],
  categoryClass: [
    { value: "I", label: "I - Inside", checked: false },
    { value: "O", label: "O - Oceanview", checked: false },
    { value: "B", label: "B - Balcony", checked: false },
    { value: "N", label: "N - Neighbourhood", checked: false },
    { value: "S", label: "S - Suite", checked: false },
  ],
  priceAdvantage: [
    { value: "0-499", label: "$0 - $499", checked: false },
    { value: "500-999", label: "$500 - $999", checked: false },
    { value: "1000-1499", label: "$1000 - $1499", checked: false },
    { value: "1500", label: "$1500 +", checked: false },
  ],
  percentAdvantage: [
    { value: "0-15", label: "0% - 15%", checked: false },
    { value: "16-30", label: "16% - 30%", checked: false },
    { value: "31-45", label: "31% - 45%", checked: false },
    { value: "46", label: "46% +", checked: false },
  ],
  regionList: regionList,
  shipList: shipList,
  excludeSoldOut: true,
};
