import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCruises } from "../../store/thunks/fetchCruises";
import {
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import roomList from "../../components/cruiseList/roomList";
import CruiseFilter from "../../components/filter/CruiseFilter";
import { ClipLoader } from "react-spinners";
import EditCruiseModal from "../../components/admin/EditCruiseModal";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const Database = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null); // true for ascending, false for descending
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // const [totalPages, setTotalPages] = useState(1); // Set initial value after fetching the first time
  const [feedback, setFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("");
  const [editFormData, setEditFormData] = useState({
    GroupId: "",
    GroupName: "",
    Ship: "",
    SailDate: "",
    Product: "",
    Nights: null,
    Currency: "",
    CategoryClass: "",
    Category: "",
    RoomsAvailable: null,
    GroupRate: null,
    CurrentGroupRate: null,
    CurrentPriceAdv: null,
    CurrentPercentAdv: null,
    CombinedKey: "",
  });
  const [editMode, setEditMode] = useState(null);
  const [cruiseToSaveIndex, setCruiseToSaveIndex] = useState(null);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [filters, setFilters] = useState(initialFilters);
  const [selectedCruise, setSelectedCruise] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [sort, setSort] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCruiseLine, setSelectedCruiseLine] = useState("");
  const [selectedShip, setSelectedShip] = useState("");
  const [nights, setNights] = useState("");

  const [excludeSoldOut, setExcludeSoldOut] = useState(true);
  const [filters, setFilters] = useState({}); // Default empty filters or your base filters
  // const [filteredCruises, setFilteredCruises] = useState([]);

  // Extract cruises data and associated state from Redux store
  const dispatch = useDispatch();
  const cruises = useSelector((state) => state.cruises.cruisesList.cruises);
  const error = useSelector((state) => state.cruises.cruisesList.error);
  const status = useSelector((state) => state.cruises.cruisesList.status);
  const subscriptionActive = useSelector((state) => {
    const subscriptionStatus = state.subscriptionStatus.data.status === "active";
    const userIsAdmin = state.activeUser.data.user.user_type === "admin";
    
    return subscriptionStatus || userIsAdmin;
  });

  useEffect(() => {
    dispatch(fetchCruises());
  }, [dispatch]);

  const refreshData = () => {
    // Resetting the current page to 1
    setCurrentPage(1);
    const params = {
      sort: sort,
      sortOrder: sortOrder,
      page: currentPage,
      pageSize: 20,
      ship: selectedShip,
      cruiseLine: selectedCruiseLine,
      excludeSoldOut: excludeSoldOut,
      filters: encodeURIComponent(JSON.stringify(filters)),
      nights, // Add this line
    };
    dispatch(fetchCruises(params));
    // giveFeedback;
  };

  if (status === "loading") return <p>Loading...</p>;
  if (error === "failed") return <p>Error: {error}</p>;

  const giveFeedback = (success, errorMessage) => {
    const message = success ? "Data fetched" : errorMessage;
    const color = success ? "green" : "red";

    setFeedback(message);
    setFeedbackColor(color);

    setTimeout(() => {
      setFeedback("");
    }, 1750);
  };

  // Edit form for updating cruise
  function handleInputChange(e, field) {
    setEditFormData({
      ...editFormData,
      [field]: e.target.value,
    });
  }

  // Update/edit cruise function (INACTIVE RIGHT NOW)
  // const updateCruise = (index) => {
  //   console.log("cruise should be updated w working API");
  //   const cruise = displayedCruises[index];
  //   const updatedCruise = { ...cruise, ...editFormData };
  //   delete updatedCruise._id; // Remove the _id since we don't need to send it in the request body

  //   fetch(`https://${apiBaseUrl}/cruises/${cruise._id}`, {
  //     method: "PATCH", // Use PATCH as per your server route
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(updatedCruise),
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return res.json();
  //     })
  //     .then((resData) => {
  //       // Update the local state with the updated cruise data returned from the server
  //       const newCruises = [...cruises];
  //       const cruiseIndex = cruises.findIndex((u) => u._id === resData._id);
  //       newCruises[cruiseIndex] = resData;
  //       setCruises(newCruises);

  //       setFeedback("Cruise updated");
  //       setFeedbackColor("green");
  //       setTimeout(() => {
  //         setFeedback("");
  //       }, 1750);
  //       setEditMode(null);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setFeedback("Cruise update failed");
  //       setFeedbackColor("red");
  //       setTimeout(() => {
  //         setFeedback("");
  //       }, 1750);
  //     });
  // };

  const toggleEditModal = () => {
    setIsModalOpen((prevIsModalOpen) => {
      if (prevIsModalOpen) {
        setSelectedCruise(null); // Reset the selected cruise when closing the modal
      }
      return !prevIsModalOpen;
    });
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  function highlightTerm(text, term) {
    if (!term) return text; // Return original text if term is empty

    const escapedTerm = escapeRegExp(term);
    const splitText = String(text).split(new RegExp(`(${escapedTerm})`, "gi"));

    return splitText.map((part, i) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={i} className="bg-yellow-300">
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  function handleSort(column) {
    if (sort === column) {
      if (sortOrder === "desc") {
        setSort(null); // Reset sorting on third click
        setSortOrder(null);
      } else {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      }
    } else {
      setSort(column);
      setSortOrder("asc"); // Default order when clicking a new column
    }
  }

  const resetSort = () => {
    setSortField(null);
    setSortDirection(null);
  };

  const getSortIcon = (field) => {
    if (sort === field) {
      if (sortOrder === "asc") {
        return <BarsArrowUpIcon className="h-4 w-4 inline ml-1" />;
      } else if (sortOrder === "desc") {
        return <BarsArrowDownIcon className="h-4 w-4 inline ml-1" />;
      }
    }
    return <Bars3BottomLeftIcon className="h-3.5 w-3.5 inline ml-1" />;
  };

  // Cruises rendered on screen, with filter logic
  const displayedCruises = cruises.filter((cruise) => {
    return Object.values(cruise).some((val) =>
      String(val).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Combine existing logic with pagination
  const paginatedCruises = displayedCruises.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const clientSideFilteredCruises = cruises.filter(
    (cruise) => selectedShip === "" || cruise.Ship === selectedShip
  );

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      // to ensure it doesn't go into negative
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  // console.log("Selected Ship:", selectedShip);
  // console.log("Selected Cruise Line:", selectedCruiseLine);

  // For determining the cruise category for Celebrity/Royal Carribean
  const getCategoryDetails = (cruise) => {
    if (cruise.CruiseLine === "Celebrity") {
      const room = roomList.find(
        (room) => room.categoryCode === cruise.Category
      );
      return room
        ? { code: cruise.Category, name: room.categoryName }
        : { code: cruise.Category, name: "" };
    } else if (cruise.CruiseLine === "Royal Caribbean") {
      const room = roomList.find(
        (room) => room.categoryCode === cruise.CategoryClass
      );
      return room
        ? { code: cruise.CategoryClass, name: room.categoryName }
        : { code: cruise.CategoryClass, name: "" };
    }
    return { code: "", name: "" }; // fallback
  };

  //   NEW FROM REFACTORING
  const handleFiltersChanged = (updatedFilters) => {
    dispatch(fetchCruises(updatedFilters));
  };

  return (
    <div className="pt-4 ml-0 sm:px-6 lg:pr-10 lg:pl-0">
      {/* Header and Refresh DB button */}
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Database
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            All data from the cruise list.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <p
              className="text-base font-semibold leading-6 mr-2.5"
              style={{ color: feedbackColor }}>
              {feedback}
            </p>
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={refreshData}>
              Refresh Database
            </button>
          </div>
        </div>
      </div>

      {/* Search and Pagination */}
      <div className="my-4 flex justify-between">
        <div className="w-1/2 sm:w-1/4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by any field"
            className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        {/* <div className="inline-flex items-center rounded-md">
          <div className="px-3 py-2">
            <label htmlFor="itemsPerPage" className="sr-only">
              Items per page
            </label>
            <select
              id="itemsPerPage"
              className="border-gray-300 rounded-md shadow-sm"
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}>
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </div> */}
      </div>

      <CruiseFilter
        onFiltersChanged={handleFiltersChanged}
        subscriptionActive={subscriptionActive}
      />

      {/* Table */}
      <div className="mt-8 flow-root pb-20">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
            {status === "loading" ? (
              <tr>
                <td colSpan="5" className="text-center py-5">
                  <ClipLoader color="#4535be" size={75} />
                </td>
              </tr>
            ) : error ? (
              <div className="error-container">
                <p>Error fetching cruises: {error}</p>
                {/* <button onClick={handleClearError}>Clear Error</button> */}
              </div>
            ) : (
              <table className=" divide-y divide-gray-300 pr-8">
                {/* Table Header */}
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3
                      text-left text-sm font-semibold text-gray-400 sm:pl-0
                      cursor-pointer">
                      Action
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer"
                      onClick={() => handleSort("GroupId")}>
                      Group ID {getSortIcon("GroupId")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("GroupName")}>
                      Group Name {getSortIcon("GroupName")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("Ship")}>
                      Ship {getSortIcon("Ship")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("SailDate")}>
                      Sail Date {getSortIcon("SailDate")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("Product")}>
                      Product {getSortIcon("Product")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("Nights")}>
                      Nights {getSortIcon("Nights")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("Currency")}>
                      Currency {getSortIcon("Currency")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("CategoryClass")}>
                      Category Class {getSortIcon("CategoryClass")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("Category")}>
                      Category {getSortIcon("Category")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("RoomsAvailable")}>
                      Rooms Available {getSortIcon("RoomsAvailable")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("GroupRate")}>
                      Group Rate {getSortIcon("GroupRate")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("CurrentGroupRate")}>
                      Current Group Rate {getSortIcon("CurrentGroupRate")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("CurrentPriceAdv")}>
                      Price Advantage {getSortIcon("CurrentPriceAdv")}
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort("CurrentPercentAdv")}>
                      Percentage Advantage {getSortIcon("CurrentPercentAdv")}
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody className="divide-y divide-gray-200 bg-white">
                  {/* Loading State */}
                  {status === "loading" && (
                    <tr>
                      <td colSpan="10" className="text-center py-5">
                        <ClipLoader color="#4535be" size={75} />
                      </td>
                    </tr>
                  )}
                  {/* Error State */}
                  {error && (
                    <tr>
                      <td
                        colSpan="10"
                        className="text-center py-5 text-red-500">
                        Error: {error}
                      </td>
                    </tr>
                  )}
                  {/* Successful fetch but no cruises */}
                  {status === "succeeded" && cruises.length === 0 && (
                    <tr>
                      <td
                        colSpan="10"
                        className="text-center py-5 text-sm text-gray-500">
                        Could not find a cruise that matches your criteria
                      </td>
                    </tr>
                  )}
                  {/* Displaying the cruises data */}
                  {cruises.map((cruise) => (
                    <tr key={cruise._id} className="even:bg-gray-100">
                      <td>
                        <button
                          className="whitespace-nowrap py-2 text-sm text-gray-400"
                          onClick={() => {
                            setSelectedCruise(cruise);
                            setShowEditModal(true);
                            setIsModalOpen(true);
                          }}>
                          Edit
                        </button>
                      </td>
                      {/* Repeat similar structure for other columns */}
                      <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900">
                        {highlightTerm(cruise.GroupId, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(cruise.GroupName, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(cruise.Ship, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(
                          new Date(cruise.SailDate).toLocaleDateString(),
                          searchTerm
                        )}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(cruise.Product, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(cruise.Nights, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(cruise.Currency, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {cruise.CategoryClass
                          ? highlightTerm(cruise.CategoryClass, searchTerm)
                          : "-"}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(cruise.Category, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(cruise.RoomsAvailable, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(`$${cruise.GroupRate}`, searchTerm)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(
                          `$${cruise.CurrentGroupRate}`,
                          searchTerm
                        )}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(
                          `$${cruise.CurrentPriceAdv}`,
                          searchTerm
                        )}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {highlightTerm(
                          `${cruise.CurrentPercentAdv}%`,
                          searchTerm
                        )}
                      </td>
                    </tr>
                  ))}
                  {/* {cruises && cruises.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center py-10">
                        <h2 className="text-xl font-semibold mb-4">
                          No matching cruises
                        </h2>
                        <p>Try adjusting your filters or check back later.</p>
                      </td>
                    </tr>
                  ) : (
                    cruises.map((cruise, index) => (
                      <tr
                        key={cruise.CombinedKey}
                        ref={rowRefs[index]}
                        className={
                          (currentPage === 1 && index >= 10) || currentPage > 1
                            ? "blurred pointer-events-none"
                            : ""
                        }>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0"></div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {cruise.CruiseLine}
                              </div>
                              <div className="mt-1 text-gray-500">
                                {cruise.Ship}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {cruise.Nights} Nights -{" "}
                            {cruise.Product.toUpperCase()}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {new Date(cruise.SailDate).toLocaleDateString(
                              "en-US"
                            )}
                          </div>
                        </td>

                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="mt-1 text-gray-900">
                            {getCategoryDetails(cruise).code}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {getCategoryDetails(cruise).name}
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            $ {parseFloat(cruise.GroupRate).toFixed(2)} pp +
                            taxes
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {isNaN(parseFloat(cruise.CurrentPriceAdv)) ? (
                              <span title="Cabin no longer available through the cruise line">
                                SOLD OUT
                              </span>
                            ) : (
                              `Save $ ${parseFloat(
                                cruise.CurrentPriceAdv
                              ).toFixed(2)} pp`
                            )}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {isNaN(cruise.CurrentPercentAdv)
                              ? "SOLD OUT"
                              : `${cruise.CurrentPercentAdv}% OFF`}
                          </div>
                        </td>
                      </tr>
                    ))
                  )} */}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      {/* Editing modal */}
      {showEditModal && selectedCruise && (
        <EditCruiseModal
          key={selectedCruise?.id} // Assuming your cruise object has a unique 'id'
          isOpen={isModalOpen}
          onClose={toggleEditModal}
          cruise={selectedCruise}
        />
      )}
    </div>
  );
};

export default Database;
