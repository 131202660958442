import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchCruise = createAsyncThunk(
  "cruises/fetchCruise",
  async (id, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user; // Update with the correct property names

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };

    const response = await axios.get(
      `https://${apiBaseUrl}/cruises/${id}`,
      config
    );
    // console.log("response.data from fechCruises: ",response.data);
    return response.data;
  }
);

export { fetchCruise };
