import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
import CCNavBar from "../components/agentLandingPage/CCNavBar";
import HeroSection from "../components/agentLandingPage/HeroSection";
import PricingTiers from "../components/agentLandingPage/PricingTiers";
import PlatformOverview from "../components/agentLandingPage/PlatformOverview";
import FeatureList from "../components/agentLandingPage/FeatureList";
import GetStarted from "../components/agentLandingPage/GetStarted";
import Footer from "../components/agentLandingPage/Footer";
import NewsLetter from "../components/agentLandingPage/NewsLetter";
import Partners from "../components/agentLandingPage/Partners";
import Services from "../components/agentLandingPage/Services";
import Testimonials from "../components/agentLandingPage/Testimonials";
import TopDestination from "../components/agentLandingPage/TopDestination";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LandingPage.css";

function AgentLandingPage() {
  const location = useLocation();
  console.log(process.env.NODE_ENV);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referrer = urlParams.get("referrer");

    if (referrer) {
      Cookies.set("referrer", referrer, { expires: 180 }); // 180 days expiration
    }
  }, [location]);

  return (
    <>
      <CCNavBar />
      <HeroSection />
      <PlatformOverview />
      <PricingTiers />
      <FeatureList />
      <GetStarted />
      <Footer />
    </>
  );
}

export default AgentLandingPage;
