import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const buildQueryString = (params) => {
  const {
    sort,
    sortOrder,
    page,
    pageSize,
    cruiseLine,
    ship,
    excludeSoldOut,
    minSailDateObj,
    maxSailDateObj,
    nights,
    category,
    priceAdv,
    percAdv
  } = params;

  const soldOutParam = excludeSoldOut ? "&excludeSoldOut=true" : "";

  return `?sort=${sort}&sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}&cruiseLine=${cruiseLine}&ship=${ship}&excludeSoldOut=${soldOutParam}&minSailDateObj=${minSailDateObj}&maxSailDateObj=${maxSailDateObj}&nights=${nights}&category=${category}&priceAdv=${priceAdv}&percAdv=${percAdv}`; // Add nights parameter here
};

const fetchCruises = createAsyncThunk(
  "cruises/fetch",
  async (params, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user; // Assuming the token and userId are stored in the activeUser state

    const queryString = buildQueryString(params);
    // console.log("Query string is:", queryString);

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };

    try {
      const response = await axios.get(
        `https://${apiBaseUrl}/cruises${queryString}`,
        config
      );
      // console.log("fetched data, from inside fetchCruises:", response.data);

      return response.data;
    } catch (error) {
      // Handle any specific logic for errors if needed
      throw error;
    }
  }
);

export { fetchCruises };
