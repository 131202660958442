import { useState, useEffect } from "react";
import axios from "axios";
import Text from "./Text";
import { TopDestinationTexts } from "./DataLists";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "./Card";
import Button from "./Button";
import SignUpButton from "./SignUpButton";
import "./TopDestination.css";
import bookingImage2 from "../../assets/bookingImage2.jpeg";

const TopDestination = () => {
  const [specialCruises, setSpecialCruises] = useState([]);
  const [shipImages, setShipImages] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Trying new
  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://www.hiddencruisefares.com:6020/cruiseLines"
  //     );
  //     setSpecialCruises(response.data);
  //     console.log("logging special cruises", specialCruises);
  //     // Fetch ship images once specialCruises data is available
  //     loadShipImages(response.data);
  //   } catch (error) {
  //     console.error("Error fetching cruises:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [refreshData]);

  // Old stuff that works.. but is big
  useEffect(() => {
    fetchData();

    // Add an event listener to update isMobile when the window is resized
    window.addEventListener("resize", updateIsMobile);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", updateIsMobile);
    };
  }, [refreshData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://www.hiddencruisefares.com:6020/api/specialCruises"
      );
      setSpecialCruises(response.data);

      // Fetch ship images once specialCruises data is available
      loadShipImages(response.data);
    } catch (error) {
      console.error("Error fetching cruises:", error);
    }
  };

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const loadShipImages = async (cruises) => {
    const shipImagesData = {};

    // Extract unique cruise lines from the cruises
    const uniqueCruiseLines = [
      ...new Set(cruises.map((cruise) => cruise.CruiseLine)),
    ];

    // Get ships for each unique cruise line
    const cruiseLineShips = {};

    for (const cruiseLine of uniqueCruiseLines) {
      const operator = mapToApiOperator(cruiseLine);
      const ships = await getShipsForOperator(operator);
      cruiseLineShips[operator] = ships;
    }

    // Map ships to their images
    for (const cruise of cruises) {
      const shipName = cruise.Ship;
      const operator = mapToApiOperator(cruise.CruiseLine);

      const ships = cruiseLineShips[operator];
      const ship = ships.find((ship) => ship.title === shipName);

      shipImagesData[shipName] = ship ? ship.cover_image_href : null;
    }

    setShipImages(shipImagesData);
  };

  const getShipsForOperator = async (operator) => {
    try {
      const appId = "92db4501d45ba484ea7f70b5cfb3db238e1b9f6e";
      const appToken =
        "7974dbd6e613308e77530f989f593b96a393c3793687dd8d6bab7b1220133a7e";
      const page = 1; // Set the desired page number

      const apiUrl = `https://www.widgety.co.uk/api/ships.json?operator=${operator}&include_cruises=true&page=${page}&app_id=${appId}&token=${appToken}`;

      const response = await axios.get(apiUrl);
      return response.data.ships || [];
    } catch (error) {
      console.error("Error fetching ship data:", error);
      return [];
    }
  };

  const mapToApiOperator = (operator) => {
    const operatorMapping = {
      Celebrity: "celebrity-cruises",
      "Royal Caribbean": "royal-caribbean-international",
      // Add more mappings as needed
    };
    return operatorMapping[operator] || operator;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  // This one works fine to render the triple + updates
  return (
    <section className="w-full h-auto flex flex-col items-center justify-center relative lg:px-24 md:px-20 px-6 my-20">
      <div className="w-full mt-12 flex justify-between items-center md:px-6 px-3">
        <div className="flex-grow">
          <h1 className="text-3xl font-bold tracking-tight text-color3 sm:text-4xl">
            {TopDestinationTexts.secondText}
          </h1>
          {/* <Text
            as="h2"
            className="md:text-4xl text-2xl font-medium capitalize text-color3">
            {TopDestinationTexts.secondText}
          </Text> */}
        </div>
        <Button
          className="outline-none border-none lg:px-3 px-3 py-3 bg-color2 text-white font-semibold rounded-lg transform transition-all duration-300 hover:bg-purple-600 hover:scale-105 hover:shadow-lg"
          onClick={() => fetchData()}>
          Generate more
        </Button>
      </div>
      <br />
      <div className="flex justify-start">
        <Text
          as="h4"
          className="lg:text-2xl md:text-xl text-xl text-color1 font-medium">
          {TopDestinationTexts.thirdText}
        </Text>
      </div>

      <div className="w-full h-auto mt-4">
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          showStatus={true}
          showArrows={true}
          swipeable={true}
          emulateTouch={true}
          selectedItem={0}
          // centerMode={true}
          centerMode={!isMobile} // Center mode only on larger screens
          centerSlidePercentage={33.33}
          autoPlay={true}
          interval={5000}
          stopOnHover={true}
          dynamicHeight={true}>
          {specialCruises.map((cruise, index) => (
            <div key={index}>
              <Card className="px-2">
                {/* <Card textWrapperClass="flex flex-col gap-4 w-full px-5 py-5"> */}
                <div className="flex flex-row">
                  <div className="flex-grow">
                    {/* Picture container */}
                    <div className="relative h-72 w-full overflow-hidden rounded-lg">
                      <img
                        src={shipImages[cruise.Ship] || bookingImage2}
                        alt={cruise.Ship}
                        className="absolute inset-0 w-full h-full object-cover object-center"
                      />

                      <div className="absolute inset-x-0 bottom-0 flex h-36 items-end justify-end overflow-hidden rounded-lg p-4">
                        <div
                          aria-hidden="true"
                          className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                        />
                        <Text
                          as="small"
                          className="relative text-lg font-semibold text-white">
                          SAVING ${cruise.CurrentPriceAdv} PP (
                          {cruise.CurrentPercentAdv}%)
                        </Text>
                      </div>
                    </div>
                    {/* Text container */}
                    <div className="mt-4">
                      <Text
                        as="h4"
                        className="text-base font-medium text-color3">
                        {cruise.Ship}
                      </Text>
                      <div className="mt-1">
                        <Text
                          as="small"
                          className="text-color3 font-light text-sm">
                          {cruise.Nights} nights -{" "}
                        </Text>
                        <Text
                          as="small"
                          className="text-color3 font-light text-sm">
                          {cruise.Product}
                        </Text>
                      </div>
                      <Text
                        as="small"
                        className="text-color3 font-light text-sm">
                        Departs: {formatDate(cruise.SailDate)}
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
      {/* <br />
      <div className="flex justify-center">
        <SignUpButton props={"Start searching for deals"} />
      </div> */}
    </section>
  );
};

export default TopDestination;
