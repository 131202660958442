import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const ContactUsModal = ({
  cruiseId,
  cruiseShip,
  sailDate,
  nights,
  product,
  currentPriceAdv,
  currentPercentAdv,
  groupRate,
  category,
  onClose,
  cruiseData,
}) => {
  const apiToken = useSelector((state) => state.activeUser.data.user?.token);
  const activeUser = useSelector((state) => state.activeUser.data.user);
  const userId = activeUser._id;

  const [formData, setFormData] = useState({
    firstName: activeUser.first_name,
    lastName: activeUser.last_name,
    email: activeUser.email,
    phone: "",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(true); // Assume it starts as true
  const [countdown, setCountdown] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    // Add the event listener
    document.addEventListener("keydown", handleKeyDown);

    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!formData.phone) {
      setErrorMessage("Phone number is required!");
      return;
    }

    if (!formData.message) {
      setErrorMessage("A message about your inquiry is required!");
      return;
    }

    try {
      const response = await axios.post(
        `https://${apiBaseUrl}/contactAgent`,
        {
          ...formData,
          cruiseId,
          userId,
          //   name,
          //   ship_title,
          //   starts_on,
          //   starts_at,
          //   vacation_days,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-token": apiToken,
            "x-user-id": activeUser._id,
            // Add other headers as needed
          },
        }
      );

      if (response.status === 200) {
        // Changed from response.ok to response.status === 200
        console.log("Form data sent successfully");
        setIsSubmitted(true);
        setErrorMessage("");
        resetForm(); //Reset form after successful submission
        // setIsModalOpen(false); // Close the modal

        setCountdown(3);

        const countdownInterval = setInterval(() => {
          setCountdown((prevCount) => {
            if (prevCount <= 1) {
              clearInterval(countdownInterval);
              setIsModalOpen(false);
              return null; // This will hide the countdown message
            } else {
              return prevCount - 1;
            }
          });
        }, 1000);
      } else {
        console.error("Failed to send form data");
        setErrorMessage("Failed to send form data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage(`An error occurred: ${error.message}`);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData((prevState) => ({
      ...prevState,
      phone: "",
      message: "",
    }));
  };

  // This function closes the modal when the overlay is clicked
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto"
          onClick={handleOverlayClick}
          style={{ paddingTop: "1rem" }} // You can adjust this value as per your design needs
        >
          <div className="relative bg-white rounded-lg w-11/12 md:w-2/3 lg:w-1/2 p-6 overflow-y-auto">
            <div className="relative isolate bg-white px-6 lg:px-8 text-lef overflow-y-auto">
              {/* Cool grid background */}
              <svg
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true">
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="50%"
                    y={-64}
                    patternUnits="userSpaceOnUse">
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
                  <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
                    strokeWidth={0}
                  />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                />
              </svg>

              {/* Content and form */}
              {/* <div className="flex overflow-y-auto"> */}
              <div className="overflow-y-auto mx-auto max-w-xl lg:max-w-4xl text-left">
                {/* Modal header and text */}
                <div className="flex justify-between">
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                    Let’s connect you with your deal
                  </h2>
                  {/* Add a close button at the top-right corner of the modal */}
                  <button onClick={onClose} className="text-2xl font-bold">
                    &times;
                  </button>
                </div>

                {/* Information text */}
                <br />
                <div className="mt-2 pb-6 text-lg font-semibold leading-8 text-gray-700">
                  <p>
                    You've selected a {nights} night cruise on {cruiseShip},
                    departing on{" "}
                    {new Date(sailDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                    .
                  </p>

                  <br />
                  {currentPriceAdv === "SOLD OUT" ? (
                    <p>
                      <br />
                      This cruise is no longer available on the company website.
                      However, we still offer it at <b>${groupRate}</b> per
                      person.
                      {/* <br /> */}
                    </p>
                  ) : (
                    <>
                      {/* <br /> */}
                      <p>
                        With us, you'll save approximately{" "}
                        <b>
                          ${currentPriceAdv} ({currentPercentAdv}%)
                        </b>
                        , for a cost per person of approximately{" "}
                        <b>${groupRate}</b>.
                      </p>
                      <br />
                      <p>
                        Fill in this form to get in contact with our sales team.
                      </p>
                    </>
                  )}
                </div>
                <br />
                {/* Form and form text */}
                <div className=" flex flex-col items-start gap-16 sm:gap-y-10 lg:flex-row">
                  <form action="#" method="POST" className="lg:flex-auto">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                      {/* FName */}
                      <div>
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-semibold leading-6 text-gray-900">
                          First Name
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="firstName"
                            id="first-name"
                            value={formData.firstName}
                            readOnly={true}
                            onChange={handleInputChange}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      {/* LName */}
                      <div>
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-semibold leading-6 text-gray-900">
                          Last Name
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="lastName"
                            id="last-name"
                            value={formData.lastName}
                            readOnly={true}
                            onChange={handleInputChange}
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      {/* Email */}
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-semibold leading-6 text-gray-900">
                          Email
                        </label>
                        <div className="mt-2.5">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            readOnly={true}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      {/* Phone */}
                      <div>
                        <label
                          htmlFor="phone"
                          className="block text-sm font-semibold leading-6 text-gray-900">
                          Phone Number
                        </label>
                        <div className="mt-2.5">
                          <input
                            id="phone"
                            name="phone"
                            type="tel" // Use type="tel" for phone numbers
                            value={formData.phone}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      {/* Message */}
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="message"
                          className="block text-sm font-semibold leading-6 text-gray-900">
                          Message
                        </label>
                        <div className="mt-2.5">
                          <textarea
                            id="message"
                            name="message"
                            rows={4}
                            value={formData.message}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            // defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Display success or error message */}
                    {isSubmitted && (
                      <p className="mt-4 text-sm text-green-500">
                        Form submitted successfully!
                      </p>
                    )}
                    {errorMessage && (
                      <p className="mt-4 text-sm text-red-500">
                        {errorMessage}
                      </p>
                    )}
                    <div>
                      {countdown && (
                        <p className="mt-4 text-sm text-green-500">
                          Closing window in {countdown}...
                        </p>
                      )}
                    </div>
                    <div className="mt-10">
                      <button
                        type="button"
                        className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleFormSubmit}>
                        Let's talk{" "}
                      </button>
                      <p className="mt-4 text-sm leading-6 text-gray-500">
                        By submitting this form, I agree to the{" "}
                        <a
                          href="https://www.hiddencruisefares.com/privacy_policy"
                          target="_blank" // This attribute opens the link in a new window or tab
                          rel="noopener noreferrer" // Recommended for security when using target="_blank"
                          className="font-semibold text-indigo-600">
                          privacy&nbsp;policy
                        </a>
                        .
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsModal;
