import React from "react";

export const NavLinks = [
  {
    name: "Destination",
    url: "/",
  },
  {
    name: "Hotels",
    url: "/",
  },
  {
    name: "Flights",
    url: "/",
  },
  {
    name: "Bookings",
    url: "/",
  },
  {
    name: "Destination",
    url: "/",
  },
  {
    name: "Hotels",
    url: "/",
  },
  {
    name: "Flights",
    url: "/",
  },
  {
    name: "Bookings",
    url: "/",
  },
];

export const NavButtons = [
  {
    name: "Login",
    url: "login/",
  },
  {
    name: "Signup",
    url: "/register",
  },
];

export const HeroTexts = {
  firstText: "FIND HIDDEN CRUISE FARES",
  secondText: "Sail Beyond Your Expectations with Exclusive Cruise Deals!",
  thirdText: "HiddenCruiseFares.com was built by travel agents, for travel agents.",
  firstButton: "Sign Up Now For Free",
  secondButton: "Play Demo",
};

// Your ticket to exclusive savings
export const BookingStepsTexts = {
  firstText: "Easy and Fast",
  secondText: "Your Ticket to Exclusive Savings!",
  thirdText: "Follow our 3-step process to unlock the full potential",
  fourthText: "Don't miss out on our exclusive offers - start saving today",
  listOfSteps: [
    {
      text: "Join the HiddenCruiseFares.com community today by signing up for free. Get exclusive access to our handpicked selection of hidden cruise fares and embark on unforgettable voyages at unbeatable prices.",
    },
    {
      text: "Once you've signed up, discover our Top 10 cruise deals curated just for you. These deals are handpicked gems that offer incredible value and remarkable experiences. Explore, compare, and choose the perfect cruise to suit your desires.",
    },
    {
      text: "Ready to unlock the full potential of HiddenCruiseFares? Utilize our advanced filtering and sorting options to access over 26,000 hidden cruise fares. View all details about ships and cruises, and use our Inquire feature to get in touch with us.",
    },
  ],
  cardOne: {
    name: "Cruise To Norway",
    date: "July 5th, 2024  |  on Jewel Of The Seas",
    people: "10 Cabins Left at 72% Off",
  },
  cardTwo: {
    status: "Ongoing",
    destination: "Trip to Rome",
    completion: "40% Completed",
  },
};

// Why choose HiddenCruiseFares.com?
export const ServiceTexts = {
  firstText: "Why Choose HiddenCruiseFares?",
  secondText:
    "Our mission is simple - to uncover the best hidden cruise fares for you. Imagine setting sail on your dream cruise, all while enjoying massive savings on your voyage. Only our experienced team at HiddenCruiseFares.com can give this to you.",
  cards: [
    {
      firstText: "Exclusive Access",
      secondText:
        "Gain entry to a treasure trove of hidden cruise fares not found elsewhere",
    },
    {
      firstText: "Unbeatable Savings",
      secondText:
        "Save up to 76% on luxury cruises, as well as free value added perks such as Onboard Credit",
    },
    {
      firstText: "Transparent Pricing",
      secondText:
        "No hidden fees or surprises - we believe in upfront and honest pricing",
    },
    {
      firstText: "Expert Support",
      secondText:
        "Our partnerships with dedicated travel agents ensure someone is there to assist you every step of the way",
    },
  ],
};

// Hot deals
export const TopDestinationTexts = {
  firstText: "Top selling",
  secondText: "Hot deals",
  thirdText: "Take a sneak peek at some deals",
  cards: [
    {
      ship: "Harmony Of The Seas",
      price: "76% off",
      duration: "7 Night Caribbean",
    },
    {
      ship: "Voyageur of the Seas",
      price: "84% off",
      duration: "4 Night Short Caribbean",
    },
    {
      ship: "Icon Of The Seas",
      price: "71% off",
      duration: "7 Night Caribbean",
    },
    {
      ship: "Odyssy Of The Seas",
      price: "70% off",
      duration: "12 Night Caribbean",
    },
    {
      ship: "Adventure Of The Seas",
      price: "68% off",
      duration: "8 Night Caribbean",
    },
    {
      ship: "Jewel Of The Seas",
      price: "68% off",
      duration: "12 Night Europe",
    },
  ],
};

export const TestimonialTexts = {
  firstText: "TESTIMONIALS",
  secondText: "What people say about Us.",
  feedBacks: [
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike Taylor",
      location: "Lahore, Pakistan",
    },
    {
      text: "Jadoo was great with the entire process from planning to updates during the trip. We had 11 people and everything was perfectly executed. We appreciate all of her hard work. It was truly the trip of a lifetime. Thank you!",
      person: "Gina",
      location: "Owerri, Nigeria",
    },
    {
      text: "Booking through you was very easy and made our lives so much easier. I have nothing bad to say! Thank you for giving us tips and guidance before we left on what to bring and such, that was very helpful!",
      person: "Emma",
      location: "Vancouver, Canada",
    },
    {
      text: "Thank you for your recommendation and putting the trip together. The resort was absolutely beautiful. The infinity pools, the palm trees in the main pool, the infinity pool in our preferred area overlooking the gulf and the golf course were exceptional....",
      person: "Loveth",
      location: "Lagos, Nigeria",
    },
  ],
};

export const NewsletterTexts = {
  firstText:
    "Subscribe to get information, latest news and other interesting offers about Jadoo.",
  placeholderText: "Your email",
  buttonText: "Subscribe",
};

export const FooterTexts = {
  underLogoText: "Book your trip in minutes, get full control for much longer.",
  quickLinks: {
    caption: "Quick Links",
    links: [
      {
        name: "Destination",
        url: "/",
      },
      {
        name: "Hotels",
        url: "/",
      },
      {
        name: "Flights",
        url: "/",
      },
      {
        name: "Bookings",
        url: "/",
      },
      {
        name: "Login",
        url: "/login",
      },
      {
        name: "Signup",
        url: "/register",
      },
    ],
  },
  contacts: {
    caption: "Contacts",
    links: [
      {
        name: "FAQs",
        url: "/",
      },
      {
        name: "Help",
        url: "/",
      },
      {
        name: "Policies",
        url: "/",
      },
      {
        name: "Terms & Conditions",
        url: "/",
      },
    ],
  },
  more: {
    caption: "More",
    links: [
      {
        name: "Career",
        url: "/",
      },
      {
        name: "Airlines",
        url: "/",
      },
      {
        name: "Airline Fees",
        url: "/",
      },
      {
        name: "Low Fare Tips",
        url: "/",
      },
    ],
  },
};

// Above the fold
export const AgentHeroText = {
  firstText: "HiddenCruiseFares.com",
  secondText: "Take the helm of your cruise bookings with HiddenCruiseFares.com.",
  thirdText:
    "Designed for travel agents, by travel agents, our platform is your first-class ticket to simplifying cruise searches, optimizing bookings, and delivering unparalleled experiences to your clients.",
  fourthText:
    "Elevate your service and stay ahead of the tide with HiddenCruiseFares.com.",
};

// Dive deeper into cruise mkt
export const AgentPlatformOverview = {
  firstText: "Dive deeper into the cruise market",
  secondText: "HiddenCruiseFares.com was built by travel agents, for travel agents.",
  thirdText:
    "With our software, you can offer clients unmatched value with the ultimate tool for uncovering hidden cruise deals.",
  fourthText:
    "Lead your clients to the best deals and solidify your position as their trusted travel advisor.",
};

export const AgentPricingTexts = {
  firstText: "Pricing",
  secondText: "The right price for you, whatever your needs",
  thirdText:
    "We offer two classes that meet all your requirements as a travel advisor.",
  fourthText: "You may change tiers at any time",
  tiers: [
    {
      title: "Business Class",
      price: "$19.95",
      description:
        "Our general membership, which gives you access to our innovative software",
      perks: [
        "Full unrestricted access to our platform",
        "Unique agent database with general analytics, week-at-a-glance, client overview, deals overview",
        "Upload custom cruise deals and utilize advanced filters",
        "Respond to unlimited inquires, via email or website",
        "Customize with personal company theme",
        "Customer support",
      ],
      button: "Get started today",
    },
    {
      title: "First Class",
      price: "$29.95",
      description:
        "First Class aims to promote sales and increase revenue by leveraging AI and machine learning.",
      perks: [
        <b>All the benfits of Business class, plus:</b>,
        "Automated marketing emails - customizable to your needs, utilizing our campaign builder",
        "Advanced in-depth analytics - view activity, revenue, invoices, and much more",
        "Automated quote generation - using our proprietary system, generate a downloadable PDF document with all inquiry details for your client",
        "Priority customer support - your queries will be answered first",
      ],
      button: "Upgrade me!",
    },
  ],
};

export const AgentFeatureList = {
  firstText: "Everything you need",
  secondText: "All-in-one platform",
  thirdText:
    "HiddenCruiseFares.com is your ticket to a competitive edge in the cruise booking industry.",
  fourthText:
    "Our platform offers swift bookings, comprehensive insights, and personalized branding—all within a user-friendly interface. Navigate options effortlessly, close deals faster, and stand out in the market.",
  fifthText:
    "With dedicated support always on standby, HiddenCruiseFares.com is your streamlined solution for success.",
  features: [
    {
      name: "Data-Driven Insights",
      description:
        "Elevate your strategy with access to comprehensive sales data and revenue insights. Monitor your performance, view client inquiries, and idenfity opportunities for growth - all in one place.",
    },
    {
      name: "Efficient Booking Tools",
      description:
        "Navigate the rough seas of bookings with ease using our streamlined tools. Our efficient system helps you close deals faster, allowing you more time to personalize experiences for your clients.",
    },
    {
      name: "Unified Dashboard",
      description:
        "Effortlessly navigate through a variety of cruise options with our consolidated view. It's your command center for all necessary information, simplifying the search for the perfect cruise for your clients.",
    },
    {
      name: "Company Personalization",
      description:
        "Stand out in the market by applying your company themes to our platform, helping clients feel at home with the familiarity and comfort of your brand.",
    },
    {
      name: "Smooth Sailing Interface",
      description:
        "The platform is designed for ease of use, ensuring that you can quickly identify and select the best option without getting lost in the details.",
    },

    {
      name: "Dedicated Agent Support",
      description:
        "You're never alone on this journey. Our dedicated agent support ensures that you have assistance whenever you need, helping you make the most out of our platform.",
    },
  ],
};

export const AgentGetStarted = {
  firstText: "Join the HiddenCruiseFares.com community today",
  secondText:
    "Don't just stay afloat in the competitive seas of the travel industry; set sail with confidence and steer your business towards new horizons.",
  thirdText:
    "Ready to transform your approach? Join now and chart your course to success.",
};
