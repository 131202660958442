import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CruiseLineDropdown({
  uniqueCruiseLines,
  onCruiseLineChange,
  subscriptionActive,
  setCurrentPage,
  selectedCruiseLine, // <-- Added
  setSelectedCruiseLine, // <-- Added
}) {
  const [query, setQuery] = useState("");

  const filteredCruiseLines =
    query === ""
      ? uniqueCruiseLines
      : uniqueCruiseLines.filter((cruiseLine) => {
          return cruiseLine.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className="relative">
      {!subscriptionActive && (
        <div
          className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 75,
            backgroundColor: "rgba(243, 244, 246, 1)", // This gives a slight whitewash look. Remove if you don't want it.
            zIndex: 1,
          }}></div>
      )}
      <Combobox
        as="div"
        value={selectedCruiseLine || "All"}
        onChange={(selectedValue) => {
          setSelectedCruiseLine(selectedValue); // <-- Use prop function here
          setCurrentPage(1);
          onCruiseLineChange(selectedValue);
        }}>
        <Combobox.Label className="block text-base font-semibold leading-6 text-gray-900">
          Cruise Line
        </Combobox.Label>
        <div className="relative mt-2.5">
          <Combobox.Input
            disabled={!subscriptionActive}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(cruiseLine) => cruiseLine}
            readOnly={true}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredCruiseLines.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredCruiseLines.map((cruiseLine, index) => (
                <Combobox.Option
                  key={index}
                  value={cruiseLine}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }>
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}>
                        {cruiseLine}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
}

export default CruiseLineDropdown;
