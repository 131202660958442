import React from "react";
import Image from "./Image";
import Text from "./Text";
import { ServiceTexts } from "./DataLists";
import { useCallback } from "react";
import Icon1 from "../../assets/icon1.png";
import Icon2 from "../../assets/icon2.png";
import Icon3 from "../../assets/icon3.png";
import Icon4 from "../../assets/icon4.png";
import { Fade } from "react-awesome-reveal";
import Card from "./Card";
import SignUpButton from "./SignUpButton";

const Services = () => {
  const renderServiceIcon = useCallback((element) => {
    switch (element) {
      case 0:
        return Icon1;
      case 1:
        return Icon2;
      case 2:
        return Icon3;
      case 3:
        return Icon4;
      default:
        return "";
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h1 className="text-3xl font-bold tracking-tight text-color3 sm:text-4xl">
              {ServiceTexts.firstText}
            </h1>
            <br />
            <p className="lg:text-2xl md:text-xl text-xl text-color1 font-medium">
              {ServiceTexts.secondText}
            </p>
          </div>
          <div className="w-full h-auto grid lg:grid-cols-4 md:grid-cols-2 lg:gap-7 md:gap-10 gap-7 my-12 z-20 px-8 md:px-0">
            {ServiceTexts.cards.map((card, index) => (
              <Card
                cardClass="w-full bg-white flex flex-col items-center justify-center py-6 cursor-pointer transition duration-300 hover:shadow-xl px-5 rounded-xl cardPseudo after:bg-color1"
                imageWrapperClass="w-28 h-28 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl"
                cover="object-cover"
                imageAlt={card.firstText}
                imageSrc={renderServiceIcon(index)}
                textWrapperClass="w-full flex flex-col items-center gap-2"
                key={index}>
                <Text
                  as="h4"
                  className="text-xl font-semibold rounded text-color3 text-center" // Add 'text-center' class here
                >
                  {card.firstText}
                </Text>
                <Text
                  as="p"
                  className="font-medium text-center text-color3" // Add 'text-center' class here
                >
                  {card.secondText}
                </Text>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center">
        <SignUpButton props={"Get me started today"} />
      </div>
    </div>
  );
};

export default Services;
