import React from "react";

export const NavLinks = [
  {
    name: "Destination",
    url: "/",
  },
  {
    name: "Hotels",
    url: "/",
  },
  {
    name: "Flights",
    url: "/",
  },
  {
    name: "Bookings",
    url: "/",
  },
  {
    name: "Destination",
    url: "/",
  },
  {
    name: "Hotels",
    url: "/",
  },
  {
    name: "Flights",
    url: "/",
  },
  {
    name: "Bookings",
    url: "/",
  },
];

export const NavButtons = [
  {
    name: "Login",
    url: "login/",
  },
  {
    name: "Signup",
    url: "/register",
  },
];

export const HeroTexts = {
  firstText: "FIND HIDDEN CRUISE FARES",
  secondText: "Sail Beyond Your Expectations with Exclusive Cruise Deals!",
  thirdText:
    "Built Wicket longer admire do barton vanity itself do in it. Preferred to sportsmen it engrossed listening. Park gate sell they west hard for the.",
  firstButton: "Sign Up Now For Free",
  secondButton: "Play Demo",
};

// Your ticket to exclusive savings
export const BookingStepsTexts = {
  firstText: "Easy and Fast",
  secondText: "Your Ticket to Exclusive Savings!",
  thirdText: "Follow our 3-step process to unlock the full potential",
  fourthText: "Don't miss out on our exclusive offers - start saving today",
  listOfSteps: [
    {
      text: "Join the HiddenCruiseFares.com community today by signing up for free. Get exclusive access to our handpicked selection of hidden cruise fares and embark on unforgettable voyages at unbeatable prices.",
    },
    {
      text: "Once you've signed up, discover our Top 10 cruise deals curated just for you. These deals are handpicked gems that offer incredible value and remarkable experiences. Explore, compare, and choose the perfect cruise to suit your desires.",
    },
    {
      text: "Ready to unlock the full potential of HiddenCruiseFares? Utilize our advanced filtering and sorting options to access over 26,000 hidden cruise fares. View all details about ships and cruises, and use our Inquire feature to get in touch with us.",
    },
  ],
  cardOne: {
    name: "Cruise To Norway",
    date: "July 5th, 2024  |  on Jewel Of The Seas",
    people: "10 Cabins Left at 72% Off",
  },
  cardTwo: {
    status: "Ongoing",
    destination: "Trip to Rome",
    completion: "40% Completed",
  },
};

// Why choose HiddenCruiseFares.com?
export const ServiceTexts = {
  firstText: "Why Choose HiddenCruiseFares?",
  secondText:
    "Our mission is simple - to uncover the best hidden cruise fares for you. Imagine setting sail on your dream cruise, all while enjoying massive savings on your voyage. Only our experienced team at HiddenCruiseFares.com can give this to you.",
  cards: [
    {
      firstText: "Exclusive Access",
      secondText:
        "Gain entry to a treasure trove of hidden cruise fares not found elsewhere",
    },
    {
      firstText: "Unbeatable Savings",
      secondText:
        "Save up to 76% on luxury cruises, as well as free value added perks such as Onboard Credit",
    },
    {
      firstText: "Transparent Pricing",
      secondText:
        "No hidden fees or surprises - we believe in upfront and honest pricing",
    },
    {
      firstText: "Expert Support",
      secondText:
        "Our partnerships with dedicated travel agents ensure someone is there to assist you every step of the way",
    },
  ],
};

// Hot deals
export const TopDestinationTexts = {
  firstText: "Top selling",
  secondText: "Hot deals",
  thirdText: "Take a sneak peek at some deals",
  cards: [
    {
      ship: "Harmony Of The Seas",
      price: "76% off",
      duration: "7 Night Caribbean",
    },
    {
      ship: "Voyageur of the Seas",
      price: "84% off",
      duration: "4 Night Short Caribbean",
    },
    {
      ship: "Icon Of The Seas",
      price: "71% off",
      duration: "7 Night Caribbean",
    },
    {
      ship: "Odyssy Of The Seas",
      price: "70% off",
      duration: "12 Night Caribbean",
    },
    {
      ship: "Adventure Of The Seas",
      price: "68% off",
      duration: "8 Night Caribbean",
    },
    {
      ship: "Jewel Of The Seas",
      price: "68% off",
      duration: "12 Night Europe",
    },
  ],
};

export const TestimonialTexts = {
  firstText: "TESTIMONIALS",
  secondText: "What people say about Us.",
  feedBacks: [
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike Taylor",
      location: "Lahore, Pakistan",
    },
    {
      text: "Jadoo was great with the entire process from planning to updates during the trip. We had 11 people and everything was perfectly executed. We appreciate all of her hard work. It was truly the trip of a lifetime. Thank you!",
      person: "Gina",
      location: "Owerri, Nigeria",
    },
    {
      text: "Booking through you was very easy and made our lives so much easier. I have nothing bad to say! Thank you for giving us tips and guidance before we left on what to bring and such, that was very helpful!",
      person: "Emma",
      location: "Vancouver, Canada",
    },
    {
      text: "Thank you for your recommendation and putting the trip together. The resort was absolutely beautiful. The infinity pools, the palm trees in the main pool, the infinity pool in our preferred area overlooking the gulf and the golf course were exceptional....",
      person: "Loveth",
      location: "Lagos, Nigeria",
    },
  ],
};

export const NewsletterTexts = {
  firstText:
    "Subscribe to get information, latest news and other interesting offers about Jadoo.",
  placeholderText: "Your email",
  buttonText: "Subscribe",
};

export const FooterTexts = {
  underLogoText: "Book your trip in minutes, get full control for much longer.",
  quickLinks: {
    caption: "Quick Links",
    links: [
      {
        name: "Destination",
        url: "/",
      },
      {
        name: "Hotels",
        url: "/",
      },
      {
        name: "Flights",
        url: "/",
      },
      {
        name: "Bookings",
        url: "/",
      },
      {
        name: "Login",
        url: "/login",
      },
      {
        name: "Signup",
        url: "/register",
      },
    ],
  },
  contacts: {
    caption: "Contacts",
    links: [
      {
        name: "FAQs",
        url: "/",
      },
      {
        name: "Help",
        url: "/",
      },
      {
        name: "Policies",
        url: "/",
      },
      {
        name: "Terms & Conditions",
        url: "/",
      },
    ],
  },
  more: {
    caption: "More",
    links: [
      {
        name: "Career",
        url: "/",
      },
      {
        name: "Airlines",
        url: "/",
      },
      {
        name: "Airline Fees",
        url: "/",
      },
      {
        name: "Low Fare Tips",
        url: "/",
      },
    ],
  },
};
