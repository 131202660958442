import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const registerUser = createAsyncThunk("user/register", async (formData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      // Add other headers as needed
    },
  };

  const response = await axios.post(
    `https://${apiBaseUrl}/register`, 
    formData,
    config
  );
  return response.data; // Return the response data on successful registration
});

export { registerUser };
