// this is a combination of usersThunk and editActiveUsersThunk

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

export const fetchUsersList = createAsyncThunk(
  "users/fetchUsersList", // <-- Adjust the action name here for clarity
  async (_, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user; // Update this according to your user info structure

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };
    const response = await fetch(
      `https://${apiBaseUrl}/users`,
      config
    );
    const data = await response.json();
    // console.log("Logging token:",token);
    // console.log("Logging ID:",_id);

    if (!response.ok) {
      throw new Error(data.message || "Could not fetch users"); // Adjusted the error message for clarity
    }

    return data;
  }
);

export const editActiveUser = createAsyncThunk(
  "user/edit",
  async ({ userId, userData, getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user; // Update this according to your user info structure

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };
    const response = await axios.put(
      `https://${apiBaseUrl}/users/${userId}`,
      userData,
      config
    );

    if (!response.data) {
      throw new Error("Failed to edit user data");
    }

    return response.data;
  }
);

// In usersThunk.js
export const updateUserThunk = createAsyncThunk(
  "users/updateUser",
  async (userUpdateData, { getState }) => {
    // Note the second argument here, destructuring the thunkAPI
    const { userId, updatedUser } = userUpdateData;
    const state = getState();
    const { token, _id } = state.activeUser.data.user;

    try {
      const response = await axios.put(
        `https://${apiBaseUrl}/users/${userId}`,
        updatedUser,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-token": token,
            "x-user-id": _id,
            // Add other headers as needed
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error("Network response was not ok");
    }
  }
);
