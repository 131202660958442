import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
import BookingSteps from "../components/landingPage/BookingSteps";
import HeroSection from "../components/landingPage/HeroSection";
import NewsLetter from "../components/landingPage/NewsLetter";
import Partners from "../components/landingPage/Partners";
import Services from "../components/landingPage/Services";
import Testimonials from "../components/landingPage/Testimonials";
import TopDestination from "../components/landingPage/TopDestination";
import Footer from "../components/landingPage/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CCNavBar from "../components/landingPage/CCNavBar";
import "./LandingPage.css";

// import BookingSteps from "../components/agent-landingPage/BookingSteps";

function LandingPage() {
  const location = useLocation();
  console.log(process.env.NODE_ENV);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referrer = urlParams.get("referrer");

    if (referrer) {
      Cookies.set("referrer", referrer, { expires: 180 }); // 180 days expiration
    }
  }, [location]);

  return (
    <>
      <CCNavBar />
      <HeroSection />
      <BookingSteps />
      <Services />
      <TopDestination />
      {/* <Testimonials /> */}
      <Footer />
    </>
  );
}

export default LandingPage;
