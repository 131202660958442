import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Update the thunk to accept an object with shipName and operator as properties
const fetchShipDetails = createAsyncThunk(
  "ship/fetch",
  async ({ shipName, operator }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const appId = "92db4501d45ba484ea7f70b5cfb3db238e1b9f6e";
    const appToken =
      "7974dbd6e613308e77530f989f593b96a393c3793687dd8d6bab7b1220133a7e";

    let currentPage = 1;
    try {
      while (true) {
        // Interpolate the operator parameter into the URL
        const url = `https://www.widgety.co.uk/api/ships.json?operator=${operator}&include_cruises=true&page=${currentPage}&app_id=${appId}&token=${appToken}`;
        const response = await axios.get(url, config);

        console.log("current page", currentPage);

        if (!response.data.ships || response.data.ships.length === 0) {
          throw new Error(
            `No details available for ${shipName} in ${operator}. In thunk`
          );
        }

        const ship = response.data.ships.find(
          (ship) => ship.title === shipName
        );
        if (ship) {
          console.log("found", shipName, "on page", currentPage);
          return { ...ship };
        }
        currentPage++;
      }
    } catch (error) {
      console.error("There was an error fetching the ship details", error);
      throw error;
    }
  }
);

export { fetchShipDetails };
