import React, { useState, useEffect, Fragment } from "react";
import { Tab, Disclosure } from "@headlessui/react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchCruiseDetails } from "../store/thunks/widgety/fetchCruiseDetails";
import { fetchShipDetails } from "../store/thunks/widgety/fetchShipDetails";
import Logo from "../components/Logo";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CruiseImageGallery from "../components/cruiseDetail/CruiseImageGallery";
import PricingTab from "../components/cruiseDetail/PricingTab";
import { TailSpin } from "react-loader-spinner";
import ShipDetailsSection from "../components/cruiseDetail/ShipDetailsSection";
import { Plus, Minus } from "heroicons-react";
import NestedTabMenu from "../components/cruiseDetail/NestedTabMenu";
import NotFoundPage from "./NotFoundPage";
import ContactUsTab from "../components/cruiseDetail/ContactUsTab";
import shipList from "../components/cruiseList/shipList";
import ContactUsModal from "../components/cruiseDetail/ContactUsModal";
import SignUpButton from "../components/landingPage/SignUpButton";

const tabs = [
    {
        name: "Overview",
    },
    {
        name: "Ship",
    },
    {
        name: "Itinerary",
    },
    {
        name: "Gallery",
    },
    {
        name: "Pricing",
    },
    {
        name: "Contact Us",
    },
    // {
    //   name: "Map",
    // },
];

function formatCruiseShipName(cruiseShip) {
    // Replace hyphens with spaces
    return cruiseShip.replace(/-/g, " ");
}

function CruiseDetailPage() {
    // const { shipName, sailDate } = useParams();
    const { cruiseLine, cruiseShip, cruiseDate } = useParams();
    const dispatch = useDispatch();
    const [cruiseData, setCruiseData] = useState(null);
    const [shipData, setShipData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
    const MAX_SUMMARY_LENGTH = 300;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Create a mapping of operator titles to API-friendly names
    const operatorMapping = {
        "Royal Caribbean International": "royal-caribbean-international",
        "Celebrity Cruises": "celebrity-cruises",
        "Virgin Voyages": "virgin-voyages",
        // Add more mappings as needed
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    // Get the current location, including the search/query parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Retrieve the values
    const shipName = cruiseShip
        ? formatCruiseShipName(cruiseShip)
        : queryParams.get("shipName");
    const sailDate = cruiseDate || queryParams.get("sailDate");
    const cruiseId = queryParams.get("cruiseId");

    // Define variables to track time elapsed
    const [elapsedTime, setElapsedTime] = useState(0);
    const [loadingText, setLoadingText] = useState(
        "Fetching cruise details..."
    );

    useEffect(() => {
        const timer = setInterval(() => {
            setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (elapsedTime >= 5 && elapsedTime < 10) {
                setLoadingText("Almost there...");
            } else if (elapsedTime >= 10 && elapsedTime < 20) {
                setLoadingText("Just a little bit longer...");
            } else if (elapsedTime >= 20 && elapsedTime < 30) {
                setLoadingText("We're working on it...");
            } else if (elapsedTime >= 30) {
                setLoadingText("Working hard to fetch your data...");
            }
        }, 10000); // 10000 milliseconds = 10 seconds

        return () => clearInterval(intervalId);
    }, [elapsedTime]);

    useEffect(() => {
        const fetchCruiseDetailsData = async () => {
            setIsLoading(true);
            try {
                const sailDateToISO = new Date(sailDate);
                sailDateToISO.setDate(sailDateToISO.getDate() + 1);
                const endDate = sailDateToISO.toISOString().split("T")[0];

                // Add console.log statements to debug
                console.log("Searching for ", shipName, " on ", sailDate);

                const response = await dispatch(
                    fetchCruiseDetails({
                        start_date_range_beginning: sailDate,
                        start_date_range_end: endDate,
                        ship_name: shipName,
                    })
                );

                // Add console.log statement to inspect response
                // console.log("Response:", response);

                const data = response.payload;

                // Add console.log statement to inspect data
                console.log("Cruise Data:", data);

                setCruiseData(data);

                // Now fetch ship details
                const operatorTitle = data.cruises[0].operator_title;
                const shipTitle = data.cruises[0].ship_title;
                const apiOperatorName = operatorMapping[operatorTitle];

                if (!apiOperatorName) {
                    console.error(
                        `Operator name not found in mapping: ${operatorTitle}`
                    );
                    return;
                }

                // Call fetchShipDetails with the correct operator and ship names
                const shipDetailsResponse = await dispatch(
                    fetchShipDetails({
                        shipName: shipTitle,
                        operator: apiOperatorName,
                    })
                );

                const shipDetailsData = shipDetailsResponse.payload;
                // Add console.log statement to inspect ship details data
                console.log("Ship Details Data:", shipDetailsData);

                setShipData(shipDetailsData);
            } catch (error) {
                setError(error);
                console.error("Error fetching cruise details", error);
            } finally {
                setIsLoading(false);
                console.log(
                    "SEARCH COMPLETE! Cruise and Ship information fetched."
                );
            }
        };

        fetchCruiseDetailsData();
    }, [dispatch, shipName, sailDate]);

    // Function to update isMobile based on window width
    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    // Add an event listener to update isMobile when the window is resized
    useEffect(() => {
        window.addEventListener("resize", updateIsMobile);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener("resize", updateIsMobile);
        };
    }, []);

    const getCompressedImage1000x = (shipName) => {
        const ship = shipList.find((s) => s.ship === shipName);
        return ship ? ship.compressedImageURI1000x : null;
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                }}
            >
                <TailSpin color="#4535be" height={35} width={35} />
                <p className="mt-2 text-gray-600">{loadingText}</p>
            </div>
        );
    }

    if (!cruiseData) {
        return <div>No cruise details available.</div>;
    }

    if (error) {
        return <NotFoundPage cruiseUnavailable={true} />;
    }

    //  This function formats non-ISO date/times
    function formatDateAndTime(dateStr) {
        const date = new Date(dateStr);
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        };

        let formattedDateAndTime = date.toLocaleString("en-US", options);

        return formattedDateAndTime;
    }

    // This function formats ISO date/times
    function formatDate(dateStr) {
        const date = new Date(dateStr);
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };

        let formattedDateAndTime = date.toLocaleString("en-US", options);

        return formattedDateAndTime;
    }

    // THis is used just for the VV data where the time is like so: Depart 1900
    function formatTime(timeString) {
        const time = timeString.match(/(\d{2})(\d{2})/); // Match four consecutive digits
        if (time) {
            const hours = parseInt(time[1]);
            const minutes = parseInt(time[2]);
            const period = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours > 12 ? hours - 12 : hours;
            const formattedMinutes = minutes.toString().padStart(2, "0");
            return `${formattedHours}:${formattedMinutes} ${period}`;
        } else {
            return "Invalid Time";
        }
    }

    // This function truncates summaries for easier reading
    function truncateSummary(summary) {
        if (summary.length > MAX_SUMMARY_LENGTH) {
            // Find the nearest sentence boundary (period + space)
            const truncatedSummary = summary.substring(0, MAX_SUMMARY_LENGTH);
            const lastPeriodIndex = truncatedSummary.lastIndexOf(". ");

            if (lastPeriodIndex !== -1) {
                return truncatedSummary.substring(0, lastPeriodIndex + 1);
            }
        }
        return summary;
    }

    return (
        <div className="bg-white">
            {/* Header logo */}
            <Disclosure as="nav" className="border-b border-gray-200 bg-white">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 justify-between">
                        <div className="flex">
                            <div className="flex flex-shrink-0 items-center">
                                <Logo height="auto" width="8rem" />
                            </div>
                        </div>
                        <div className="flex items-center">
                            {/* Add the SignUpButton component here */}
                            <SignUpButton props={"Sign Up"} />
                        </div>
                    </div>
                </div>
            </Disclosure>
            {/* Entire body */}
            <section
                aria-labelledby="features-heading"
                className="mx-auto max-w-7xl py-10 sm:px-2 lg:px-8"
            >
                <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                    <div className="max-w-3xl">
                        <h2
                            id="features-heading"
                            className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                        >
                            {cruiseData.cruises[0].name}
                        </h2>
                        <p className="mt-4 text-xl text-gray-500">
                            <i>
                                {cruiseData.cruises[0].ship_title} with{" "}
                                {cruiseData.cruises[0].operator_title}
                            </i>
                        </p>
                    </div>

                    <Tab.Group as="div" className="mt-4">
                        <div className="-mx-4 flex overflow-x-auto sm:mx-0">
                            <div className="flex-auto border-b border-gray-200 px-4 sm:px-0">
                                <Tab.List className="-mb-px flex space-x-10">
                                    {tabs.map((tab) => (
                                        <Tab
                                            key={tab.name}
                                            className={({ selected }) =>
                                                classNames(
                                                    selected
                                                        ? "border-indigo-500 text-indigo-600"
                                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                    "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                                                )
                                            }
                                        >
                                            {tab.name}
                                        </Tab>
                                    ))}
                                </Tab.List>
                            </div>
                        </div>

                        <Tab.Panels as={Fragment}>
                            {tabs.map((tab) => (
                                <Tab.Panel
                                    key={tab.name}
                                    className="space-y-16 pt-10 lg:pt-16 overflow-x-auto"
                                >
                                    {/* Info for Overview tab */}
                                    {tab.name === "Overview" && (
                                        <div>
                                            <h3 className="text-lg font-medium text-gray-900">
                                                Overview
                                            </h3>

                                            <div className="mt-4 grid gap-4 md:grid-cols-2">
                                                <div className="border-t border-gray-200 pt-4">
                                                    <h4 className="text-md font-medium text-gray-700">
                                                        Starts (Local)
                                                    </h4>
                                                    <p className="mt-2 text-gray-500">
                                                        {
                                                            cruiseData
                                                                .cruises[0]
                                                                .starts_at
                                                        }
                                                    </p>
                                                    <p className="text-gray-500">
                                                        {formatDateAndTime(
                                                            cruiseData
                                                                .portVisits[0]
                                                                .departs_on
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="border-t border-gray-200 pt-4 md:border-t border-gray-200 md:mt-0">
                                                    <h4 className="text-md font-medium text-gray-700">
                                                        Ends
                                                    </h4>
                                                    <p className="mt-2 text-gray-500">
                                                        {
                                                            cruiseData
                                                                .cruises[0]
                                                                .ends_at
                                                        }
                                                    </p>
                                                    <p className="text-gray-500">
                                                        {formatDate(
                                                            cruiseData
                                                                .cruises[0]
                                                                .ends_on
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="border-t border-gray-200 pt-4">
                                                    <h4 className="text-md font-medium text-gray-700">
                                                        Nights
                                                    </h4>
                                                    <div className="mt-2 text-gray-500">
                                                        {
                                                            cruiseData
                                                                .cruises[0]
                                                                .cruise_nights
                                                        }
                                                    </div>
                                                </div>

                                                <div className="border-t border-gray-200 pt-4">
                                                    <h4 className="text-md font-medium text-gray-700">
                                                        Port Visits
                                                    </h4>
                                                    <div className="mt-2 text-gray-500">
                                                        {
                                                            cruiseData
                                                                .portVisits
                                                                .length
                                                        }
                                                    </div>
                                                </div>

                                                <div className="border-t border-gray-200 pt-4">
                                                    <h4 className="text-md font-medium text-gray-700">
                                                        Region
                                                    </h4>
                                                    <div className="mt-2">
                                                        {cruiseData.cruises[0]
                                                            .regions ? (
                                                            <p className="text-gray-500">
                                                                {cruiseData.cruises[0].regions.join(
                                                                    ", "
                                                                ) ||
                                                                    "None available"}
                                                            </p>
                                                        ) : (
                                                            <p className="text-gray-500">
                                                                None available
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="border-t border-gray-200 pt-4">
                                                    <h4 className="text-md font-medium text-gray-700">
                                                        Cruise Type
                                                    </h4>
                                                    <div className="mt-2">
                                                        {cruiseData.cruises[0]
                                                            .cruise_type ? (
                                                            <p className="text-gray-500">
                                                                {cruiseData.cruises[0].cruise_type.join(
                                                                    ", "
                                                                ) ||
                                                                    "None available"}
                                                            </p>
                                                        ) : (
                                                            <p className="text-gray-500">
                                                                None available
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* Info for Ship tab */}
                                    {tab.name === "Ship" && (
                                        <div>
                                            <h3 className="text-lg font-medium text-gray-900">
                                                Ship
                                            </h3>
                                            <br />
                                            <div className="aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-2 sm:aspect-w-5">
                                                {/* <img
                          src={shipData.cover_image_href}
                          alt="Cruise ship"
                          className="h-auto w-full object-contain sm:rounded-lg mx-auto my-auto"
                        /> */}
                                                <img
                                                    src={getCompressedImage1000x(
                                                        shipData.title
                                                    )}
                                                    alt="Cruise ship"
                                                    className="h-auto w-full object-contain sm:rounded-lg mx-auto my-auto"
                                                />
                                            </div>
                                            <div className="mt-4 grid gap-4">
                                                {/* Ship teaser */}
                                                <ShipDetailsSection
                                                    title="Introduction"
                                                    content={
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: shipData.introduction,
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>

                                            {/* Ship facts */}
                                            <ShipDetailsSection
                                                title="Features"
                                                content={
                                                    <>
                                                        <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 md:gap-x-8">
                                                            <p>
                                                                <b>
                                                                    Launch year:
                                                                </b>{" "}
                                                                {
                                                                    shipData
                                                                        .ship_facts
                                                                        .launch_year
                                                                }
                                                            </p>
                                                            <p>
                                                                <b>
                                                                    Gross
                                                                    tonnage:
                                                                </b>{" "}
                                                                {
                                                                    shipData
                                                                        .ship_facts
                                                                        .gross_tonnage
                                                                }{" "}
                                                                tonnes
                                                            </p>
                                                            <p>
                                                                <b>Length:</b>{" "}
                                                                {
                                                                    shipData
                                                                        .ship_facts
                                                                        .length
                                                                }{" "}
                                                                m
                                                            </p>
                                                            <p>
                                                                <b>Capacity:</b>{" "}
                                                                {
                                                                    shipData
                                                                        .ship_facts
                                                                        .capacity
                                                                }{" "}
                                                                guests
                                                            </p>
                                                            <p>
                                                                <b>
                                                                    Deck count:
                                                                </b>{" "}
                                                                {
                                                                    shipData
                                                                        .ship_facts
                                                                        .deck_count
                                                                }
                                                            </p>
                                                            <p>
                                                                <b>
                                                                    Cabin count:
                                                                </b>{" "}
                                                                {
                                                                    shipData
                                                                        .ship_facts
                                                                        .cabin_count
                                                                }
                                                            </p>
                                                        </div>
                                                        {shipData.unique_feature &&
                                                            shipData.unique_feature !==
                                                                "<p><br></p>" && (
                                                                <>
                                                                    <h4 className="text-gray-700 font-medium mt-4">
                                                                        Unique
                                                                        to{" "}
                                                                        {
                                                                            shipData.title
                                                                        }
                                                                        :
                                                                    </h4>
                                                                    <div
                                                                        className="mt-2"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: shipData.unique_feature,
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </>
                                                }
                                            />

                                            {/* Ship accommodations */}
                                            <ShipDetailsSection
                                                title="Accommodation"
                                                content={
                                                    <div className="grid gap-2">
                                                        <div
                                                            className="mb-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: shipData
                                                                    .accommodation
                                                                    .intro,
                                                            }}
                                                        />
                                                        <div className="md:grid md:grid-cols-2 md:gap-2">
                                                            {shipData.accomodation_types.map(
                                                                (
                                                                    accommodationType
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            accommodationType.name
                                                                        }
                                                                    >
                                                                        <NestedTabMenu
                                                                            items={[
                                                                                {
                                                                                    name: accommodationType.name,
                                                                                    description:
                                                                                        accommodationType.description,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            {/* Ship dining */}
                                            <ShipDetailsSection
                                                title="Dining"
                                                content={
                                                    <div className="grid gap-2">
                                                        <div
                                                            className="mb-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: shipData
                                                                    .dining
                                                                    .intro,
                                                            }}
                                                        />
                                                        <div className="md:grid md:grid-cols-2 md:gap-2">
                                                            {shipData.dining_options.map(
                                                                (
                                                                    diningOption,
                                                                    index
                                                                ) => (
                                                                    <NestedTabMenu
                                                                        key={
                                                                            index
                                                                        }
                                                                        items={[
                                                                            diningOption,
                                                                        ]}
                                                                        isDining={
                                                                            true
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            {/* Ship Entertainment */}
                                            <ShipDetailsSection
                                                title="Entertainment"
                                                content={
                                                    <div className="grid gap-2">
                                                        <div
                                                            className="mb-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: shipData
                                                                    .entertainment
                                                                    .intro,
                                                            }}
                                                        />
                                                        <div className="md:grid md:grid-cols-2 md:gap-2">
                                                            {shipData.entertainment_types.map(
                                                                (
                                                                    entertainmentType
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            entertainmentType.name
                                                                        }
                                                                    >
                                                                        <NestedTabMenu
                                                                            items={[
                                                                                {
                                                                                    name: entertainmentType.name,
                                                                                    description:
                                                                                        entertainmentType.description,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            {/* Ship enrichment */}
                                            <ShipDetailsSection
                                                title="Enrichment"
                                                content={
                                                    <div className="grid gap-2">
                                                        <div
                                                            className="mb-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: shipData
                                                                    .enrichment
                                                                    .intro,
                                                            }}
                                                        />
                                                        <div className="md:grid md:grid-cols-2 md:gap-2">
                                                            {shipData.enrichment_types.map(
                                                                (
                                                                    enrichmentType
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            enrichmentType.name
                                                                        }
                                                                    >
                                                                        <NestedTabMenu
                                                                            items={[
                                                                                {
                                                                                    name: enrichmentType.name,
                                                                                    description:
                                                                                        enrichmentType.description,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            {/* Ship health & fitness */}
                                            <ShipDetailsSection
                                                title="Health & Fitness"
                                                content={
                                                    <div className="grid gap-2">
                                                        <div
                                                            className="mb-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: shipData
                                                                    .health_and_fitness
                                                                    .intro,
                                                            }}
                                                        />
                                                        <div className="md:grid md:grid-cols-2 md:gap-2">
                                                            {shipData.health_fitness_types.map(
                                                                (
                                                                    healthFitnessType
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            healthFitnessType.name
                                                                        }
                                                                    >
                                                                        <NestedTabMenu
                                                                            items={[
                                                                                {
                                                                                    name: healthFitnessType.name,
                                                                                    description:
                                                                                        healthFitnessType.description,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            {/* Ship kids & teens */}
                                            <ShipDetailsSection
                                                title="Kids & Teens"
                                                content={
                                                    <div className="grid gap-2">
                                                        <div
                                                            className="mb-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: shipData
                                                                    .kids_and_teens
                                                                    .intro,
                                                            }}
                                                        />
                                                        <div className="md:grid md:grid-cols-2 md:gap-2">
                                                            {shipData.kid_teen_types.map(
                                                                (
                                                                    kidTeenType
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            kidTeenType.name
                                                                        }
                                                                    >
                                                                        <NestedTabMenu
                                                                            items={[
                                                                                {
                                                                                    name: kidTeenType.name,
                                                                                    description:
                                                                                        kidTeenType.description,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            {/* Ship good to know */}
                                            <ShipDetailsSection
                                                title="Useful To Know"
                                                content={
                                                    <div className="grid gap-2">
                                                        <div className="md:grid md:grid-cols-2 md:gap-2">
                                                            {shipData.useful_types.map(
                                                                (
                                                                    usefulTypes
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            usefulTypes.name
                                                                        }
                                                                    >
                                                                        <NestedTabMenu
                                                                            items={[
                                                                                {
                                                                                    name: usefulTypes.name,
                                                                                    description:
                                                                                        usefulTypes.description,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    )}
                                    {/* Info for Itinerary tab */}
                                    {tab.name === "Itinerary" && (
                                        <div>
                                            <h3 className="text-lg font-medium text-gray-900">
                                                Itinerary
                                            </h3>
                                            {cruiseData.portVisits.map(
                                                (portVisit, index) => (
                                                    <div
                                                        key={index}
                                                        className="mt-4 border border-gray-200 p-4 rounded-lg"
                                                    >
                                                        <h4 className="text-md font-medium text-gray-700 mb-4">
                                                            Day {portVisit.day}
                                                        </h4>
                                                        <div className="grid grid-cols-2 gap-4 mt-2">
                                                            <div>
                                                                <p className="text-sm font-medium text-gray-700">
                                                                    Port Name
                                                                </p>
                                                                <p className="text-sm text-gray-500">
                                                                    {portVisit.port
                                                                        ? portVisit
                                                                              .port
                                                                              .name ||
                                                                          "Unknown Port"
                                                                        : "Unknown Port"}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="text-sm font-medium text-gray-700">
                                                                    Country
                                                                </p>
                                                                <p className="text-sm text-gray-500">
                                                                    {portVisit.port
                                                                        ? portVisit
                                                                              .port
                                                                              .country ||
                                                                          "Unknown Country"
                                                                        : "Unknown Country"}
                                                                </p>
                                                            </div>
                                                            {index === 0 ? (
                                                                // On day 1
                                                                <>
                                                                    <div>
                                                                        <p className="text-sm font-medium text-gray-700">
                                                                            Cruise
                                                                            Starts
                                                                            (Local)
                                                                        </p>
                                                                        <p className="text-sm text-gray-500">
                                                                            {formatDateAndTime(
                                                                                portVisit.departs_on
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            ) : index ===
                                                              cruiseData
                                                                  .portVisits
                                                                  .length -
                                                                  1 ? (
                                                                // On the last day
                                                                <>
                                                                    <div>
                                                                        <p className="text-sm font-medium text-gray-700">
                                                                            Ship
                                                                            Docks
                                                                            (Local)
                                                                        </p>
                                                                        <p className="text-sm text-gray-500">
                                                                            {formatDateAndTime(
                                                                                portVisit.arrives_on
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div>
                                                                        <p className="text-sm font-medium text-gray-700">
                                                                            Arrives
                                                                            (Local)
                                                                        </p>
                                                                        <p className="text-sm text-gray-500">
                                                                            {formatDateAndTime(
                                                                                portVisit.arrives_on
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    {cruiseData
                                                                        .cruises[0]
                                                                        .operator_title ===
                                                                        "Virgin Voyages" &&
                                                                        portVisit.notes && (
                                                                            <div>
                                                                                <p className="text-sm font-medium text-gray-700">
                                                                                    Departs
                                                                                    (Local)
                                                                                </p>
                                                                                <p className="text-sm text-gray-500">
                                                                                    {portVisit.notes.startsWith(
                                                                                        ". "
                                                                                    )
                                                                                        ? formatTime(
                                                                                              portVisit.notes.substring(
                                                                                                  2
                                                                                              )
                                                                                          ) // Remove the leading period and space and format the time
                                                                                        : portVisit.notes}
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    {cruiseData
                                                                        .cruises[0]
                                                                        .operator_title !==
                                                                        "Virgin Voyages" && (
                                                                        <div>
                                                                            <p className="text-sm font-medium text-gray-700">
                                                                                Departs
                                                                                (Local)
                                                                            </p>
                                                                            <p className="text-sm text-gray-500">
                                                                                {formatDateAndTime(
                                                                                    portVisit.departs_on
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                            {/* {portVisit.notes && (
                              <div className="col-span-2">
                                <p className="text-sm font-medium text-gray-700">
                                  Notes
                                </p>
                                <p className="text-sm text-gray-500">
                                  {portVisit.notes}
                                </p>
                              </div>
                            )} */}
                                                            {portVisit.notes && (
                                                                <div className="col-span-2">
                                                                    <p className="text-sm font-medium text-gray-700">
                                                                        Notes
                                                                    </p>
                                                                    <p className="text-sm text-gray-500">
                                                                        {cruiseData
                                                                            .cruises[0]
                                                                            .operator_title ===
                                                                            "Virgin Voyages" &&
                                                                        portVisit.notes.match(
                                                                            /\.\s*Depart\s*\d+/i
                                                                        )
                                                                            ? portVisit.notes.replace(
                                                                                  /\.\s*Depart\s*\d+/i,
                                                                                  ""
                                                                              )
                                                                            : portVisit.notes}
                                                                    </p>
                                                                </div>
                                                            )}
                                                            {portVisit.port &&
                                                                portVisit.port
                                                                    .summary && (
                                                                    <div className="col-span-2">
                                                                        <p className="text-sm font-medium text-gray-700">
                                                                            Port
                                                                            Summary
                                                                        </p>
                                                                        <p className="text-sm text-gray-500">
                                                                            {isSummaryExpanded
                                                                                ? portVisit
                                                                                      .port
                                                                                      .summary
                                                                                : truncateSummary(
                                                                                      portVisit
                                                                                          .port
                                                                                          .summary
                                                                                  )}
                                                                        </p>
                                                                        {portVisit
                                                                            .port
                                                                            .summary
                                                                            .length >
                                                                            MAX_SUMMARY_LENGTH && (
                                                                            <button
                                                                                className="text-sm text-blue-600 underline mt-2 cursor-pointer"
                                                                                onClick={() =>
                                                                                    setIsSummaryExpanded(
                                                                                        !isSummaryExpanded
                                                                                    )
                                                                                }
                                                                            >
                                                                                {isSummaryExpanded
                                                                                    ? "View less"
                                                                                    : "View more"}
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            {/* Render port images */}
                                                            {portVisit.portImages &&
                                                                portVisit
                                                                    .portImages
                                                                    .images &&
                                                                portVisit
                                                                    .portImages
                                                                    .images
                                                                    .length >
                                                                    0 && (
                                                                    <div className="col-span-2 mt-2">
                                                                        <h5 className="text-sm font-medium text-gray-700">
                                                                            Port
                                                                            Images
                                                                        </h5>
                                                                        <div className="w-full h-auto mt-2">
                                                                            {/* Render port images carousel */}
                                                                            {isMobile ? (
                                                                                // Mobile view
                                                                                <Carousel
                                                                                    showThumbs={
                                                                                        false
                                                                                    }
                                                                                    infiniteLoop={
                                                                                        true
                                                                                    }
                                                                                    showStatus={
                                                                                        true
                                                                                    }
                                                                                    showArrows={
                                                                                        true
                                                                                    }
                                                                                    swipeable={
                                                                                        true
                                                                                    }
                                                                                    emulateTouch={
                                                                                        true
                                                                                    }
                                                                                    selectedItem={
                                                                                        0
                                                                                    }
                                                                                    autoPlay={
                                                                                        true
                                                                                    }
                                                                                    interval={
                                                                                        5000
                                                                                    }
                                                                                    stopOnHover={
                                                                                        true
                                                                                    }
                                                                                    dynamicHeight={
                                                                                        true
                                                                                    }
                                                                                >
                                                                                    {portVisit.portImages.images.map(
                                                                                        (
                                                                                            image,
                                                                                            imageIndex
                                                                                        ) => (
                                                                                            <div
                                                                                                key={
                                                                                                    imageIndex
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        image.href
                                                                                                    }
                                                                                                    alt={
                                                                                                        image.name
                                                                                                    }
                                                                                                    className="object-cover object-center w-full h-48 rounded-lg"
                                                                                                />
                                                                                                <p className="text-xs text-gray-500 mt-1">
                                                                                                    {
                                                                                                        image.credit
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </Carousel>
                                                                            ) : // Desktop view
                                                                            portVisit
                                                                                  .portImages
                                                                                  .images
                                                                                  .length >
                                                                              1 ? ( // Check if there's more than one image
                                                                                <Carousel
                                                                                    showThumbs={
                                                                                        false
                                                                                    }
                                                                                    infiniteLoop={
                                                                                        true
                                                                                    }
                                                                                    showStatus={
                                                                                        true
                                                                                    }
                                                                                    showArrows={
                                                                                        true
                                                                                    }
                                                                                    swipeable={
                                                                                        true
                                                                                    }
                                                                                    emulateTouch={
                                                                                        true
                                                                                    }
                                                                                    selectedItem={
                                                                                        0
                                                                                    }
                                                                                    centerMode={
                                                                                        !isMobile
                                                                                    }
                                                                                    centerSlidePercentage={
                                                                                        55
                                                                                    }
                                                                                    autoPlay={
                                                                                        true
                                                                                    }
                                                                                    interval={
                                                                                        5000
                                                                                    }
                                                                                    stopOnHover={
                                                                                        true
                                                                                    }
                                                                                    dynamicHeight={
                                                                                        true
                                                                                    }
                                                                                >
                                                                                    {portVisit.portImages.images.map(
                                                                                        (
                                                                                            image,
                                                                                            imageIndex
                                                                                        ) => (
                                                                                            <div
                                                                                                key={
                                                                                                    imageIndex
                                                                                                }
                                                                                                className="px-2"
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        image.href
                                                                                                    }
                                                                                                    alt={
                                                                                                        image.name
                                                                                                    }
                                                                                                    className="object-cover object-center w-full h-60 rounded-lg"
                                                                                                />
                                                                                                <br />
                                                                                                <p className="text-xs text-gray-500 mt-8">
                                                                                                    {
                                                                                                        image.credit
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </Carousel>
                                                                            ) : (
                                                                                // Render single image if there's only one
                                                                                <div className="px-2">
                                                                                    <img
                                                                                        src={
                                                                                            portVisit
                                                                                                .portImages
                                                                                                .images[0]
                                                                                                .href
                                                                                        }
                                                                                        alt={
                                                                                            portVisit
                                                                                                .portImages
                                                                                                .images[0]
                                                                                                .name
                                                                                        }
                                                                                        className="object-cover object-center w-full h-48 rounded-lg"
                                                                                    />
                                                                                    <p className="text-xs text-gray-500 mt-1">
                                                                                        {
                                                                                            portVisit
                                                                                                .portImages
                                                                                                .images[0]
                                                                                                .credit
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {/* Info for Gallery tab */}
                                    {tab.name === "Gallery" && (
                                        <div>
                                            <h3 className="text-lg font-medium text-gray-900">
                                                Gallery
                                            </h3>
                                            <div className="mt-4">
                                                <CruiseImageGallery
                                                    shipData={shipData}
                                                    cruiseData={cruiseData}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* Info for Pricing tab */}
                                    {tab.name === "Pricing" && (
                                        <PricingTab
                                            cruiseLine={cruiseLine}
                                            cruiseShip={cruiseShip}
                                            cruiseDate={cruiseDate}
                                            cruiseId={cruiseId}
                                        />
                                    )}
                                    {/* Info for Map tab - COMING SOON */}
                                    {tab.name === "Map" && (
                                        <div>
                                            <h3 className="text-lg font-medium text-gray-900">
                                                Map
                                            </h3>
                                            <h3 className="text-lg font-medium text-gray-900">
                                                This feature will be added soon.
                                            </h3>
                                        </div>
                                    )}
                                    {/* Info for Contact Us tab */}
                                    {tab.name === "Contact Us" && (
                                        <div>
                                            <h3 className="text-lg font-medium text-gray-900">
                                                Contact Us
                                            </h3>
                                            <ContactUsTab
                                                cruiseData={cruiseData}
                                            />
                                        </div>
                                    )}
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </section>
        </div>
    );
}
export default CruiseDetailPage;
