import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from '../thunks/loginUser'
import { fetchActiveUser } from '../thunks/fetchActiveUser';

const loginUserSlice = createSlice({
  name: "loginUserSlice",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(fetchActiveUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.user = action.payload; // Assuming the payload structure matches
    });
  }
});

export const { setLoginUserStatistics } = loginUserSlice.actions;

export default loginUserSlice.reducer;