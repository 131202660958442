/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminMetrics } from "../../store/thunks/admin/fetchAdminMetrics";
import { Menu, Transition } from "@headlessui/react";
import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { ResponsiveLine } from '@nivo/line'

import MetricsDetails from "../../components/admin/MetricsDetails";

const secondaryNavigation = [
    { name: "Last 7 days", href: "#", current: true },
    { name: "Last 30 days", href: "#", current: false },
    { name: "All-time", href: "#", current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Metrics() {
    const dispatch = useDispatch();
    const stats = useSelector((state) => state.adminMetrics.data) || []; // Assuming you store admin metrics in a 'stats' property in your Redux state
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [dateFilter, setDateFilter] = useState("Last 7 days");
    const [feedback, setFeedback] = useState("");
    const [feedbackColor, setFeedbackColor] = useState("");

    useEffect(() => {
        // Fetch admin metrics when the component mounts or when the dateFilter changes
        dispatch(fetchAdminMetrics(dateFilter));
    }, [dispatch, dateFilter]);

    const refreshData = () => {
        setFeedback("Refresh successful");
        setFeedbackColor("green");
        setTimeout(() => {
            setFeedback("");
        }, 1750);
    };

    return (
        <>
            <main>
                <div className="ml-0 sm:px-6 lg:pr-10 lg:pl-0">
                    {/* Secondary navigation aka header w buttons and dates */}
                    <header className="pb-4 pt-6 sm:pb-6">
                        <div className="sm:flex sm:items-center justify-between">
                            <h1 className="text-base font-semibold leading-7 text-gray-900">
                                Metrics
                            </h1>
                            <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                                {secondaryNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setDateFilter(item.name);
                                        }}
                                        className={
                                            dateFilter === item.name
                                                ? "text-indigo-600"
                                                : "text-gray-700"
                                        }
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <p
                                className="text-base font-semibold leading-6 mr-2.5"
                                style={{ color: feedbackColor }}
                            >
                                {feedback}
                            </p>
                            <button
                                href="#"
                                onClick={refreshData}
                                className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {/* <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" /> */}
                                Refresh Metrics
                            </button>
                        </div>
                    </header>

                    {/* Stats */}
                    <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
                        <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
                            {stats.map((stat, statIdx) => (
                                <div
                                    key={stat.name}
                                    className={classNames(
                                        statIdx % 2 === 1
                                            ? "sm:border-l"
                                            : statIdx === 2
                                            ? "lg:border-l"
                                            : "",
                                        "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                                    )}
                                >
                                    <dt className="text-sm font-medium leading-6 text-gray-500">
                                        {stat.name}
                                    </dt>
                                    <dd
                                        className={classNames(
                                            stat.changeType === "negative"
                                                ? "text-rose-600"
                                                : "text-gray-700",
                                            "text-xs font-medium"
                                        )}
                                    >
                                        {stat.change}
                                    </dd>
                                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                        {stat.value}
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>

                {/* Recent client list*/}
                {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="flex items-center justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Recent clients
              </h2>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                View all<span className="sr-only">, clients</span>
              </a>
            </div>
            <ul
              role="list"
              className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
              {clients.map((client) => (
                <li
                  key={client.id}
                  className="overflow-hidden rounded-xl border border-gray-200">
                  <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                    <img
                      src={client.imageUrl}
                      alt={client.name}
                      className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                    />
                    <div className="text-sm font-medium leading-6 text-gray-900">
                      {client.name}
                    </div>
                    <Menu as="div" className="relative ml-auto">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}>
                                View
                                <span className="sr-only">, {client.name}</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}>
                                Edit
                                <span className="sr-only">, {client.name}</span>
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Last invoice</dt>
                      <dd className="text-gray-700">
                        <time dateTime={client.lastInvoice.dateTime}>
                          {client.lastInvoice.date}
                        </time>
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Amount</dt>
                      <dd className="flex items-start gap-x-2">
                        <div className="font-medium text-gray-900">
                          {client.lastInvoice.amount}
                        </div>
                        <div
                          className={classNames(
                            statuses[client.lastInvoice.status],
                            "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                          )}>
                          {client.lastInvoice.status}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

                {/* Stats component with shared borders  */}
                {/* <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Last 7 days
          </h3>
          <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
            {stats.map((item) => (
              <div key={item.name} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  {item.name}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {item.stat}
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {item.previousStat}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      item.changeType === "increase"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800",
                      "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                    )}>
                    {item.changeType === "increase" ? (
                      <ArrowUpIcon
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowDownIcon
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                      />
                    )}

                    <span className="sr-only">
                      {" "}
                      {item.changeType === "increase"
                        ? "Increased"
                        : "Decreased"}{" "}
                      by{" "}
                    </span>
                    {item.change}
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div> */}

                {/* <div className="pt-4 ml-0 sm:px-6 lg:pr-10 lg:pl-0"> */}
                {/* Recent activity table */}
                {/* <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
                Recent activity
              </h2>
            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead className="sr-only">
                      <tr>
                        <th>Amount</th>
                        <th className="hidden sm:table-cell">Client</th>
                        <th>More details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {days.map((day) => (
                        <Fragment key={day.dateTime}>
                          <tr className="text-sm leading-6 text-gray-900">
                            <th
                              scope="colgroup"
                              colSpan={3}
                              className="relative isolate py-2 font-semibold">
                              <time dateTime={day.dateTime}>{day.date}</time>
                              <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                              <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                            </th>
                          </tr>
                          {day.transactions.map((transaction) => (
                            <tr key={transaction.id}>
                              <td className="relative py-5 pr-6">
                                <div className="flex gap-x-6">
                                  <transaction.icon
                                    className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                    aria-hidden="true"
                                  />
                                  <div className="flex-auto">
                                    <div className="flex items-start gap-x-3">
                                      <div className="text-sm font-medium leading-6 text-gray-900">
                                        {transaction.amount}
                                      </div>
                                      <div
                                        className={classNames(
                                          statuses[transaction.status],
                                          "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                        )}>
                                        {transaction.status}
                                      </div>
                                    </div>
                                    {transaction.tax ? (
                                      <div className="mt-1 text-xs leading-5 text-gray-500">
                                        {transaction.tax} tax
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                              </td>
                              <td className="hidden py-5 pr-6 sm:table-cell">
                                <div className="text-sm leading-6 text-gray-900">
                                  {transaction.client}
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  {transaction.description}
                                </div>
                              </td>
                              <td className="py-5 text-right">
                                <div className="flex justify-end">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevents the default anchor behavior
                                      setShowDetails(true);
                                    }}
                                    className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500">
                                    View
                                    <span className="hidden sm:inline">
                                      {" "}
                                      transaction
                                    </span>
                                    <span className="sr-only">
                                      , invoice #{transaction.invoiceNumber},{" "}
                                      {transaction.client}
                                    </span>
                                  </a>
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  Invoice{" "}
                                  <span className="text-gray-900">
                                    #{transaction.invoiceNumber}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
                <n
                    animate
                    curve="linear"
                    data={[
                        {
                            color: "hsl(66, 70%, 50%)",
                            data: [
                                {
                                    color: "hsl(3, 70%, 50%)",
                                    x: "PS",
                                    y: 10,
                                },
                                {
                                    color: "hsl(291, 70%, 50%)",
                                    x: "JP",
                                    y: 52,
                                },
                                {
                                    color: "hsl(150, 70%, 50%)",
                                    x: "LU",
                                    y: 5,
                                },
                                {
                                    color: "hsl(249, 70%, 50%)",
                                    x: "BL",
                                    y: 60,
                                },
                                {
                                    color: "hsl(350, 70%, 50%)",
                                    x: "SO",
                                    y: 20,
                                },
                                {
                                    color: "hsl(253, 70%, 50%)",
                                    x: "BT",
                                    y: 36,
                                },
                                {
                                    color: "hsl(203, 70%, 50%)",
                                    x: "GR",
                                    y: 43,
                                },
                                {
                                    color: "hsl(309, 70%, 50%)",
                                    x: "AW",
                                    y: 30,
                                },
                                {
                                    color: "hsl(324, 70%, 50%)",
                                    x: "GM",
                                    y: 5,
                                },
                                {
                                    color: "hsl(211, 70%, 50%)",
                                    x: "AL",
                                    y: 11,
                                },
                                {
                                    color: "hsl(19, 70%, 50%)",
                                    x: "SA",
                                    y: 29,
                                },
                                {
                                    color: "hsl(204, 70%, 50%)",
                                    x: "MF",
                                    y: 57,
                                },
                                {
                                    color: "hsl(259, 70%, 50%)",
                                    x: "IO",
                                    y: 21,
                                },
                                {
                                    color: "hsl(336, 70%, 50%)",
                                    x: "SI",
                                    y: 4,
                                },
                                {
                                    color: "hsl(13, 70%, 50%)",
                                    x: "CL",
                                    y: 30,
                                },
                                {
                                    color: "hsl(63, 70%, 50%)",
                                    x: "RS",
                                    y: 41,
                                },
                                {
                                    color: "hsl(222, 70%, 50%)",
                                    x: "AE",
                                    y: 0,
                                },
                                {
                                    color: "hsl(38, 70%, 50%)",
                                    x: "ML",
                                    y: 25,
                                },
                            ],
                            id: "whisky",
                        },
                        {
                            color: "hsl(261, 70%, 50%)",
                            data: [
                                {
                                    color: "hsl(189, 70%, 50%)",
                                    x: "PS",
                                    y: 36,
                                },
                                {
                                    color: "hsl(117, 70%, 50%)",
                                    x: "JP",
                                    y: 3,
                                },
                                {
                                    color: "hsl(259, 70%, 50%)",
                                    x: "LU",
                                    y: 0,
                                },
                                {
                                    color: "hsl(303, 70%, 50%)",
                                    x: "BL",
                                    y: 36,
                                },
                                {
                                    color: "hsl(305, 70%, 50%)",
                                    x: "SO",
                                    y: 12,
                                },
                                {
                                    color: "hsl(106, 70%, 50%)",
                                    x: "BT",
                                    y: 51,
                                },
                                {
                                    color: "hsl(197, 70%, 50%)",
                                    x: "GR",
                                    y: 34,
                                },
                                {
                                    color: "hsl(87, 70%, 50%)",
                                    x: "AW",
                                    y: 39,
                                },
                                {
                                    color: "hsl(229, 70%, 50%)",
                                    x: "GM",
                                    y: 22,
                                },
                                {
                                    color: "hsl(354, 70%, 50%)",
                                    x: "AL",
                                    y: 32,
                                },
                                {
                                    color: "hsl(43, 70%, 50%)",
                                    x: "SA",
                                    y: 20,
                                },
                                {
                                    color: "hsl(153, 70%, 50%)",
                                    x: "MF",
                                    y: 60,
                                },
                                {
                                    color: "hsl(228, 70%, 50%)",
                                    x: "IO",
                                    y: 58,
                                },
                                {
                                    color: "hsl(213, 70%, 50%)",
                                    x: "SI",
                                    y: 57,
                                },
                                {
                                    color: "hsl(54, 70%, 50%)",
                                    x: "CL",
                                    y: 44,
                                },
                                {
                                    color: "hsl(124, 70%, 50%)",
                                    x: "RS",
                                    y: 44,
                                },
                                {
                                    color: "hsl(131, 70%, 50%)",
                                    x: "AE",
                                    y: 25,
                                },
                                {
                                    color: "hsl(279, 70%, 50%)",
                                    x: "ML",
                                    y: 49,
                                },
                            ],
                            id: "rhum",
                        },
                        {
                            color: "hsl(301, 70%, 50%)",
                            data: [
                                {
                                    color: "hsl(270, 70%, 50%)",
                                    x: "PS",
                                    y: 40,
                                },
                                {
                                    color: "hsl(325, 70%, 50%)",
                                    x: "JP",
                                    y: 22,
                                },
                                {
                                    color: "hsl(168, 70%, 50%)",
                                    x: "LU",
                                    y: 28,
                                },
                                {
                                    color: "hsl(298, 70%, 50%)",
                                    x: "BL",
                                    y: 57,
                                },
                                {
                                    color: "hsl(216, 70%, 50%)",
                                    x: "SO",
                                    y: 13,
                                },
                                {
                                    color: "hsl(258, 70%, 50%)",
                                    x: "BT",
                                    y: 42,
                                },
                                {
                                    color: "hsl(162, 70%, 50%)",
                                    x: "GR",
                                    y: 35,
                                },
                                {
                                    color: "hsl(330, 70%, 50%)",
                                    x: "AW",
                                    y: 42,
                                },
                                {
                                    color: "hsl(185, 70%, 50%)",
                                    x: "GM",
                                    y: 8,
                                },
                                {
                                    color: "hsl(233, 70%, 50%)",
                                    x: "AL",
                                    y: 6,
                                },
                                {
                                    color: "hsl(255, 70%, 50%)",
                                    x: "SA",
                                    y: 47,
                                },
                                {
                                    color: "hsl(53, 70%, 50%)",
                                    x: "MF",
                                    y: 57,
                                },
                                {
                                    color: "hsl(246, 70%, 50%)",
                                    x: "IO",
                                    y: 35,
                                },
                                {
                                    color: "hsl(74, 70%, 50%)",
                                    x: "SI",
                                    y: 24,
                                },
                                {
                                    color: "hsl(282, 70%, 50%)",
                                    x: "CL",
                                    y: 11,
                                },
                                {
                                    color: "hsl(227, 70%, 50%)",
                                    x: "RS",
                                    y: 29,
                                },
                                {
                                    color: "hsl(270, 70%, 50%)",
                                    x: "AE",
                                    y: 40,
                                },
                                {
                                    color: "hsl(137, 70%, 50%)",
                                    x: "ML",
                                    y: 16,
                                },
                            ],
                            id: "gin",
                        },
                        {
                            color: "hsl(129, 70%, 50%)",
                            data: [
                                {
                                    color: "hsl(129, 70%, 50%)",
                                    x: "PS",
                                    y: 36,
                                },
                                {
                                    color: "hsl(3, 70%, 50%)",
                                    x: "JP",
                                    y: 3,
                                },
                                {
                                    color: "hsl(244, 70%, 50%)",
                                    x: "LU",
                                    y: 22,
                                },
                                {
                                    color: "hsl(253, 70%, 50%)",
                                    x: "BL",
                                    y: 23,
                                },
                                {
                                    color: "hsl(33, 70%, 50%)",
                                    x: "SO",
                                    y: 8,
                                },
                                {
                                    color: "hsl(80, 70%, 50%)",
                                    x: "BT",
                                    y: 46,
                                },
                                {
                                    color: "hsl(35, 70%, 50%)",
                                    x: "GR",
                                    y: 30,
                                },
                                {
                                    color: "hsl(184, 70%, 50%)",
                                    x: "AW",
                                    y: 43,
                                },
                                {
                                    color: "hsl(134, 70%, 50%)",
                                    x: "GM",
                                    y: 34,
                                },
                                {
                                    color: "hsl(159, 70%, 50%)",
                                    x: "AL",
                                    y: 19,
                                },
                                {
                                    color: "hsl(250, 70%, 50%)",
                                    x: "SA",
                                    y: 9,
                                },
                                {
                                    color: "hsl(218, 70%, 50%)",
                                    x: "MF",
                                    y: 6,
                                },
                                {
                                    color: "hsl(75, 70%, 50%)",
                                    x: "IO",
                                    y: 23,
                                },
                                {
                                    color: "hsl(246, 70%, 50%)",
                                    x: "SI",
                                    y: 33,
                                },
                                {
                                    color: "hsl(83, 70%, 50%)",
                                    x: "CL",
                                    y: 43,
                                },
                                {
                                    color: "hsl(247, 70%, 50%)",
                                    x: "RS",
                                    y: 48,
                                },
                                {
                                    color: "hsl(136, 70%, 50%)",
                                    x: "AE",
                                    y: 49,
                                },
                                {
                                    color: "hsl(180, 70%, 50%)",
                                    x: "ML",
                                    y: 40,
                                },
                            ],
                            id: "vodka",
                        },
                        {
                            color: "hsl(25, 70%, 50%)",
                            data: [
                                {
                                    color: "hsl(42, 70%, 50%)",
                                    x: "PS",
                                    y: 45,
                                },
                                {
                                    color: "hsl(72, 70%, 50%)",
                                    x: "JP",
                                    y: 50,
                                },
                                {
                                    color: "hsl(156, 70%, 50%)",
                                    x: "LU",
                                    y: 54,
                                },
                                {
                                    color: "hsl(261, 70%, 50%)",
                                    x: "BL",
                                    y: 15,
                                },
                                {
                                    color: "hsl(359, 70%, 50%)",
                                    x: "SO",
                                    y: 51,
                                },
                                {
                                    color: "hsl(209, 70%, 50%)",
                                    x: "BT",
                                    y: 29,
                                },
                                {
                                    color: "hsl(312, 70%, 50%)",
                                    x: "GR",
                                    y: 12,
                                },
                                {
                                    color: "hsl(347, 70%, 50%)",
                                    x: "AW",
                                    y: 25,
                                },
                                {
                                    color: "hsl(278, 70%, 50%)",
                                    x: "GM",
                                    y: 56,
                                },
                                {
                                    color: "hsl(194, 70%, 50%)",
                                    x: "AL",
                                    y: 22,
                                },
                                {
                                    color: "hsl(221, 70%, 50%)",
                                    x: "SA",
                                    y: 33,
                                },
                                {
                                    color: "hsl(212, 70%, 50%)",
                                    x: "MF",
                                    y: 57,
                                },
                                {
                                    color: "hsl(97, 70%, 50%)",
                                    x: "IO",
                                    y: 49,
                                },
                                {
                                    color: "hsl(235, 70%, 50%)",
                                    x: "SI",
                                    y: 59,
                                },
                                {
                                    color: "hsl(161, 70%, 50%)",
                                    x: "CL",
                                    y: 17,
                                },
                                {
                                    color: "hsl(104, 70%, 50%)",
                                    x: "RS",
                                    y: 12,
                                },
                                {
                                    color: "hsl(31, 70%, 50%)",
                                    x: "AE",
                                    y: 50,
                                },
                                {
                                    color: "hsl(243, 70%, 50%)",
                                    x: "ML",
                                    y: 26,
                                },
                            ],
                            id: "cognac",
                        },
                    ]}
                    enableSlices="x"
                    height={400}
                    margin={{
                        bottom: 60,
                        left: 80,
                        right: 20,
                        top: 20,
                    }}
                    width={900}
                    yScale={{
                        stacked: true,
                        type: "linear",
                    }}
                />
            </main>

            {showDetails && (
                <MetricsDetails closeDetails={() => setShowDetails(false)} />
            )}
        </>
    );
}

export default Metrics;
