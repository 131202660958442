import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { fetchCruiseDetails } from "../../store/thunks/widgety/fetchCruiseDetails";
import { Disclosure } from "@headlessui/react";
import { MapPinIcon } from "@heroicons/react/20/solid";
import { Plus, Minus, XOutline } from "heroicons-react";
import { TailSpin } from "react-loader-spinner";
import CruiseUnavailableModal from "../cruiseList/CruiseUnavailableModal";
import PortVisitsCarousel from "./PortVisitsCarousel";

export default function CruiseDetailModal({
  cruiseId,
  sailDate,
  shipName,
  isOpen,
  onClose,
}) {
  const { cruise, loading, error } = useSelector((state) => state.cruise);
  const cruiseData = useSelector(
    (state) => state.cruiseDetails.cruiseData.cruises ?? []
  );
  const dispatch = useDispatch();
  const [localCruiseData, setLocalCruiseData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // to track the loading state of your data
  const [isUnavailableModalOpen, setUnavailableModalOpen] = useState(false);
  const [showFullSummary, setShowFullSummary] = useState(false);

  // First useeffect
  useEffect(() => {
    const fetchCruiseDetailsData = async () => {
      setIsLoading(true);
      try {
        const sailDateToISO = new Date(sailDate);
        sailDateToISO.setDate(sailDateToISO.getDate() + 1);
        const endDate = sailDateToISO.toISOString().split("T")[0];

        const data = await dispatch(
          fetchCruiseDetails({
            start_date_range_beginning: sailDate,
            start_date_range_end: endDate,
            ship_name: shipName,
          })
        ).unwrap();

        setLocalCruiseData(data);
      } catch (error) {
        console.error("Error fetching cruise details", error);
        // setCruiseUnavailableModalOpen(true); // show the unavailable modal on error
      } finally {
        setIsLoading(false);
        console.log("loading state = false in 1st useeffect. SPINNER STOP");
      }
    };

    if (sailDate && shipName) {
      fetchCruiseDetailsData();
    }

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [sailDate, shipName, dispatch]);

  // Second useeffect
  useEffect(() => {
    if (
      !localCruiseData ||
      (localCruiseData.cruises && localCruiseData.cruises.length === 0)
    ) {
      setUnavailableModalOpen(true); // open the modal if the API response is invalid
    }
  }, [localCruiseData]);

  if (isLoading) {
    return (
      <div className="flex items-center">
        <TailSpin color="#4535be" height={35} width={35} radius={2} />
        <p className="font-medium px-4" style={{ color: "whitesmoke" }}>
          Getting cruise details...
        </p>
      </div>
    );
  }

  if (
    !localCruiseData ||
    (localCruiseData.cruises && localCruiseData.cruises.length === 0)
  ) {
    <div>
      <p className="font-medium px-4" style={{ color: "whitesmoke" }}>
        Could not retrieve cruise details right now. Try another cruise.
      </p>
    </div>;
  }

  console.log("Local Cruise Data", localCruiseData); // Log the whole localCruiseData object

  if (loading) {
    return <TailSpin color="#4535be" height={35} width={35} />;
  }

  if (error) {
    console.log("error loading details", error.message);
    return <div>Error loading ship details! {error.message}</div>;
  }

  function truncateSummary(summary) {
    const maxLength = 650;
    if (summary.length <= maxLength) {
      return summary;
    }

    const truncatedText = summary.substr(0, maxLength);
    const nextSentenceIndex = truncatedText.lastIndexOf(". ");

    if (nextSentenceIndex >= 0) {
      return truncatedText.substr(0, nextSentenceIndex + 1);
    } else {
      return truncatedText;
    }
  }

  function calculateStayDuration(arrivesOn, departsOn) {
    const arrivesDate = new Date(arrivesOn);
    const departsDate = new Date(departsOn);
    const durationInMilliseconds = departsDate - arrivesDate;
    const durationInHours = durationInMilliseconds / (1000 * 60 * 60);

    return Math.round(durationInHours); // Round to the nearest whole hour
  }

  return (
    <div className="bg-white rounded-lg max-h-screen overflow-auto">
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <TailSpin />
        </div>
      ) : error ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          Error loading cruise details! {error.message}
        </div>
      ) : !localCruiseData ||
        (localCruiseData.cruises && localCruiseData.cruises.length === 0) ? (
        <CruiseUnavailableModal
          isOpen={isUnavailableModalOpen}
          closeModal={() => setUnavailableModalOpen(false)}
          closeParentModal={onClose} // Pass the onClose prop to the child modal
        />
      ) : (
        <div className="mx-auto max-w-5xl px-4 py-4 sm:px-6 lg:px-8 transition-all transform">
          <div className="lg:grid lg:grid-cols-1 lg:items-start lg:gap-x-8">
            {/* Header section */}
            <div className="flex justify-between items-center w-full">
              <div className="flex-1">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                  {localCruiseData.cruises[0].name}
                </h1>
                <p className="text-xl tracking-tight text-gray-900 pt-1">
                  <i>{localCruiseData.cruises[0].ship_title}</i> with{" "}
                  <i>{localCruiseData.cruises[0].operator_title}</i>
                </p>
              </div>
              <button
                onClick={onClose}
                className="p-2"
                aria-label="Close modal">
                <XOutline className="w-6 h-6" />
              </button>
            </div>

            {/* Start and End Data section */}
            <div className="mt-6 w-full">
              {/* <h3 className="text-base font-medium text-gray-700">
                Starts: {localCruiseData.cruises[0].starts_at}.{" "}
                {new Date(
                  localCruiseData.portVisits[0].departs_on
                ).toLocaleTimeString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
                ,{" "}
                {new Date(
                  localCruiseData.cruises[0].starts_on
                ).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  timeZoneName: "short",
                })}
              </h3> */}

              <h3 className="text-base font-medium text-gray-700">
                Starts: {localCruiseData.cruises[0].starts_at}.{" "}
                {new Date(
                  localCruiseData.portVisits[0].departs_on
                ).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                })}
                {" local"}
              </h3>

              <h3 className="text-base font-medium text-gray-700 mt-2">
                Ends: {localCruiseData.cruises[0].starts_at}.{" "}
                {new Date(localCruiseData.cruises[0].ends_on).toLocaleString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                    // hour: "numeric",
                    // minute: "2-digit",
                  }
                )}
                {/* {" local"} */}
              </h3>

              {/* <h3 className="text-base font-medium text-gray-700 mt-2">
                Ends: {localCruiseData.cruises[0].ends_at}.{" "}
                {new Date(localCruiseData.cruises[0].ends_on).toLocaleString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )}
                ,{" "}
                {new Date(localCruiseData.cruises[0].ends_on).toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "2-digit",
                    // timeZoneName: "short",
                  }
                )}
                {" local"}
              </h3> */}
            </div>
          </div>
          <div className="flex justify-between items-center flow-root mt-8 pb-2">
            <ul role="list" className="-mb-8">
              {localCruiseData?.portVisits?.map((visit, idx) => (
                <li key={visit.id || idx}>
                  <div className="relative pb-6">
                    {idx !== localCruiseData.portVisits?.length - 1 ? (
                      <span
                        className="absolute left-4 top-4 -ml-px ml-1.5 h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <Disclosure as="div">
                      {({ open }) => {
                        return (
                          <>
                            <Disclosure.Button className="group relative flex justify-between items-center w-full p-2">
                              {/* Left Side - Day, Port Name, Country */}
                              <div className="flex items-center space-x-3">
                                <span className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-blue-500">
                                  <MapPinIcon
                                    className="h-4 w-4 text-white"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="text-sm text-gray-500">
                                  <span className="font-medium text-gray-900 pr-10">
                                    Day {visit.day}:{" "}
                                    {visit.port
                                      ? `${visit.port.name}, ${visit.port.country}`
                                      : visit.name || "Unknown Port"}
                                  </span>
                                </span>
                              </div>
                              {/* Right Side - Date and Plus/Minus Icon */}
                              <div className="flex items-center space-x-2">
                                {idx === 0 ? (
                                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                    <span>
                                      Departs:{" "}
                                      {new Date(visit.departs_on)
                                        .toLocaleString("en-US", {
                                          month: "long",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "2-digit",
                                        })
                                        .replace(" at", ",")}
                                    </span>
                                  </div>
                                ) : idx ===
                                  localCruiseData.portVisits.length - 1 ? (
                                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                    <span>
                                      Arrives:{" "}
                                      {new Date(visit.arrives_on)
                                        .toLocaleString("en-US", {
                                          month: "long",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "2-digit",
                                        })
                                        .replace(" at", ",")}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                    <time dateTime={visit.arrives_on}>
                                      {new Date(visit.arrives_on)
                                        .toLocaleString("en-US", {
                                          month: "long",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "2-digit",
                                        })
                                        .replace(" at", ",")}
                                    </time>
                                  </div>
                                )}
                                {open ? (
                                  <Minus
                                    className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <Plus
                                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </div>
                            </Disclosure.Button>

                            {/* Port summary and images */}
                            <Disclosure.Panel
                              as="div"
                              className="prose prose-sm mt-2 pl-10">
                              <div className="flex flex-wrap">
                                {visit.portImages &&
                                  visit.portImages.images && (
                                    <div className="w-full md:w-1/3 max-w-500px pr-4">
                                      <PortVisitsCarousel
                                        images={visit.portImages.images}
                                      />
                                    </div>
                                  )}
                                <div className="w-full md:w-2/3">
                                  {localCruiseData.cruises[0].operator_title ===
                                  "Virgin Voyages" ? (
                                    <p className="text-gray-900">
                                      {/* If the operator is Virgin Voyages, don't render duration */}
                                    </p>
                                  ) : (
                                    <p className="text-gray-900">
                                      <span className="font-semibold">
                                        {`Duration: ${calculateStayDuration(
                                          visit.arrives_on,
                                          visit.departs_on
                                        )} hours`}
                                      </span>
                                    </p>
                                  )}
                                  {visit.port?.summary ? (
                                    <p className="text-gray-900">
                                      {truncateSummary(visit.port.summary)}
                                    </p>
                                  ) : (
                                    <p className="text-gray-900">
                                      No summary available
                                    </p>
                                  )}
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        );
                      }}
                    </Disclosure>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
