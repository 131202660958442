import { Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import ProtectedRoute from "./util/ProtectedRoute";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.css";
import LandingPage from "./pages/LandingPage";
import AgentLandingPage from "./pages/AgentLandingPage";
import Register from "./auth/Register";
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import Dashboard from "./pages/dashboard/Dashboard";
import AdminPage from "./pages/AdminPage";
import PrivacyPolicy from "./pages/legal/privacyPolicy";
import UsageTerms from "./pages/legal/usageTerms";
import EmailVerification from "./components/email/emailVerification";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./pages/NotFoundPage";
import CruiseDetailPage from "./pages/CruiseDetailPage";
import ReactGA from "react-ga4";
import { isDev } from "./config";
import CruiseLine from "./pages/cruises/cruiseLine";

function App() {
  ReactGA.initialize("G-X12DM06P57", {
    debug: true,
    titleCase: false,
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/" element={<AgentLandingPage />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route
            path="/:cruiseLine/:cruiseShip/:cruiseDate"
            element={<CruiseDetailPage />}
          />
          <Route path="/:cruiseLine" element={<CruiseLine />} />
          <Route
            path="/confirm-email/:userId/:hash"
            element={<EmailVerification />}
          />
          <Route path="/usage_terms" element={<UsageTerms />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/cruise_detail" element={<CruiseDetailPage />} />

          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Navigate to="/admin/metrics" replace />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
