import { createSlice } from "@reduxjs/toolkit";
import { fetchCruises } from "../thunks/fetchCruises";

const cruisesSlice = createSlice({
  name: "cruises",
  initialState: {
    cruisesList: [],
    status: "idle",
    error: null,
  },
  reducers: {
    clearCruisesError: (state) => {
      state.error = null;
    },
    // Any other synchronous actions related to cruises can go here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruises.pending, (state) => {
        state.status = "loading";
        state.error = null; // You can also clear error here if you wish to reset it on each new request
      })
      .addCase(fetchCruises.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cruisesList = action.payload;
      })
      .addCase(fetchCruises.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // You can handle other thunks here similar to usersSlice.js when you add them.
  },
});

// Exporting the action
export const { clearCruisesError } = cruisesSlice.actions;

export default cruisesSlice.reducer;
