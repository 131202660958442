import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const logoutUser = createAsyncThunk("user/logout", async (_, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user;

    const headers = {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
    };

    const response = await axios.post(
        `https://${apiBaseUrl}/logout`, 
        { "userId": _id },
        { headers }
    );

    return response.data;
});

export { logoutUser };

