import React from "react";
import { useParams } from "react-router-dom";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function CruiseLine() {
  const { cruiseLine } = useParams();

  // Convert the cruiseLine parameter to proper text
  const properCruiseLine = capitalizeFirstLetter(cruiseLine);

  return (
    <div>
      <h1>{properCruiseLine}</h1>
    </div>
  );
}

export default CruiseLine;
