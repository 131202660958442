import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, BellIcon } from "@heroicons/react/24/outline";
import CruiseList from "../../components/cruiseList/CruiseList";
import Logo from "../../components/Logo";
import Profile from "../../components/profile/Profile";
import { useLocation } from "react-router-dom";
import { fetchSubscriptionStatus } from "../../store/thunks/fetchSubscriptionStatus";
import { logoutUser } from "../../store/thunks/logoutUser";
import { persistor } from "../../store";
import CruiseDetailModal from "../../components/cruiseDetail/CruiseDetailModal";
import ContactUs from "../../components/contact/ContactUs";
import { fetchActiveUser } from "../../store/thunks/fetchActiveUser";
import CruiseCalendar from "../../components/cruiseCalendar/CruiseCalendar";

function Dashboard() {
  const location = useLocation();
  const checkoutSessionId = new URLSearchParams(location.search).get(
    "checkout_session_id"
  );
  const dispatch = useDispatch();
  const apiToken = useSelector((state) => state.activeUser.data.user?.token);
  const activeUser = useSelector((state) => state.activeUser.data.user);
  const subscriptionStatus = useSelector((state) => state.subscriptionStatus);
  const [selectedMenu, setSelectedMenu] = useState("Dashboard");
  const [showDetails, setShowDetails] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [selectedCruiseId, setSelectedCruiseId] = useState(null);
  const [selectedCruiseLine, setSelectedCruiseLine] = useState(null);
  const [selectedCruiseShip, setSelectedCruiseShip] = useState(null);
  const [selectedSailDate, setSelectedSailDate] = useState(null);
  const [selectedNights, setSelectedNights] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCurrentPriceAdv, setSelectedCurrentPriceAdv] = useState(null);
  const [selectedCurrentPercentAdv, setSelectedCurrentPercentAdv] =
    useState(null);
  const [selectedGroupRate, setSelectedGroupRate] = useState(null);

  const signOut = async () => {
    try {
      console.log("signOut Button Clicked");
      // Dispatch the logoutUser action
      dispatch(logoutUser({ token: apiToken, userId: activeUser._id }));
      persistor.purge();

      // Redirect to the home page
      window.location.href = "https://www.hiddencruisefares.com";
    } catch (err) {
      console.error(err);
    }
  };

  const navigation = [
    { name: "Cruise List", href: "", current: true },
    // { name: "Cruise Calendar", href: "#", current: false }
  ];

  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Logout", href: "/logout" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Function to handle menu item click and update the selected menu
  const handleMenuItemClick = (item) => {
    console.log("Handle Menu Item Click");
    setSelectedMenu(item.name);
    console.log(selectedMenu);
  };

  useEffect(() => {
    dispatch(fetchSubscriptionStatus());
    dispatch(fetchActiveUser());

    const handleBackButton = (event) => {
      // console.log("Handle Back Button");
      if (showDetails) {
        setShowDetails(false);
        event.preventDefault();
      }
    };
    // console.log("Add Event Listener");
    window.addEventListener("popstate", handleBackButton);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [checkoutSessionId, dispatch, showDetails]);

  return (
    <>
      <div className="min-h-full">
        {/* Header and stuff */}
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <Logo height="auto" width="8rem" />
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "border-indigo-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                          onClick={() => handleMenuItemClick(item)} // Call handleMenuItemClick on click
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <button
                      type="button"
                      className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-color2 text-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <div className="h-8 w-8 rounded-full flex items-center justify-center">
                            {activeUser.first_name[0].toUpperCase()}
                            {activeUser.last_name[0].toUpperCase()}
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  onClick={(e) => {
                                    console.log("ITEM CLICKED - " + e);
                                    // e.preventDefault();
                                    handleMenuItemClick(item);
                                    if (item.onClick) item.onClick();
                                  }}>
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          {activeUser.user_type === "admin" && (
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/admin"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}>
                                  Admin
                                </a>
                              )}
                            </Menu.Item>
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}>
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={activeUser.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {activeUser.first_name} {activeUser.last_name}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {activeUser.email}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="py-10">
          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              {/* Conditionally render content based on the selected menu */}
              {selectedMenu === "Dashboard" && (
                <>
                  {!showDetails && !showContactUs && (
                    <CruiseList
                      onDetailsClick={(
                        cruiseId,
                        cruiseLine,
                        cruiseShip,
                        sailDate,
                        nights,
                        product,
                        currentPriceAdv,
                        currentPercentAdv,
                        groupRate
                      ) => {
                        setShowDetails(true);
                        setShowContactUs(false);
                        setSelectedCruiseId(cruiseId);
                        setSelectedCruiseLine(cruiseLine);
                        setSelectedCruiseShip(cruiseShip);
                        setSelectedSailDate(sailDate);
                        setSelectedNights(nights);
                        setSelectedProduct(product);
                        setSelectedCurrentPriceAdv(currentPriceAdv);
                        setSelectedCurrentPercentAdv(currentPercentAdv);
                        setSelectedGroupRate(groupRate);
                      }}
                      onInquireClick={(
                        cruiseId,
                        cruiseLine,
                        cruiseShip,
                        sailDate,
                        nights,
                        product,
                        currentPriceAdv,
                        currentPercentAdv,
                        groupRate
                      ) => {
                        setShowContactUs(true);
                        setShowDetails(false);
                        setSelectedCruiseId(cruiseId);
                        setSelectedCruiseLine(cruiseLine);
                        setSelectedCruiseShip(cruiseShip);
                        setSelectedSailDate(sailDate);
                        setSelectedNights(nights);
                        setSelectedProduct(product);
                        setSelectedCurrentPriceAdv(currentPriceAdv);
                        setSelectedCurrentPercentAdv(currentPercentAdv);
                        setSelectedGroupRate(groupRate);
                      }}
                    />
                  )}
                  {showDetails && (
                    <CruiseDetailModal
                      cruiseId={selectedCruiseId}
                      cruiseLine={selectedCruiseLine}
                      cruiseShip={selectedCruiseShip}
                      sailDate={selectedSailDate}
                      nights={selectedNights}
                      product={selectedProduct}
                      currentPriceAdv={selectedCurrentPriceAdv}
                      currentPercentAdv={selectedCurrentPercentAdv}
                      groupRate={selectedGroupRate}
                      onClose={() => setShowDetails(false)}
                    />
                  )}
                  {showContactUs && (
                    <ContactUs
                      cruiseId={selectedCruiseId}
                      cruiseLine={selectedCruiseLine}
                      cruiseShip={selectedCruiseShip}
                      sailDate={selectedSailDate}
                      nights={selectedNights}
                      product={selectedProduct}
                      currentPriceAdv={selectedCurrentPriceAdv}
                      currentPercentAdv={selectedCurrentPercentAdv}
                      groupRate={selectedGroupRate}
                      onClose={() => setShowContactUs(false)}
                    />
                  )}
                </>
              )}
              {selectedMenu === "Cruise Calendar" && <CruiseCalendar />}
              {selectedMenu === "Your Profile" && <Profile />}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
