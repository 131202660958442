import React, { useState, useEffect } from "react";
import ComingSoonModal from "./ComingSoonModal";

function EditCruiseModal({ isOpen, onClose, cruise }) {
  const [formData, setFormData] = useState(cruise);
  const [selectedCurrency, setSelectedCurrency] = useState(cruise.Currency);
  const [selectedCategoryClass, setSelectedCategoryClass] = useState(
    cruise.CategoryClass
  );
  const [selectedDate, setSelectedDate] = useState(cruise.SailDate); // Make sure the date format matches the input type="date" format ("YYYY-MM-DD").
  const [showComingSoonModal, setShowComingSoonModal] = useState(false);

  // for escape key exit function
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose(); // call your close modal function
      }
    };

    // Adding the event listener
    document.addEventListener("keydown", handleEsc);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  const handleSaveClick = () => {
    setShowComingSoonModal(true);
  };

  if (!isOpen) return null; // Don't render anything if modal isn't open

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-60"></div>
      {/* <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-xl w-full"> */}
      {/* Ensuring the modal content appears on top of the overlay */}
      <div className="relative z-10 bg-white p-6 rounded-lg shadow-xl max-w-xl w-full">
        <form className="big-gray-100">
          {/* Editing form */}
          <div className="space-y-12 relative z-10">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Cruise Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Edit fields as necessary.
              </p>

              {/* group id */}
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2 sm:col-start-1">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Group ID
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="group-id"
                      id="group-id"
                      value={formData.GroupId || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          GroupId: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900
                shadow-sm ring-1 ring-inset ring-gray-300
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* group name */}
                <div className="sm:col-span-2 sm:col">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Group Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="group-name"
                      id="group-name"
                      value={formData.GroupName || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          GroupName: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* ship */}
                <div className="sm:col-span-2 sm:col">
                  <label
                    htmlFor="ship"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Ship
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="ship"
                      id="ship"
                      value={formData.Ship || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, Ship: e.target.value })
                      }
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* sail date */}
                <div className="sm:col-span-2 sm:col">
                  <label
                    htmlFor="sail-date"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Sail Date
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {/* <input
                      type="text"
                      name="sail-date"
                      id="sail-date"
                      value={formData.SailDate || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          SailDate: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    /> */}
                  </div>
                </div>

                {/* product */}
                <div className="sm:col-span-2 sm:col">
                  <label
                    htmlFor="product"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Product
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="product"
                      id="product"
                      value={formData.Product || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Product: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* nights */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="nights"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Nights
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="nights"
                      id="nights"
                      value={formData.Nights || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, Nights: e.target.value })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* currency */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="currency"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Currency
                  </label>
                  <div className="mt-2">
                    <select
                      value={selectedCurrency}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <option value="USD">USD</option>
                      <option value="CAD">CAD</option>
                    </select>
                    {/* <input
                      type="text"
                      name="currency"
                      id="currency"
                      value={formData.Currency || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Currency: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    /> */}
                  </div>
                </div>

                {/* category class */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="CategoryClass"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Category Class
                  </label>
                  <div className="mt-2">
                    <select
                      value={selectedCategoryClass}
                      onChange={(e) => setSelectedCategoryClass(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <option value="O">O</option>
                      <option value="B">B</option>
                      <option value="I">I</option>
                      <option value="N">N</option>
                    </select>
                    {/* <input
                      type="text"
                      name="CategoryClass"
                      id="CategoryClass"
                      value={formData.CategoryClass || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CategoryClass: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    /> */}
                  </div>
                </div>

                {/* category */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Category
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="category"
                      id="category"
                      value={formData.Category || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Category: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* rooms avail */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="rooms-available"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Rooms Available
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="rooms-available"
                      id="rooms-available"
                      value={formData.RoomsAvailable || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          RoomsAvailable: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* Group Rate */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="group-rate"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Group Rate
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="group-rate"
                      id="group-rate"
                      value={`$${formData.GroupRate || ""}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          GroupRate: e.target.value.replace("$", ""),
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* Current Group Rate */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="current-group-rate"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Current Group Rate
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="current-group-rate"
                      id="current-group-rate"
                      value={`$${formData.CurrentGroupRate || ""}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CurrentGroupRate: e.target.value.replace("$", ""),
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* Price Advantage */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="price-advantage"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Price Advantage
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="price-advantage"
                      id="price-advantage"
                      value={`$${formData.CurrentPriceAdv || ""}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CurrentPriceAdv: e.target.value.replace("$", ""),
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* Percent Advantage */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="percent-advantage"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Percent Advantage
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="percent-advantage"
                      id="percent-advantage"
                      value={`${formData.CurrentPercentAdv || ""}%`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          CurrentPercentAdv: e.target.value.replace("%", ""), // Remove % sign when updating state
                        })
                      }
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Cancel / Save button */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={onClose}>
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={(e) => {
                e.preventDefault();
                setShowComingSoonModal(true);
              }}>
              Save
            </button>
            {/* {showComingSoonModal} */}
            {showComingSoonModal && (
              <ComingSoonModal onClose={() => setShowComingSoonModal(false)} />
            )}
          </div>
        </form>
      </div>
    </div>
    // </div>
  );
}

export default EditCruiseModal;
