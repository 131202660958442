import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const PortVisitsCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative w-full">
      <div className="overflow-hidden rounded-md relative">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            transform: `translateX(-${currentIndex * 100}%)`,
          }}>
          {images.map((image, index) => (
            <div
              key={index}
              className="w-full h-auto rounded-md flex-shrink-0 relative">
              <img
                src={image.href}
                alt={image.name}
                className="w-full h-auto rounded-md"
              />
              {/* <p className="text-center mt-2">{image.name}</p>
              <p className="text-center text-gray-600 text-sm">
                {image.credit}
              </p> */}
            </div>
          ))}
        </div>
      </div>
      {images.length > 1 && ( // Check if there's more than one image to display arrows
        <>
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 p-1 text-gray-800 hover:text-gray-600 bg-white rounded-full mx-2"
            onClick={prevSlide}>
            <ChevronLeftIcon className="h-4 w-4" />
          </button>
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 p-1 text-gray-800 hover:text-gray-600 bg-white rounded-full mx-2"
            onClick={nextSlide}>
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </>
      )}
    </div>
  );
};

export default PortVisitsCarousel;