import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCruises } from "../../store/thunks/fetchCruises";
import { Disclosure } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { initialFilters } from "../../config/Filters";
import shipList from "../cruiseList/shipList";
import roomList from "../cruiseList/roomList";
import regionList from "../cruiseList/regionList";
import Pagination from "../Pagination";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CruiseLineDropdown from "./CruiseLineDropdown";
import CruiseShipDropdown from "./CruiseShipDropdown";
import RegionDropdown from "./RegionDropdown";
import "./CruiseFilter.css";
import ReactGA from "react-ga4";
import { isDev } from "../../config";

function CruiseFilter({ onPageChange, subscriptionActive, sortOrder, sort }) {
  const [selectedCruiseId, setSelectedCruiseId] = useState(null);
  // const [selectedCruiseShip, setSelectedCruiseShip] = useState("");
  const [selectedCruiseLine, setSelectedCruiseLine] = useState("");
  const [selectedShip, setSelectedShip] = useState("");
  const [selectedNights, setSelectedNights] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null); //product = category class = room type
  const [selectedCurrentPriceAdv, setSelectedCurrentPriceAdv] = useState("");
  const [selectedCurrentPercentAdv, setSelectedCurrentPercentAdv] =
    useState("");
  const [excludeSoldOut, setExcludeSoldOut] = useState(false);
  const [minSailDate, setMinSailDate] = useState("");
  const [minSailDateObj, setMinSailDateObj] = useState(null);
  const [maxSailDate, setMaxSailDate] = useState("");
  const [maxSailDateObj, setMaxSailDateObj] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [isMaxDateOpen, setIsMaxDateOpen] = useState(false);
  const [dateSelectError, setDateSelectError] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  // const [sort, setSort] = useState("CurrentPriceAdv");
  // const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [overlayTop, setOverlayTop] = useState(0);
  const [overlayHeight, setOverlayHeight] = useState(0);
  const [filters, setFilters] = useState(initialFilters);

  const dispatch = useDispatch();
  const cruises = useSelector((state) => state.cruises.cruisesList.cruises);
  const totalPages = useSelector(
    (state) => state.cruises.cruisesList.totalPages
  );

  const rowRefs = useMemo(() => {
    return cruises ? cruises.map(() => React.createRef()) : [];
  }, [cruises]);

  useEffect(() => {
    const params = {
      sort: sort,
      sortOrder: sortOrder,
      page: currentPage,
      pageSize: 20,
      cruiseLine: encodeURIComponent(selectedCruiseLine),
      ship: encodeURIComponent(selectedShip),
      excludeSoldOut: excludeSoldOut,
      nights: selectedNights,
      category: selectedCategory,
      priceAdv: selectedCurrentPriceAdv,
      percAdv: selectedCurrentPercentAdv,
      minSailDateObj: minSailDateObj,
      maxSailDateObj: maxSailDateObj,
    };
    dispatch(fetchCruises(params));
  }, [
    sort,
    sortOrder,
    currentPage,
    excludeSoldOut,
    selectedCruiseLine,
    selectedShip,
    selectedNights,
    selectedCategory,
    selectedCurrentPriceAdv,
    selectedCurrentPercentAdv,
    minSailDateObj,
    maxSailDateObj,
    dispatch,
  ]);

  useEffect(() => {
    if (
      currentPage === 1 &&
      rowRefs[9]?.current &&
      rowRefs[cruises.length - 1]?.current
    ) {
      const topPosition = rowRefs[9]?.current.getBoundingClientRect().top;
      const bottomPosition =
        rowRefs[cruises.length - 1]?.current.getBoundingClientRect().bottom;
      const overlayHeight = bottomPosition - topPosition;

      setOverlayTop(topPosition);
      setOverlayHeight(overlayHeight);
    }
  }, [currentPage, rowRefs, cruises]);

  const onCruiseLineChange = (selectedValue) => {
    setSelectedCruiseLine(selectedValue);
    setSelectedShip("All"); // Reset selected ship when cruise line changes
    setCurrentPage(1);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Clicked Filter Cruise Line - ${selectedValue}`,
      });
    }
  };

  const onCruiseShipChange = (selectedValue) => {
    setSelectedShip(selectedValue);
    setCurrentPage(1);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Clicked Filter Ship - ${selectedValue}`,
      });
    }
  };

  const handleRegionChange = (selectedValue) => {
    setSelectedRegion(selectedValue);
    console.log("Region selected (from dropdown):", selectedValue);
    // Add or remove any other state updates as per your requirements
    setCurrentPage(1);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Clicked Filter Region - ${selectedValue}`,
      });
    }
  };

  // Handler for the filters (the first 4)
  const onFilterChange = (filterType, filterValue) => {
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Clicked Filter ${filterType} - ${filterValue}`,
      });
    }
    // Update the filter state based on the checkbox change
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [filterType]: prevFilters[filterType].map((option) =>
          option.value === filterValue
            ? { ...option, checked: !option.checked }
            : option
        ),
      };

      // Compute new selected values based on the updated filters
      const newSelectedNights = extractCheckedValues(updatedFilters.nights);
      const newSelectedCategory = extractCheckedValues(
        updatedFilters.categoryClass
      );
      const newSelectedPriceAdv = extractCheckedValues(
        updatedFilters.priceAdvantage
      );
      const newSelectedPercentAdv = extractCheckedValues(
        updatedFilters.percentAdvantage
      );

      // Update other states
      setSelectedNights(newSelectedNights);
      setSelectedCategory(newSelectedCategory);
      setSelectedCurrentPriceAdv(newSelectedPriceAdv);
      setSelectedCurrentPercentAdv(newSelectedPercentAdv);

      return updatedFilters;
    });
  };

  const extractCheckedValues = (filterArray) => {
    return filterArray
      .filter((option) => option.checked)
      .map((option) => option.value)
      .join(",");
  };

  const clearFilters = () => {
    setSelectedNights("");
    setSelectedCategory("");
    setSelectedCurrentPriceAdv("");
    setSelectedCurrentPercentAdv("");
    setSelectedCruiseLine("");
    setSelectedShip("");
    setMinSailDate("");
    setMinSailDateObj(null);
    setMaxSailDate("");
    setMaxSailDateObj(null);
    setExcludeSoldOut(false);
    setSelectedRegion(""); // for region

    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        nights: prevFilters.nights.map((option) => ({
          ...option,
          checked: false,
        })),
        categoryClass: prevFilters.categoryClass.map((option) => ({
          ...option,
          checked: false,
        })),
        priceAdvantage: prevFilters.priceAdvantage.map((option) => ({
          ...option,
          checked: false,
        })),
        percentAdvantage: prevFilters.percentAdvantage.map((option) => ({
          ...option,
          checked: false,
        })),
      };
    });
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Cleared All Filters`,
      });
    }
  };

  // Filter count
  const countActiveFilters = () => {
    let activeFilters = 0;

    // Check filters object for checked filters
    for (let key in filters) {
      // Exclude excludeSoldOut from the loop
      if (key === "excludeSoldOut") continue;

      for (let filter of filters[key]) {
        if (filter.checked) activeFilters++;
      }
    }

    // Check other states for selected filters
    if (selectedCruiseLine && selectedCruiseLine !== "") activeFilters++;
    if (selectedShip && selectedShip !== "" && selectedShip !== "All")
      activeFilters++;
    if (excludeSoldOut) activeFilters++;
    if (minSailDate && minSailDate !== "") activeFilters++;
    if (maxSailDate && maxSailDate !== "") activeFilters++;
    if (selectedRegion && selectedRegion !== "") activeFilters++;

    return activeFilters;
  };

  const uniqueCruiseLines = [
    ...new Set(shipList.map((ship) => ship.cruiseLine)),
  ];

  const uniqueRegions = regionList.map((region) => region.name);

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.error("Invalid date received:", date);
      return null;
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const pillCategoryMapping = {
    I: "Inside",
    O: "Oceanview",
    B: "Balcony",
    N: "Neighbourhood",
    S: "Suite",
  };

  const activePillFilters = [
    ...selectedNights
      .split(",")
      .filter((night) => night)
      .map((night) => ({
        // Ensure "night" is non-empty with the filter function above
        value: night,
        label: `${night} Nights`,
        filterType: "nights",
      })),
    ...selectedCategory
      .split(",")
      .filter((category) => category)
      .map((category) => ({
        // Ensure "category" is non-empty
        value: category,
        label: `${pillCategoryMapping[category] || category}`,
        filterType: "categoryClass",
      })),
    ...selectedCurrentPriceAdv
      .split(",")
      .filter((currentPriceAdv) => currentPriceAdv) // Ensure non-empty values only
      .map((currentPriceAdv) => ({
        value: currentPriceAdv,
        label: `Saving $${currentPriceAdv}`,
        filterType: "priceAdvantage",
      })),
    ...selectedCurrentPercentAdv
      .split(",")
      .filter((currentPercentAdv) => currentPercentAdv) // Ensure non-empty values only
      .map((currentPercentAdv) => ({
        value: currentPercentAdv,
        label: `${currentPercentAdv}% discount`,
        filterType: "percentAdvantage",
      })),
    ...selectedCruiseLine
      .split(",")
      .filter((CruiseLine) => CruiseLine) // Ensure non-empty
      .map((CruiseLine) => ({
        value: CruiseLine,
        label: `${CruiseLine}`,
        filterType: "cruiseLine",
      })),
    ...selectedShip
      .split(",")
      .filter((Ship) => Ship) // Ensure non-empty
      .map((Ship) => ({
        value: Ship,
        label: `${Ship}`,
        filterType: "cruiseShip",
      })),
    ...minSailDate
      .split(",")
      .filter((minDate) => minDate) // Ensure non-empty
      .map((minDate) => ({
        value: minDate,
        label: `Earliest: ${minDate}`,
        filterType: "earliestDeparture",
      })),
    ...maxSailDate
      .split(",")
      .filter((maxDate) => maxDate) // Ensure non-empty
      .map((maxDate) => ({
        value: maxDate,
        label: `Latest: ${maxDate}`,
        filterType: "latestDeparture",
      })),
    ...(excludeSoldOut
      ? [
          {
            value: "Exclude Sold Out",
            label: "Exclude Sold Out",
            filterType: "excludeSoldOut",
          },
        ]
      : []),
    ...selectedRegion
      .split(",")
      .filter((Region) => Region) // Ensure non-empty
      .map((Region) => ({
        value: Region,
        label: `${Region}`,
        filterType: "region",
      })),
  ];

  const removePillFilter = (filterType, filterValue) => {
    // Clearing logic for specific filter types
    if (
      [
        "cruiseLine",
        "cruiseShip",
        "earliestDeparture",
        "latestDeparture",
        "region",
      ].includes(filterType)
    ) {
      switch (filterType) {
        case "cruiseLine":
          setSelectedCruiseLine("");
          break;
        case "cruiseShip":
          setSelectedShip("");
          break;
        case "earliestDeparture":
          setMinSailDate("");
          setMinSailDateObj(null);
          break;
        case "latestDeparture":
          setMaxSailDate("");
          setMaxSailDateObj(null);
          break;
        case "region":
          setSelectedRegion("");
          break;
        default:
          console.warn(`Unexpected filterType encountered: ${filterType}`);
          break;
      }
      return;
    }

    setFilters((prevFilters) => {
      let updatedFilters;

      if (filterType === "excludeSoldOut") {
        updatedFilters = {
          ...prevFilters,
          excludeSoldOut: true,
        };
      } else {
        if (Array.isArray(prevFilters[filterType])) {
          updatedFilters = {
            ...prevFilters,
            [filterType]: prevFilters[filterType].map((option) =>
              option.value === filterValue
                ? { ...option, checked: false }
                : option
            ),
          };
        } else {
          console.warn(`prevFilters[${filterType}] is not an array.`);
        }
      }

      switch (filterType) {
        case "nights":
          setSelectedNights((prev) =>
            prev.replace(filterValue + ",", "").replace(filterValue, "")
          );
          break;
        case "categoryClass":
          setSelectedCategory((prev) =>
            prev.replace(filterValue + ",", "").replace(filterValue, "")
          );
          break;
        case "priceAdvantage":
          setSelectedCurrentPriceAdv((prev) =>
            prev.replace(filterValue + ",", "").replace(filterValue, "")
          );
          break;
        case "percentAdvantage":
          setSelectedCurrentPercentAdv((prev) =>
            prev.replace(filterValue + ",", "").replace(filterValue, "")
          );
          break;
        case "excludeSoldOut":
          setExcludeSoldOut(false);
          break;
        default:
          console.warn(`Unexpected filterType encountered: ${filterType}`);
          break;
      }

      return updatedFilters;
    });
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    onPageChange((prevPage) => prevPage + 1);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Clicked Next Page - Page ${currentPage}`,
      });
    }
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    onPageChange((prevPage) => prevPage - 1);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Clicked Previous Page - Page ${currentPage}`,
      });
    }
  };

  const handlePageClick = (clickedPage) => {
    setCurrentPage(clickedPage);
    onPageChange(clickedPage);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: `Clicked Page ${clickedPage} `,
      });
    }
  };

  return (
    <div>
      <div className="bg-gray-100 relative rounded-xl">
        <Disclosure
          as="section"
          aria-labelledby="filter-heading"
          className="grid items-center">
          {/* This is for the filter header */}
          <div className="relative col-start-1 row-start-1 py-4">
            <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
              <div className="parent-container">
                <Disclosure.Button
                  onClick={() => setFiltersOpen(!filtersOpen)}
                  className="filter-button group flex items-center font-medium text-gray-700 mb-2">
                  {" "}
                  {/* Added mb-2 for spacing */}
                  <FunnelIcon
                    className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <div className="flex items-center">
                    {countActiveFilters() === 0
                      ? "Filters"
                      : countActiveFilters() === 1
                      ? "1 Filter"
                      : `${countActiveFilters()} Filters`}
                  </div>
                </Disclosure.Button>
                <button
                  onClick={clearFilters}
                  type="button"
                  className="pt-2 filter-button text-gray-500"
                  style={{ borderTop: "1px solid #ccc" }}>
                  Clear
                </button>
              </div>

              {/* Active Pill filters */}
              <div>
                <div>
                  <div className="sm:ml-4 sm:mt-0">
                    <div className="-m-1 flex flex-wrap items-center">
                      <div className="pills-container">
                        {activePillFilters.length > 0 &&
                          activePillFilters.map((activeFilter) => (
                            <span
                              key={activeFilter.value}
                              className="mx-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1 pl-3 pr-2 text-sm font-medium text-gray-900">
                              <span>{activeFilter.label}</span>
                              <button
                                onClick={() =>
                                  removePillFilter(
                                    activeFilter.filterType,
                                    activeFilter.value
                                  )
                                }
                                type="button"
                                className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500">
                                <span className="sr-only">
                                  Remove filter for {activeFilter.label}
                                </span>
                                <svg
                                  className="h-2 w-2"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 8 8">
                                  <path
                                    strokeLinecap="round"
                                    strokeWidth="1.5"
                                    d="M1 1l6 6m0-6L1 7"
                                  />
                                </svg>
                              </button>
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Filter body and components */}
          <div>
            <Disclosure.Panel className="border-t border-gray-200 py-1">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row flex-wrap justify-between p-2">
                  {/* Nights */}
                  <fieldset className="flex-1 relative mb-2 sm:mb-0 sm:mr-2">
                    <legend className="block text-base font-semibold leading-6 text-gray-900">
                      Nights
                    </legend>
                    <div>
                      {!subscriptionActive && (
                        <div
                          className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            opacity: 75,
                            backgroundColor: "rgba(243, 244, 246, 1)", // This gives a slight whitewash look. Remove if you don't want it.
                            zIndex: 1,
                          }}></div>
                      )}
                      <div className="space-y-4 sm:space-y-2 pt-2">
                        {filters.nights.map((option, optionIdx) => (
                          <div
                            key={option.value}
                            className="flex items-center text-sm sm:text-sm">
                            <input
                              id={`nights-${optionIdx}`}
                              name="nights[]"
                              value={option.value}
                              type="checkbox"
                              disabled={!subscriptionActive}
                              className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              checked={option.checked}
                              onChange={() =>
                                onFilterChange("nights", option.value)
                              }
                            />
                            <label
                              htmlFor={`nights-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-600">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </fieldset>
                  {/* Category class */}
                  <fieldset className="flex-1 relative mb-2 sm:mb-0 sm:mr-2">
                    <legend className="block text-base font-semibold leading-6 text-gray-900">
                      Category Class
                    </legend>
                    <div>
                      {!subscriptionActive && (
                        <div
                          className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            opacity: 75,
                            backgroundColor: "rgba(243, 244, 246, 1)", // This gives a slight whitewash look. Remove if you don't want it.
                            zIndex: 1,
                          }}></div>
                      )}
                      <div className="space-y-4 sm:space-y-2 pt-2">
                        {filters.categoryClass.map((option, optionIdx) => (
                          <div
                            key={option.value}
                            className="flex items-center text-sm sm:text-sm">
                            <input
                              id={`categoryClass-${optionIdx}`}
                              name="categoryClass[]"
                              value={option.value}
                              type="checkbox"
                              disabled={!subscriptionActive}
                              className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              checked={option.checked}
                              onChange={() =>
                                onFilterChange("categoryClass", option.value)
                              }
                            />
                            <label
                              htmlFor={`categoryClass-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-600">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </fieldset>
                  {/* Price advantage */}
                  <fieldset className="flex-1 relative mb-2 sm:mb-0 sm:mr-2">
                    <legend className="block text-base font-semibold leading-6 text-gray-900">
                      Price Advantage
                    </legend>
                    <div>
                      {!subscriptionActive && (
                        <div
                          className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            opacity: 75,
                            backgroundColor: "rgba(243, 244, 246, 1)", // This gives a slight whitewash look. Remove if you don't want it.
                            zIndex: 1,
                          }}></div>
                      )}
                      <div className="space-y-4 sm:space-y-2 pt-2">
                        {filters.priceAdvantage.map((option, optionIdx) => (
                          <div
                            key={option.value}
                            className="flex items-center text-sm sm:text-sm">
                            <input
                              id={`priceAdvantage-${optionIdx}`}
                              name="priceAdvantage[]"
                              value={option.value}
                              type="checkbox"
                              disabled={!subscriptionActive}
                              className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              checked={option.checked}
                              onChange={() =>
                                onFilterChange("priceAdvantage", option.value)
                              }
                            />
                            <label
                              htmlFor={`priceAdvantage-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-600">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </fieldset>
                  {/* Percent advantage */}
                  <fieldset className="flex-1 relative mb-2 sm:mb-0 sm:mr-2">
                    <legend className="block text-base font-semibold leading-6 text-gray-900">
                      Percent Advantage
                    </legend>
                    <div>
                      {!subscriptionActive && (
                        <div
                          className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            opacity: 75,
                            backgroundColor: "rgba(243, 244, 246, 1)", // This gives a slight whitewash look. Remove if you don't want it.
                            zIndex: 1,
                          }}></div>
                      )}
                      <div className="space-y-4 sm:space-y-2 pt-2">
                        {filters.percentAdvantage.map((option, optionIdx) => (
                          <div
                            key={option.value}
                            className="flex items-center text-sm sm:text-sm">
                            <input
                              id={`percentAdvantage-${optionIdx}`}
                              name="percentAdvantage[]"
                              value={option.value}
                              type="checkbox"
                              disabled={!subscriptionActive}
                              className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              checked={option.checked}
                              onChange={() =>
                                onFilterChange("percentAdvantage", option.value)
                              }
                            />
                            <label
                              htmlFor={`percentAdvantage-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-600">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 p-8 col-span-2">
                  <CruiseLineDropdown
                    className="w-full md:flex-1"
                    uniqueCruiseLines={uniqueCruiseLines}
                    onCruiseLineChange={onCruiseLineChange}
                    subscriptionActive={subscriptionActive}
                    setCurrentPage={setCurrentPage}
                    selectedCruiseLine={selectedCruiseLine} // <-- Added
                    setSelectedCruiseLine={setSelectedCruiseLine} // <-- Added
                  />

                  <CruiseShipDropdown
                    className="w-full md:flex-1"
                    shipList={shipList}
                    selectedCruiseLine={selectedCruiseLine}
                    onCruiseShipChange={onCruiseShipChange}
                    subscriptionActive={subscriptionActive}
                    selectedShip={selectedShip}
                    setSelectedShip={setSelectedShip}
                    setCurrentPage={setCurrentPage}
                  />

                  {/* <RegionDropdown
                    uniqueRegions={uniqueRegions}
                    onRegionChange={handleRegionChange}
                    subscriptionActive={true} // Modify based on your logic
                    setCurrentPage={setCurrentPage}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                  /> */}

                  {/* Earliest departure */}
                  <fieldset className="relative w-full md:flex-1">
                    {!subscriptionActive && (
                      <div
                        className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          opacity: 75,
                          backgroundColor: "rgba(243, 244, 246, 1)",
                          zIndex: 1,
                        }}></div>
                    )}
                    <legend className="block text-base font-semibold leading-6 text-gray-900">
                      Earliest departure
                    </legend>
                    <div>
                      <DatePicker
                        selected={minSailDateObj}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = formatDate(date);
                            setMinSailDate(formattedDate);
                            setMinSailDateObj(date);
                            setMaxSailDate(""); // Reset the maxSailDate when minSailDate is changed
                            setMaxSailDateObj(null); // Same for the Date object version
                          }
                        }}
                        minDate={
                          new Date(
                            new Date().setDate(new Date().getDate() + 2)
                          )
                        }
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select a date"
                        disabled={!subscriptionActive}
                        className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {dateSelectError && (
                        <p className="text-red-500">Please set both dates</p>
                      )}
                    </div>
                  </fieldset>

                  {/* Latest departure */}
                  <fieldset className="relative w-full md:flex-1">
                    {!subscriptionActive && (
                      <div
                        className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          opacity: 75,
                          backgroundColor: "rgba(243, 244, 246, 1)",
                          zIndex: 1,
                        }}></div>
                    )}
                    <legend className="block text-base font-semibold leading-6 text-gray-900">
                      Latest departure
                    </legend>
                    <div>
                      <DatePicker
                        selected={maxSailDateObj}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = formatDate(date);
                            setMaxSailDate(formattedDate);
                            setMaxSailDateObj(date);
                          }
                        }}
                        onCalendarOpen={() => setIsMaxDateOpen(true)}
                        onCalendarClose={() => setIsMaxDateOpen(false)}
                        // minDate={minSailDateObj}
                        // minDate={
                        //   new Date(
                        //     new Date().setDate(new Date().getDate() + 120)
                        //   ) || minSailDateObj
                        // }
                        minDate={(() => {
                          const minDate120Days = new Date(
                            new Date().setDate(new Date().getDate() + 120)
                          );
                          if (!minSailDateObj) return minDate120Days;
                          return new Date(minSailDateObj) > minDate120Days
                            ? new Date(minSailDateObj)
                            : minDate120Days;
                        })()}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select a date"
                        disabled={!subscriptionActive}
                        className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />

                      {dateSelectError && minSailDateObj && !maxSailDateObj && (
                        <p className="text-red-500">
                          Max date must be after min date
                        </p>
                      )}
                    </div>
                  </fieldset>

                  {/* Exclude sold out */}
                  <fieldset className="relative">
                    {!subscriptionActive && (
                      <div
                        className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          opacity: 75,
                          backgroundColor: "rgba(243, 244, 246, 1)", // This gives a slight whitewash look. Remove if you don't want it.
                          zIndex: 1,
                        }}></div>
                    )}
                    <div className="space-y-4 pt-4">
                      <div className="relative flex items-center">
                        {/* Changed from items-start to items-center */}
                        <div className="flex h-6 items-center justify-center">
                          {/* Added justify-center to center the checkbox */}
                          <input
                            id="excludeSoldOutCheckbox"
                            name="excludeSoldOut"
                            type="checkbox"
                            disabled={!subscriptionActive}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            checked={excludeSoldOut}
                            onChange={() => setExcludeSoldOut(!excludeSoldOut)}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="excludeSoldOutCheckbox"
                            className="block text-base font-semibold leading-6 text-gray-900">
                            Exclude <br /> Sold Out
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              {/* Subscription banner */}
              {!subscriptionActive && (
                <div
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "135px",
                    width: "80%", // or any desired width
                    // opacity: 1,
                  }}
                  className="absolute bg-opacity-75 flex items-center justify-center z-10 pointer-events-auto">
                  <p className="block text-base font-semibold leading-6 text-gray-900">
                    Filtering requires a subscription
                  </p>
                </div>
              )}
            </Disclosure.Panel>
          </div>
        </Disclosure>
      </div>
      <br />
      <div className="border-t border-gray-200">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleNext={handleNext}
          handlePrev={handlePrev}
          handlePage={handlePageClick}
        />
      </div>
    </div>
  );
}

export default CruiseFilter;
