import { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import celebrityLogo from "../../assets/celebrityLogo.png";
import royalLogo from "../../assets/royalLogo.png";
import virginLogo from "../../assets/virginLogo.png";

export default function SoldOutModal({ cruiseLine, closeModal }) {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(true);
    }, []);

    let logo;
    switch (cruiseLine) {
        case "Celebrity":
            logo = celebrityLogo;
            break;
        case "Royal Caribbean":
            logo = royalLogo;
            break;
        case "Virgin Voyages":
            logo = virginLogo;
            break;
        default:
            logo = celebrityLogo; // or a default logo if you have one
    }

    let message;
    if (cruiseLine === "Virgin Voyages") {
        message = (
            <>
                <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold leading-6 text-gray-900"
                >
                    Important
                </Dialog.Title>
                <br />
                <div>
                    <p className="text-md text-semibold text-gray-500">
                    At {cruiseLine}, we believe in providing equal pricing for every guest.
                    </p>
                    <br />
                    <p className="text-md text-semibold text-gray-500">
                        <b>This is why you won't see a savings on our site.</b>
                    </p>
                    <br />
                    <p className="text-md text-semibold text-gray-500">
                        <b>
                            Virgin offers an exceptional Adult Only experience
                            unlike anything in the cruise industry
                        </b>
                    </p>
                </div>
            </>
        );
    } else {
        message = (
            <>
                <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold leading-6 text-gray-900"
                >
                    Sold Out
                </Dialog.Title>
                <br />
                <div>
                    <p className="text-md text-gray-500">
                        This cabin type cannot be purchased anymore on the{" "}
                        {cruiseLine} website.
                    </p>
                    <br />
                    <p className="text-md text-semibold text-gray-500">
                        <b>
                            However, at HiddenCruiseFares.com, we still have this
                            cabin type available at the offered price.
                        </b>
                    </p>
                </div>
            </>
        );
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 flex items-center justify-center z-50"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {/* Semi-transparent background */}
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                </Transition.Child>
                <div className="relative z-20 bg-white rounded-lg px-4 pb-4 text-left shadow-xl sm:w-full sm:max-w-sm sm:p-6">
                    <div>
                        <div className="mx-auto flex h-75 w-75 items-center justify-center">
                            <img
                                src={logo}
                                alt={`${cruiseLine} logo`}
                                className="h-50 w-50"
                            />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            {message}
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={closeModal}
                        >
                            Good to know
                        </button>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
