import React, { useState } from "react";
import { Plus, Minus } from "heroicons-react";

function ShipDetailsSection({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-t border-gray-200 py-4">
      <h4
        className="text-md font-medium text-gray-700 cursor-pointer flex items-center justify-between"
        onClick={toggleSection}>
        {title}

        <span>
          {isOpen ? (
            <Minus className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500" />
          ) : (
            <Plus className="block h-6 w-6 text-gray-400 group-hover:text-gray-500" />
          )}
        </span>
      </h4>
      {isOpen && (
        <div className="mt-6 text-gray-500 prose prose-sm">{content}</div>
      )}
    </div>
  );
}

export default ShipDetailsSection;
