import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../store/thunks/loginUser";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import FlowRider from "../assets/FlowRider.webp";
import useActiveUser from "../customHooks/useActiveUser";

function Login({ onLogin, onRegister }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [emailToVerify, setEmailToVerify] = useState(null);

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const errorType = queryParams.get("error");

        if (errorType === "sessionExpired") {
            setError("Your session has expired. Please log in again.");
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const loginData = { email: username, password: password };

        try {
            const action = await dispatch(loginUser(loginData));
            // Check if the action was successful
            if (action.payload) {
                if (action.payload.emailNotVerified) {
                    setEmailToVerify(username);
                } else {
                    if (action.payload.user.token) {
                        navigate("/dashboard");
                    } else {
                        setError("Unexpected response from server.");
                    }
                }
            } else {
                setError("Invalid credentials.");
            }
        } catch (error) {
            // Handle the email not verified error specifically
            setError("An error occurred.");
        }
    };

    const handleResendVerificationEmail = () => {
        // TODO: Implement the logic to resend verification email
        alert(`Verification email resent to ${emailToVerify}`);
    };

    return (
        <>
            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Logo />
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Sign in to your account
                            </h2>
                            <p className="mt-2 text-sm leading-6 text-gray-500">
                                Not a member?{" "}
                                <a
                                    href="/register"
                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                >
                                    Sign Up Now
                                </a>
                            </p>
                        </div>

                        {error && (
                            <div className="mt-4 flex items-center">
                                <p className="text-red-500 mr-2">{error}</p>
                                <button
                                    className="text-red-500 font-semibold"
                                    onClick={() => setError("")}
                                >
                                    Dismiss
                                </button>
                            </div>
                        )}

                        <div className="mt-10">
                            <div>
                                {emailToVerify ? (
                                    <div>
                                        <p>
                                            Your email {emailToVerify} is not
                                            verified.
                                        </p>
                                        <button
                                            onClick={
                                                handleResendVerificationEmail
                                            }
                                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Resend Verification Email
                                        </button>
                                    </div>
                                ) : (
                                    <form
                                        onSubmit={handleSubmit}
                                        className="space-y-6"
                                    >
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Email address
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    required
                                                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={username}
                                                    onChange={(e) =>
                                                        setUsername(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Password
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    required
                                                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={password}
                                                    onChange={(e) =>
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <input
                                                    id="remember-me"
                                                    name="remember-me"
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                                <label
                                                    htmlFor="remember-me"
                                                    className="ml-3 block text-sm leading-6 text-gray-700"
                                                >
                                                    Remember me
                                                </label>
                                            </div>
                                            <div className="text-sm leading-6">
                                                <Link
                                                    to="/reset_password"
                                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                                >
                                                    Forgot password?
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Sign in
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={FlowRider}
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}

export default Login;
